import Vue from 'vue';
import { TableDataApiService } from '@/services';
import { THEME, TABLE_TYPES } from '@/consts';
import {
  Operation,
  Pagination,
  PrepaidCard,
  ClientWithdrawal,
  PaymentAgent,
  ClientDeposits,
} from '@/models';
import mappers from './mappers';
import api from './api';
import createStoreModule from '../../utils/create-store-module';

import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const actionsDeps = {
  api,
  TableDataApiService,
  Vue,
  mappers,
};

const gettersDeps = {
  models: {
    Operation,
    Pagination,
    PrepaidCard,
    ClientWithdrawal,
    PaymentAgent,
    ClientDeposits,
  },
  CONSTANTS: {
    THEME,
    TABLE_TYPES,
  },
};

export default createStoreModule({
  moduleName: 'content',
  entities: {
    state: [state],
    actions: [actions, actionsDeps],
    mutations: [mutations],
    getters: [getters, gettersDeps],
  },
});
