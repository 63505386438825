import state from './state';
import createActions from './actions';
import mutations from './mutations';
import getters from './getters';

export function createClientDepositCreationStoreModule({ clientDepositCreationApiService }) {
  return {
    namespaced: true,
    state: () => state,
    actions: createActions({ clientDepositCreationApiService }),
    mutations,
    getters,
  };
}
