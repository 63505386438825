export class StrategyManager {
  _strategies = [];

  constructor(strategies = []) {
    this._strategies = strategies;
  }

  get strategiesList() {
    return this._strategies.reduce((acc, strategy) => ({
      ...acc,
      [strategy.name]: strategy.action,
    }), {});
  }

  addStrategy(strategy) {
    this._strategies.push(strategy);
  }

  removeStrategy(strategyName) {
    this._strategies = this._strategies.filter((strategy) => strategy?.name !== strategyName);
  }

  getStrategy(strategyName) {
    const strategy = this._strategies.find((strat) => strat?.name === strategyName);
    if (!strategy) {
      throw new Error(`strategy: ${strategyName} is not exists`);
    }

    return strategy.action;
  }
}
