import {
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiDownload,
  mdiContentCopy,
  mdiHelpCircleOutline,
  mdiOpenInNew,
} from '@mdi/js';

export default {
  'check': mdiCheck,
  'chevron-down': mdiChevronDown,
  'chevron-up': mdiChevronUp,
  'download': mdiDownload,
  'copy': mdiContentCopy,
  'help': mdiHelpCircleOutline,
  'open-in-new': mdiOpenInNew,
};
