export class VuexHelper {
  /**
   * Dynamically register the store module once.
   * Ignores registration if module has already been registered.
   */
  static registerStoreModule({ store, moduleName, module }) {
    if (!store.hasModule(moduleName)) {
      store.registerModule(moduleName, module);
    }
  }

  static actionExceptionHandler(e, actionName, disableThrowsOn = []) {
    console.error(e, `while ${actionName}`);

    if (!disableThrowsOn.includes(e?.response?.status)) {
      throw e;
    }
  }
}
