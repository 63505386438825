import { VuexHelper } from '@/lib/vuex-helper';
import { ACTION_FETCH_AGENT_TITLES, MUTATIONS_SET_AGENT_TITLES } from './types';

export default function ({ tableDataService }) {
  return {
    async [ACTION_FETCH_AGENT_TITLES]({ commit }) {
      try {
        const agentTitles = await tableDataService.getAgentsList();
        commit(MUTATIONS_SET_AGENT_TITLES, { agentTitles });
      } catch (e) {
        VuexHelper.actionExceptionHandler(e, ACTION_FETCH_AGENT_TITLES);
      }
    },
  };
}
