import { formatDate } from '@/utils';

export default class PaymentAgent {
  id;
  agentUid;
  humanId;
  email;
  name;
  balance;
  updatedAt;
  createdAt;

  constructor(opts) {
    Object.assign(this, {
      ...opts,
      updatedAt: formatDate(opts.updatedAt),
      createdAt: formatDate(opts.createdAt),
    });
  }
}
