<template lang="pug">
  v-container(id="home")
    h1(class="px-7") Profile

    v-layout(v-if="!isContentLoading")
      v-container(id="home")
        agent-info-card(:item="agent")
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ACTION_FETCH_AGENT } from '@/store/modules/content/action-types';

import { AgentInfoCard } from '@/components/cards/agent-info-card';

export default {
  name: 'AgentProfile',
  components: {
    AgentInfoCard,
    CommonErrors: () => import('@/components/core/CommonErrors.vue'),
  },
  async mounted() {
    await this[ACTION_FETCH_AGENT]({ uid: this.authData.userUid });
  },
  computed: {
    ...mapGetters('auth', ['authData']),
    ...mapGetters('content', ['isContentLoading', 'getStateDataItem']),

    agent() {
      return this.getStateDataItem({ type: 'agents', id: this.authData.userUid });
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_AGENT]),
  },
};
</script>
