var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"xs":"12","md":"10","offset-md":"1"}},[_c('h1',{staticClass:"mb-4"},[_vm._v("Deposits")]),_c('agent-titles-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isTitlesLoaded = ref.isTitlesLoaded;
var agentTitles = ref.agentTitles;
return [_c('table-content-provider',{attrs:{"table-type":_vm.$options.constants.tableType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isInitLoadingComplete = ref.isInitLoadingComplete;
var pagination = ref.pagination;
var items = ref.items;
var handleChange = ref.handleChange;
return [(isInitLoadingComplete && isTitlesLoaded)?_c('table-complex',{attrs:{"filters":_vm.createTableFilters(agentTitles),"filter-names":_vm.filterNames,"headers":_vm.tableHeaders,"items":items,"min-items-per-page":_vm.$options.constants.minItemsPerPage,"pagination":pagination},on:{"pagination-change":handleChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('agent-operations-table-row',{attrs:{"item":item,"route-name-to-item":_vm.$options.constants.depositRoute,"table-type":_vm.$options.constants.tableType}})]}}],null,true)}):_vm._e()]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }