export default {
  isRequired({ errorMsg = 'Field is required' }) {
    return (val) => !!val || errorMsg;
  },
  isNumber({ errorMsg = 'Must be a valid number (decimal or float)' }) {
    return (val) => (Number.isNaN(Number(val)) ? errorMsg : true);
  },
  isInteger({ errorMsg = 'Must be an integer number' }) {
    return (val) => (Number.isInteger(Number(val)) ? true : errorMsg);
  },
  notZero({ errorMsg = 'Must be more than 0' }) {
    return (val) => ((Number(val) > 0 ? true : errorMsg));
  },
  notMoreThan({ errorMsg = 'Must be not more than', argument }) {
    return (val) => (Number(val) > Number(argument) ? `${errorMsg} ${argument}` : true);
  },
  isUUID4({ errorMsg = 'Must be a uuidv4 format' }) {
    const UUIDV4_REGEX = /[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/i;
    return (val) => UUIDV4_REGEX.test(val) || errorMsg;
  },
  isEmail({ errorMsg = 'Email is not not valid' }) {
    const EMAIL_REGEX = /\S+@\S+\.\S+/;
    return (val) => EMAIL_REGEX.test(val) || errorMsg;
  },
  noWhitespaceCharacters({ errorMsg = 'Cannot contain whitespace' }) {
    const SPACE_REGEX = /\s+/g;
    return (val) => !SPACE_REGEX.test(val) || errorMsg;
  },
  isNotUndefined({ errorMsg = 'Fill in the Wallet ID or Phone field' }) {
    return (val) => !!val || errorMsg;
  },
};
