<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="item in items" :key="item.id">
      <v-expansion-panel-header>
        <slot name="title" :title="item.title" />
      </v-expansion-panel-header>
      <v-expansion-panel-content :eager="true">
        <slot name="content" :content="item.content" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'app-expansion-panel',
  props: {
    items: VueTypes.arrayOf(VueTypes.shape({
      title: VueTypes.string.isRequired,
      content: VueTypes.string.isRequired,
    })).isRequired,
  },
};
</script>
