import Vue from 'vue';
import { ErrorParser, NotifierService } from '@/services';
import { VuexHelper } from '@/lib/vuex-helper';
import {
  ACTION_CREATE_CLIENT_DEPOSITS,
  MUTATION_SET_SERVER_VALIDATION_ERRORS,
  ACTION_CHANGE_LOADING_CONTENT_STATUS,
  MUTATION_SET_CONTENT_LOADING_STATUS,
} from './types';

const notifier = new NotifierService(Vue.$toast);

export default function ({ clientDepositCreationApiService }) {
  return {
    [ACTION_CHANGE_LOADING_CONTENT_STATUS]({ commit }, { isLoading }) {
      commit(MUTATION_SET_CONTENT_LOADING_STATUS, { isLoading });
    },
    async [ACTION_CREATE_CLIENT_DEPOSITS]({ commit, dispatch }, {
      onSuccess = () => {},
      agentUid,
      wallet_id: walletId,
      phone,
      amount,
      currency,
    }) {
      try {
        await dispatch(ACTION_CHANGE_LOADING_CONTENT_STATUS, { isLoading: true });
        commit(MUTATION_SET_SERVER_VALIDATION_ERRORS, { errors: [] });

        await clientDepositCreationApiService.createClientDeposit({
          agentUid,
          walletId,
          phone,
          amount,
          currency,
        });

        notifier.show({
          type: 'success',
          msg: 'Success',
        });
        onSuccess();
      } catch (e) {
        const errorCallback = (err) => (err?.response?.status === 422
          ? ErrorParser.parseBusinessLogicError(e)
          : ErrorParser.parseFormValidationError(e));

        VuexHelper.actionExceptionHandler(e, ACTION_CREATE_CLIENT_DEPOSITS);
        commit(MUTATION_SET_SERVER_VALIDATION_ERRORS, {
          errors: errorCallback(e),
        });
      } finally {
        await dispatch(ACTION_CHANGE_LOADING_CONTENT_STATUS, { isLoading: false });
      }
    },
  };
}
