export default {
  changeFirstDotSymbolToZero() {
    return (val) => {
      const isFirstSymbolIsDot = val.slice(0, 1) === '.';
      return isFirstSymbolIsDot ? `0${val}` : val;
    };
  },
  changeSecondCommaSymbolToDot() {
    return (val) => {
      const isSecondSymbolIsComma = val.slice(1, 2) === ',';

      if (isSecondSymbolIsComma) {
        const [first, ...rest] = val.split(',').filter((symbol) => symbol !== ',');
        return [first, '.', ...rest].join('');
      }

      return val;
    };
  },
  trim() {
    return (val) => val.trim();
  },
};
