<template>
  <router-link class="mr-1" :to="{ name: $options.guidelineRoute }">
    <app-icon
      icon="help"
      :is-cursor-pointer="true"
      :size="25"
    />
  </router-link>
</template>

<script>
import { COMMON_ROUTES } from '@/router/routes-const';

import { AppIcon } from '@/components/common/app-icon';

export default {
  name: 'toolbar-guideline-btn',
  components: {
    AppIcon,
  },
  guidelineRoute: COMMON_ROUTES.GUIDELINE.NAME,
};
</script>
