export default {
  searchExactQueryParamKeys: [
    'humanId',
    'code',
    'operationUuid',
  ],
  searchPartialQueryParamKeys: [
    'accountDetails',
    'email',
    'name',
    'title',
    'externalOperationUid',
    'recepientUserUid',
    'createdAt',
    'agentTitle',
  ],
  filterQueryParams: new Map([
    ['pageNumber', 'page[number]'],
    ['pageSize', 'page[size]'],
    ['statusName', 'filter[state][name]'],
    ['humanId', 'filter[human_id]'],
    ['title', 'filter[title]'],
    ['agentTitle', 'filter[agent_title]'],
    ['accountDetails', 'filter[account_details]'],
    ['uid', 'filter[uid]'],
    ['code', 'filter[code]'],
    ['email', 'filter[email]'],
    ['name', 'filter[name]'],
    ['operationUuid', 'filter[operation_uuid]'],
    ['agentUid', 'filter[agent_uid]'],
    ['createdAt', 'filter[created_at]'],
    ['externalOperationUid', 'filter[external_operation_uid]'],
    ['recepientUserUid', 'filter[recepient_user_uid]'],
    ['walletId', 'filter[wallet_id]'],
    ['createdAtFrom', 'filter[created_at_from]'],
    ['createdAtTo', 'filter[created_at_to]'],
    ['ActivatedBy', 'filter[client_uid]'],
    ['OperationID', 'filter[operation_uid]'],
    ['amount', 'filter[charge][fractional]'],
    ['clientUuid', 'filter[client_uuid]'],
  ]),
  sortingQueryParams: new Map([
    ['humanId', 'human_id'],
    ['title', 'title'],
    ['amount', 'charge.fractional'],
    ['accountDetails', 'account_details'],
    ['statusName', 'state.name'],
    ['statusUpdatedAt', 'state.updated_at'],
    ['createdAt', 'created_at'],
    ['updatedAt', 'updated_at'],
    ['code', 'code'],
    ['email', 'email'],
    ['name', 'name'],
    ['operationUid', 'operation_uid'],
    ['agentUid', 'agent_uid'],
    ['clientUid', 'client_id'],
    ['operationUId', 'operation_id'],
    ['externalOperationUid', 'external_operation_uid'],
    ['recepientUserUid', 'recepient_user_uid'],
    ['walletId', 'wallet_id'],
  ]),
};
