<script>
import VueTypes from 'vue-types';
import { mapActions, mapGetters } from 'vuex';
import { ACTION_FETCH_AGENT_TITLES, GETTER_AGENT_TITLES } from '@/store/modules/shared-cache/types';
import { getSecondsFrom } from '@/utils';

export default {
  name: 'agent-titles-provider',
  props: {
    onInit: VueTypes.bool.def(true),
  },
  async created() {
    if (this.onInit) {
      await this.fetchTitles();
      this.isTitlesLoaded = true;
    }
  },
  data() {
    return {
      isTitlesLoaded: false,
    };
  },
  computed: {
    ...mapGetters('shared-cache', [GETTER_AGENT_TITLES]),

    agentTitles() {
      return this[GETTER_AGENT_TITLES]?.titles;
    },
    isCacheInvalid() {
      const timeout = 120; // time in seconds after need to invalidate
      const recordDate = this[GETTER_AGENT_TITLES]?.recordDate;

      return recordDate ? getSecondsFrom(recordDate) >= timeout : true;
    },
  },
  methods: {
    ...mapActions('shared-cache', [ACTION_FETCH_AGENT_TITLES]),

    async fetchTitles(forceUpdate = false) {
      if (forceUpdate || this.isCacheInvalid) {
        await this[ACTION_FETCH_AGENT_TITLES]();
      }
    },
  },
  render() {
    return this.$scopedSlots.default({
      isTitlesLoaded: this.isTitlesLoaded,
      agentTitles: this[GETTER_AGENT_TITLES]?.titles,
      fetchTitles: this.fetchTitles,
    });
  },
};
</script>
