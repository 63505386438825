<template>
  <div class="agent-info-card mt-2 pa-7 rounded-xl">
    <div
      class="agent-info-card__info-wrapper"
      v-for="({ title, description }, idx) in cardData"
      :key="`${title}${idx}`"
    >
      <item-info :title="title" :description="description" />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import get from 'lodash.get';
import pick from 'lodash.pick';
import capitalize from 'lodash.capitalize';
import { formatDate, fromSnakeToNormal } from '@/utils';

import { ItemInfo } from '@/components/common/item-info';

export default {
  name: 'agent-info-card',
  components: {
    ItemInfo,
  },
  props: {
    item: VueTypes.object.def(() => ({})),
  },
  computed: {
    attributes() {
      return get(this.item, 'attributes', null);
    },
    cardData() {
      const dateFields = ['created_at', 'updated_at'];
      const data = pick(this.attributes, ['uid', 'title', 'email', 'name', ...dateFields]);

      return Object.entries(data).map(([key, val]) => ({
        title: capitalize(fromSnakeToNormal(key)),
        description: dateFields.includes(key) ? formatDate(val) : val,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'agent-info-card';
</style>
