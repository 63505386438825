<template>
  <tr>
    <td>{{ withdrawal.amount }}</td>
    <td>{{ withdrawal.truncatedAccountDetails }}</td>
    <td>{{ withdrawal.createdAt }}</td>
    <td>
      <router-link :to="redirectAttrs">
        Show
      </router-link>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';
import { ClientWithdrawal } from '@/models';

export default {
  name: 'table-row',
  props: {
    withdrawal: VueTypes.instanceOf(ClientWithdrawal).isRequired,
    routeNameToItem: VueTypes.string.isRequired,
  },
  computed: {
    redirectAttrs() {
      return {
        name: this.routeNameToItem,
        params: {
          uid: this.withdrawal.id,
          agent_uid: this.withdrawal.agentUid,
        },
      };
    },
  },
};
</script>
