<template>
  <div id="home">
    <v-container>
      <v-layout d-flex flex-column align-center justify-center>
        <div class="display-3">Page not found.</div>
        <div class="grey--text lighten-5 mt-8 mb-8">
          Try going back to home page and repeat your action or contact your manager.
        </div>
        <v-btn color="primary" :to="{ name: homePageLink }">
          Go to homepage
        </v-btn>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getHomePageName } from '@/router/helpers';

export default {
  name: 'page-not-found',
  computed: {
    ...mapGetters('auth', ['authData']),

    homePageLink() {
      const { roles } = this.authData;

      return getHomePageName({ userRole: roles && roles.length > 0 ? roles[0] : '' });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  height: 80vh;
}
</style>
