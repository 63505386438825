export const ACTION_CHANGE_ITEM_STATUS = 'ACTION_HANDLE_CONTENT_DATA';

export const ACTION_ACCEPT_AGENT_WITHDRAWAL = 'ACTION_ACCEPT_AGENT_WITHDRAWAL';
export const ACTION_CANCEL_AGENT_WITHDRAWAL = 'ACTION_CANCEL_AGENT_WITHDRAWAL';
export const ACTION_REJECT_AGENT_WITHDRAWAL = 'ACTION_REJECT_AGENT_WITHDRAWAL';
export const ACTION_START_PROCESSING_AGENT_WITHDRAWAL = 'ACTION_START_PROCESSING_AGENT_WITHDRAWAL';

export const ACTION_ACCEPT_AGENT_DEPOSIT = 'ACTION_ACCEPT_AGENT_DEPOSIT';
export const ACTION_CANCEL_AGENT_DEPOSIT = 'ACTION_CANCEL_AGENT_DEPOSIT';
export const ACTION_REJECT_AGENT_DEPOSIT = 'ACTION_REJECT_AGENT_DEPOSIT';
export const ACTION_START_PROCESSING_AGENT_DEPOSIT = 'ACTION_START_PROCESSING_AGENT_DEPOSIT';

export const ACTION_CANCEL_PREPAID_CARD = 'ACTION_CANCEL_PREPAID_CARD';

export const ACTION_CREATE_AGENT_DEPOSIT_FOR_AGENT = 'ACTION_CREATE_AGENT_DEPOSIT_FOR_AGENT';
export const ACTION_UPDATE_AGENT_DEPOSIT = 'ACTION_UPDATE_AGENT_DEPOSIT';

export const ACTION_CREATE_PREPAID_CARD_FOR_AGENT = 'ACTION_CREATE_PREPAID_CARD_FOR_AGENT';

export const ACTION_CREATE_AGENT_WITHDRAWAL_FOR_AGENT = 'ACTION_CREATE_AGENT_WITHDRAWAL_FOR_AGENT';
export const ACTION_UPDATE_AGENT_WITHDRAWAL = 'ACTION_UPDATE_AGENT_WITHDRAWAL';

export const ACTION_REMOVE_FORM_ERRORS = 'ACTION_REMOVE_FORM_ERRORS';
