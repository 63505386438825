var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('financier-commands-provider',{attrs:{"commands-for":_vm.commandsFor},scopedSlots:_vm._u([{key:"default",fn:function(commands){return [_c('app-list',{attrs:{"item-classes":['mr-0'],"list-items":_vm.buttons},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var color = ref_item.color;
var classes = ref_item.classes;
var commandName = ref_item.commandName;
var text = ref_item.text;
var outlined = ref_item.outlined;
return [(!(commandName === 'edit' && _vm.isProcessing))?_c('app-btn',{class:classes,attrs:{"id":id,"color":color,"outlined":outlined},on:{"click":function($event){return _vm.getClickHandler(commandName, commands)}}},[(commandName === 'edit')?_c('v-icon',{attrs:{"left":true}},[_vm._v(" mdi-pencil ")]):_vm._e(),_vm._v(" "+_vm._s(text)+" ")],1):_vm._e()]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }