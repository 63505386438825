<template>
  <plain-form
    submit-button-text="Create"
    :server-errors="serverErrors"
    :form="$options.formData"
    @submit="$emit('submit', $event)"
  />
</template>

<script>
import VueTypes from 'vue-types';

import { PlainForm } from '@/components/common/plain-form';
import createFormData from './create-form-data';

export default {
  name: 'form-create-agent',
  components: {
    PlainForm,
  },
  props: {
    serverErrors: VueTypes.arrayOf(VueTypes.object).def(() => [{}]),
  },
  formData: createFormData,
};
</script>
