import { AGENT_ROUTES } from '@/router/routes-const';
import AgentHome from '@/views/agent/AgentHome.vue';
import AgentProfile from '@/views/agent/AgentProfile.vue';
import AgentNewAcardInfo from '@/views/agent/AgentNewAcardInfo.vue';
import AgentDepositsNew from '@/views/agent/deposits/New.vue';
import AgentDepositsShow from '@/views/agent/deposits/Show.vue';
import AgentDepositsIndex from '@/views/agent/deposits/Index.vue';
import AgentWithdrawalsNew from '@/views/agent/withdrawals/New.vue';
import AgentWithdrawalsShow from '@/views/agent/withdrawals/Show.vue';
import AgentWithdrawalsIndex from '@/views/agent/withdrawals/Index.vue';
import AgentClientsWithdrawalsIndex from '@/views/agent/clients/withdrawals/index/Index.vue';
import AgentClientsWithdrawalsShow from '@/views/agent/clients/withdrawals/Show.vue';
import AgentPrepaidCardsIndex from '@/views/agent/prepaid_cards/index/Index.vue';
import AgentPrepaidCardsNew from '@/views/agent/prepaid_cards/New.vue';
import AgentPrepaidCardsShow from '@/views/agent/prepaid_cards/Show.vue';
import PageClientDeposit from '@/views/agent/page-client-deposit/page-client-deposit.vue';
import PageDetailClientDeposit from
  '@/views/agent/page-client-deposit/page-detail-client-deposits/page-detail-client-deposit.vue';

const PageCreateClientDeposit = () => import(
  /* webpackChunkName: "page-create-client-deposit" */
  '@/views/agent/page-client-deposit/page-created-client-deposit/page-created-client-deposit.vue'
);

export default [
  {
    path: AGENT_ROUTES.HOME.PATH,
    name: AGENT_ROUTES.HOME.NAME,
    component: AgentHome,
  },
  {
    path: AGENT_ROUTES.PROFILE.PATH,
    name: AGENT_ROUTES.PROFILE.NAME,
    component: AgentProfile,
  },
  {
    path: AGENT_ROUTES.DEPOSITS.PATH,
    name: AGENT_ROUTES.DEPOSITS.NAME,
    component: AgentDepositsIndex,
  },
  {
    path: AGENT_ROUTES.NEW_DEPOSIT.PATH,
    name: AGENT_ROUTES.NEW_DEPOSIT.NAME,
    component: AgentDepositsNew,
  },
  {
    path: AGENT_ROUTES.AGENT_DEPOSIT.PATH,
    name: AGENT_ROUTES.AGENT_DEPOSIT.NAME,
    component: AgentDepositsShow,
  },
  {
    path: AGENT_ROUTES.WITHDRAWALS.PATH,
    name: AGENT_ROUTES.WITHDRAWALS.NAME,
    component: AgentWithdrawalsIndex,
  },
  {
    path: AGENT_ROUTES.NEW_WITHDRAWAL.PATH,
    name: AGENT_ROUTES.NEW_WITHDRAWAL.NAME,
    component: AgentWithdrawalsNew,
  },
  {
    path: AGENT_ROUTES.AGENT_WITHDRAWAL.PATH,
    name: AGENT_ROUTES.AGENT_WITHDRAWAL.NAME,
    component: AgentWithdrawalsShow,
  },
  {
    path: AGENT_ROUTES.CLIENT_WITHDRAWALS.PATH,
    name: AGENT_ROUTES.CLIENT_WITHDRAWALS.NAME,
    component: AgentClientsWithdrawalsIndex,
  },
  {
    path: AGENT_ROUTES.CLIENT_WITHDRAWAL.PATH,
    name: AGENT_ROUTES.CLIENT_WITHDRAWAL.NAME,
    component: AgentClientsWithdrawalsShow,
  },
  {
    path: AGENT_ROUTES.PREPAID_CARDS.PATH,
    name: AGENT_ROUTES.PREPAID_CARDS.NAME,
    component: AgentPrepaidCardsIndex,
  },
  {
    path: AGENT_ROUTES.NEW_PREPAID_CARD.PATH,
    name: AGENT_ROUTES.NEW_PREPAID_CARD.NAME,
    component: AgentPrepaidCardsNew,
  },
  {
    path: AGENT_ROUTES.CLIENT_PREPAID_CARDS.PATH,
    name: AGENT_ROUTES.CLIENT_PREPAID_CARDS.NAME,
    component: AgentPrepaidCardsShow,
  },
  {
    path: AGENT_ROUTES.CLIENT_DEPOSITS.PATH,
    name: AGENT_ROUTES.CLIENT_DEPOSITS.NAME,
    component: PageClientDeposit,
  },
  {
    path: AGENT_ROUTES.NEW_CLIENT_DEPOSITS.PATH,
    name: AGENT_ROUTES.NEW_CLIENT_DEPOSITS.NAME,
    component: PageCreateClientDeposit,
  },
  {
    path: AGENT_ROUTES.AGENT_CLIENT_DEPOSITS.PATH,
    name: AGENT_ROUTES.AGENT_CLIENT_DEPOSITS.NAME,
    component: PageDetailClientDeposit,
  },
  {
    path: AGENT_ROUTES.NEW_ACARD_INFO.PATH,
    name: AGENT_ROUTES.NEW_ACARD_INFO.NAME,
    component: AgentNewAcardInfo,
  },
].map((route) => ({
  ...route,
  meta: {
    roles: ['agent'],
  },
}));
