import { TableDataApiService } from '@/services';
import mappers from './mappers';
import state from './state';
import createActions from './actions';
import mutations from './mutations';
import getters from './getters';

export function createStoreSharedCacheModule({ oauth, axios }) {
  return {
    namespaced: true,
    state: () => state,
    actions: createActions({
      tableDataService: new TableDataApiService(oauth, axios, mappers),
    }),
    mutations,
    getters,
  };
}
