<template>
  <plain-form
    submit-button-text="Create"
    :server-errors="serverErrors"
    :form="$options.formData"
    :disabled="isContentLoading"
    @submit="submit"
    @model-change="customValidation"
  />
</template>

<script>
import VueTypes from 'vue-types';
import { setFormRules } from '@/services';
import { mapGetters } from 'vuex';
import { PlainForm } from '@/components/common/plain-form';
import { GETTER_CONTENT_LOADING } from '../../store/types';
import { STORE_MODULE_CLIENT_DEPOSITS_CREATION } from '../../const';

import createFormData from './create-form-data';

export default {
  name: 'form-create-client-deposit',
  components: {
    PlainForm,
  },
  props: {
    serverErrors: VueTypes.arrayOf(VueTypes.object).def(() => [{}]),
  },
  computed: {
    ...mapGetters(STORE_MODULE_CLIENT_DEPOSITS_CREATION, [GETTER_CONTENT_LOADING]),
    isContentLoading() {
      return this.GETTER_CONTENT_LOADING;
    },
  },
  methods: {
    submit(event) {
      if (event.wallet_id || event.phone) {
        this.$emit('submit', event);
      }
    },
    isRuleUndefined(rule) {
      return rule() === setFormRules(['isNotUndefined'])[0]();
    },
    resetValidationRules(field) {
      field.rules = [
        ...setFormRules(['isNumber', 'isNotUndefined']),
      ];
    },
    currenField({ fields, modelName }) {
      return fields.filter((field) => field?.modelName === modelName);
    },
    customValidation(val) {
      const FIELD_WALLET = 'wallet_id';
      const FIELD_PHONE = 'phone';

      const { modelName } = val.field;

      const [fieldWallet] = this.currenField({
        fields: this.$options.formData,
        modelName: FIELD_WALLET,
      });
      const [fieldPhone] = this.currenField({
        fields: this.$options.formData,
        modelName: FIELD_PHONE,
      });

      const customValidator = {
        [FIELD_WALLET]: (() => {
          fieldPhone.rules.forEach((rule) => {
            if (this.isRuleUndefined(rule)) {
              fieldPhone.rules = [];
            }
          });
          if (!val.inputVal) {
            this.resetValidationRules(fieldPhone);
          }
        }),
        [FIELD_PHONE]: (() => {
          fieldWallet.rules.forEach((rule) => {
            if (this.isRuleUndefined(rule)) {
              fieldWallet.rules = [];
            }
          });
          if (!val.inputVal) {
            this.resetValidationRules(fieldWallet);
          }
        }),
      };

      return customValidator?.[modelName] ? customValidator?.[modelName]() : false;
    },
  },
  formData: createFormData,
};
</script>
