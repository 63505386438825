export default {
  HOME: {
    NAME: 'agent_home',
    PATH: '/agent',
  },
  PROFILE: {
    NAME: 'agent_profile',
    PATH: '/agent/profile',
  },
  DEPOSITS: {
    NAME: 'agent_deposits',
    PATH: '/agent/deposits',
  },
  NEW_DEPOSIT: {
    NAME: 'new_agent_deposit',
    PATH: '/agent/deposits/new',
  },
  AGENT_DEPOSIT: {
    NAME: 'agent_deposit',
    PATH: '/agent/deposits/:uid',
  },
  WITHDRAWALS: {
    NAME: 'agent_withdrawals',
    PATH: '/agent/withdrawals',
  },
  NEW_WITHDRAWAL: {
    NAME: 'new_agent_withdrawals',
    PATH: '/agent/withdrawals/new',
  },
  AGENT_WITHDRAWAL: {
    NAME: 'agent_withdrawal',
    PATH: '/agent/withdrawals/:uid',
  },
  CLIENT_WITHDRAWALS: {
    NAME: 'agent_clients_withdrawals',
    PATH: '/agent/clients/withdrawals',
  },
  CLIENT_WITHDRAWAL: {
    NAME: 'agent_clients_withdrawal',
    PATH: '/agent/clients/withdrawals/:uid',
  },
  PREPAID_CARDS: {
    NAME: 'agent_prepaid_cards',
    PATH: '/agent/prepaid_cards',
  },
  NEW_PREPAID_CARD: {
    NAME: 'new_agent_prepaid_card',
    PATH: '/agent/prepaid_cards/new',
  },
  CLIENT_PREPAID_CARDS: {
    NAME: 'agent_prepaid_card',
    PATH: '/agent/prepaid_cards/:uid',
  },
  CLIENT_DEPOSITS: {
    NAME: 'agent_client_deposits',
    PATH: '/agent/client-deposits',
  },
  AGENT_CLIENT_DEPOSITS: {
    NAME: 'agent_show_client_deposits',
    PATH: '/agent/client-deposits/:uid',
  },
  NEW_CLIENT_DEPOSITS: {
    NAME: 'agent_create_client_deposits',
    PATH: '/agent/client-deposits/new',
  },
  NEW_ACARD_INFO: {
    NAME: 'agent_new_acard_info',
    PATH: '/agent/new-acard-info',
  },
};
