import Vue from 'vue';

export default {
  acceptAgentWithdrawal({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.post(`agent-withdrawals/${uid}/accept`));
  },
  cancelAgentWithdrawal({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.post(`agent-withdrawals/${uid}/cancel`));
  },
  rejectAgentWithdrawal({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.post(`agent-withdrawals/${uid}/reject`));
  },
  startProcessingAgentWithdrawal({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.post(`/agent-withdrawals/${uid}/process`));
  },
  acceptAgentDeposit({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.post(`agent-deposits/${uid}/accept`));
  },
  cancelAgentDeposit({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.post(`agent-deposits/${uid}/cancel`));
  },
  rejectAgentDeposit({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.post(`agent-deposits/${uid}/reject`));
  },
  startProcessingAgentDeposit({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.post(`/agent-deposits/${uid}/process`));
  },
  cancelPrepaidCard({ code }) {
    return Vue.$oauth.access(() => Vue.axios.post(`prepaid-cards/${code}/cancel`));
  },

  createAgentDepositForAgent({ agentUid, data, financier = false }) {
    const endpoint = financier ? `financier/agents/${agentUid}/agent-deposits` : `agents/${agentUid}/agent-deposits`;
    return Vue.$oauth.access(() => Vue.axios.post(endpoint, { data }));
  },
  updateAgentDeposit({ uid, data }) {
    return Vue.$oauth.access(() => Vue.axios.patch(`agent-deposits/${uid}`, { data }));
  },
  createPrepaidCardForAgent({ agentUid, data }) {
    return Vue.$oauth.access(() => Vue.axios.post(`agents/${agentUid}/prepaid-cards`, { data }));
  },
  createAgentWithdrawalForAgent({ agentUid, data }) {
    return Vue.$oauth.access(() => Vue.axios.post(`agents/${agentUid}/agent-withdrawals`, { data }));
  },
  updateAgentWithdrawal({ uid, data }) {
    return Vue.$oauth.access(() => Vue.axios.patch(`agent-withdrawals/${uid}`, { data }));
  },
};
