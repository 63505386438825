<template>
  <tr>
    <td>
      <app-clipboard
        v-if="item.walletId"
        :value="item.walletId"
      >
        {{ item.walletId }}
      </app-clipboard>
    </td>
    <td>{{ item.amount }}</td>
    <td>{{ item.externalOperationUid }}</td>
    <td>{{ item.recepientUserUid }}</td>
    <td>{{ item.createdAt }}</td>
    <td>{{ item.updatedAt }}</td>
    <td>
      <router-link :to="redirectAttrs">Show</router-link>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';
import { AppClipboard } from '@/components/common/app-clipboard';

export default {
  name: 'client-deposits-table-row',
  components: {
    AppClipboard,
  },
  props: {
    item: VueTypes.object.isRequired,
    routeNameToItem: VueTypes.string.isRequired,
  },
  computed: {
    redirectAttrs() {
      return {
        name: this.routeNameToItem,
        params: {
          uid: this.item.id,
          agent_uid: this.item.agentUid,
        },
      };
    },
  },
};
</script>
