<template lang="pug">
  v-flex(xs12, sm8, offset-sm2, md6, offset-md3, lg4, offset-lg4)
    h1 Sign in
    container-form-authorization
</template>

<script>
import { ContainerFormAuthorization } from '@/components/containers/common/form-authorization';

export default {
  name: 'page-sign-in',
  components: {
    ContainerFormAuthorization,
  },
};
</script>
