// actions
export const ACTION_CREATE_CLIENT_DEPOSITS = 'ACTION_CREATE_CLIENT_DEPOSITS';
export const ACTION_CHANGE_LOADING_CONTENT_STATUS = 'ACTION_CHANGE_LOADING_CONTENT_STATUS';

// mutations
export const MUTATION_SET_SERVER_VALIDATION_ERRORS = 'MUTATION_SET_SERVER_VALIDATION_ERRORS';
export const MUTATION_SET_CONTENT_LOADING_STATUS = 'MUTATION_SET_CONTENT_LOADING_STATUS';

// getters
export const GETTER_SERVER_VALIDATION_ERRORS = 'GETTER_SERVER_VALIDATION_ERRORS';
export const GETTER_CONTENT_LOADING = 'GETTER_CONTENT_LOADING';
