<template>
  <v-container class="page-guideline">
    <v-flex xs="12" md="10" offset-md="1">
      <h1 class="mb-10">Guidline</h1>
      <module-acard-guideline />
    </v-flex>
  </v-container>
</template>

<script>
export default {
  name: 'page-guideline',
};
</script>
