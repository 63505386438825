const getIndexedCollection = (arrOfCollections, result = {}) => {
  if (arrOfCollections.length) {
    const firstCollection = arrOfCollections[0];
    const accInitField = firstCollection[0].type;
    const accumulator = { ...result, [accInitField]: {} };

    const indexedCollection = firstCollection.reduce((acc, { type, id, ...rest }) => {
      acc[accInitField][id] = { ...rest, id };
      return acc;
    }, accumulator);

    return getIndexedCollection(arrOfCollections.slice(1), indexedCollection);
  }

  return result;
};

export default function ({ agents = {}, agentsFinances = [], pagination }) {
  return {
    items: agents.map((agent) => {
      const { id, attributes: { uid: agentUid, ...restAttributes } } = agent;
      const balance = (
        getIndexedCollection([agentsFinances])?.['agent-finances']?.[agentUid]?.attributes?.usd?.total?.amount
      );

      return {
        id,
        agentUid,
        humanId: restAttributes.humanId,
        title: restAttributes.title,
        email: restAttributes.email,
        name: restAttributes.name,
        balance: balance || 0,
        updatedAt: restAttributes.updatedAt,
        createdAt: restAttributes.createdAt,
      };
    }),
    pagination,
  };
}
