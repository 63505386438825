<template>
  <div class="client-deposits-card mt-2 pa-7 rounded-xl">
    <div
      class="client-deposits-card__info-wrapper"
      v-for="(val, key) in cardData"
      :key="key"
    >
      <div
        v-if="val"
        class="item-info"
      >
        <strong>
          {{ key }}
        </strong>
        <template>
          <app-clipboard
            v-if="getLinkForRedirect(key)"
            classes="mb-4"
            :value="val"
          >
            <a :href="getLinkForRedirect(key)" target="_blank">{{ val }}</a>
          </app-clipboard>
          <p v-else>{{ val }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { cardMixin } from '@/mixins';
import VueTypes from 'vue-types';
import get from 'lodash.get';
import { createDetailLinks } from '@/utils';
import { AppClipboard } from '@/components/common/app-clipboard';

export default {
  name: 'client-deposits-card-financier',
  components: {
    AppClipboard,
  },
  mixins: [
    cardMixin,
  ],
  props: {
    item: VueTypes.object.def(() => ({})),
  },
  computed: {
    getDepositsId() {
      return this.item?.id ?? this.$route.params.uid;
    },
    geAgentUid() {
      return get(this.mix_attributes, 'agent_uid', '');
    },
    getOperationUid() {
      return get(this.mix_attributes, 'external_operation_uid', '');
    },
    getUserUid() {
      return get(this.mix_attributes, 'recepient_user_uid', '');
    },
    getWalletId() {
      return get(this.mix_attributes, 'wallet_id', '');
    },
    agentTitle() {
      return get(this.mix_attributes, 'agent_title', '-');
    },
    cardData() {
      return {
        'Id': this.getDepositsId,
        'Wallet Id': this.getWalletId,
        'Agent Uid': this.geAgentUid,
        'Amount': this.mix_amount,
        'External Operation UID': this.getOperationUid,
        'Recipient User UID': this.getUserUid,
        'Created at': this.mix_createdAt,
        'Update at': this.mix_updateAt,
        'Agent title': this.agentTitle,
      };
    },
    detailLinks() {
      return createDetailLinks('clientDeposits', {
        operationId: this.getOperationUid,
        clientId: this.getUserUid,
        agentUid: this.geAgentUid,
      });
    },
  },
  methods: {
    getLinkForRedirect(key) {
      const links = {
        'AGENT_UID': 'Agent Uid',
        'OPERATION_UID': 'External Operation UID',
        'USER_UID': 'Recipient User UID',
      };

      const getLink = {
        [links.AGENT_UID]: this.detailLinks.linkToAgent,
        [links.OPERATION_UID]: this.detailLinks.linkToEditOperation,
        [links.USER_UID]: this.detailLinks.linkToUser,
      };

      return getLink[key] || false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'client-deposits-card';
</style>
