import rules from './rules';

/**
 * This service is a helper func with
 * rules methods which validates VUETIFY form fields
 */

export default function (formRules = []) {
  return Array.isArray(formRules) && formRules.length
    ? formRules.reduce((acc, nextRule) => {
      const ruleIdx = 0;
      const errorMessageIdx = 1;
      const argumentIdx = 2;
      const hasArgsSeparator = nextRule.includes('|');
      const ruleName = hasArgsSeparator ? nextRule.split('|')[ruleIdx] : nextRule;
      const errorMsg = hasArgsSeparator ? nextRule.split('|')[errorMessageIdx] : '';
      const argument = hasArgsSeparator ? nextRule.split('|')[argumentIdx] : '';
      const selectedRule = rules?.[ruleName];

      if (selectedRule) {
        acc.push(errorMsg ? selectedRule({ errorMsg, argument }) : selectedRule({ argument }));
      }

      return acc;
    }, [])
    : [];
}
