var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('h1',[_vm._v("Payment Agents")]),_c('table-content-provider',{attrs:{"table-type":_vm.$options.constants.tableType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isInitLoadingComplete = ref.isInitLoadingComplete;
var pagination = ref.pagination;
var items = ref.items;
var handleChange = ref.handleChange;
return [(isInitLoadingComplete)?_c('div',[_c('table-complex',{attrs:{"filters":_vm.tableFilters,"filter-names":_vm.filterNames,"headers":_vm.tableHeaders,"items":items,"min-items-per-page":_vm.$options.constants.minItemsPerPage,"pagination":pagination},on:{"pagination-change":handleChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('payment-agents-table-row',{attrs:{"payment-agent":item,"route-name-to-item":_vm.$options.constants.agentsRoute}})]}}],null,true)}),(_vm.isUserHasFinancierRole)?_c('app-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: _vm.$options.constants.newAgentRoute })}}},[_vm._v(" New ")]):_vm._e()],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }