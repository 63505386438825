<template>
  <div>
    <v-select
      label="PaymentSystem"
      :items="selectItems"
      :value="currentPaymentSystem"
      @input="handleChangePaymentSystem"
    />
    <plain-form
      :submit-button-text="submitBtnText"
      :server-errors="serverErrors"
      :form="form"
      :init-values="initValues"
      :disabled="isContentLoading"
      @submit="handlePlainFormSubmit"
    />
    <form-deposit-hidden
      v-if="!isNotPerfectMoney"
      :last-created-deposit="lastCreatedDeposit"
      :form-action="formAction"
      @pm-data-sent="$emit('pm-data-sent')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueTypes from 'vue-types';
import { PAYMENT_SYSTEMS, PERFECT_MONEY_FORM_ACTION } from '@/consts';
import { PlainForm } from '@/components/common/plain-form';
import formManualData from './form-data';

import FormDepositHidden from './form-deposit-hidden.vue';

const createFormPerfectMoneyData = (manualData) => (
  manualData.filter((field) => field.modelName !== 'accountDetails')
);

export default {
  name: 'form-deposit',
  components: {
    FormDepositHidden,
    PlainForm,
  },
  props: {
    serverErrors: VueTypes.arrayOf(VueTypes.object).def(() => [{}]),
    initValues: VueTypes.arrayOf(VueTypes.object).def(() => [{}]),
    lastCreatedDeposit: VueTypes.object.def(() => ({})),
    submitBtnText: VueTypes.string,
    isContentLoading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      currentPaymentSystem: PAYMENT_SYSTEMS.PERFECT_MONEY,
    };
  },
  computed: {
    ...mapGetters('auth', ['isUserHasFinancierRole']),

    availablePaymentSystems() {
      return PAYMENT_SYSTEMS;
    },
    formAction() {
      return PERFECT_MONEY_FORM_ACTION;
    },
    form() {
      return this.isNotPerfectMoney || this.isUserHasFinancierRole
        ? formManualData
        : createFormPerfectMoneyData(formManualData);
    },
    isNotPerfectMoney() {
      return this.currentPaymentSystem !== this.availablePaymentSystems.PERFECT_MONEY;
    },
    selectItems() {
      return Object.values(this.availablePaymentSystems);
    },
  },
  methods: {
    handleChangePaymentSystem(paymentSystem) {
      this.currentPaymentSystem = paymentSystem;
    },
    handlePlainFormSubmit(values) {
      this.$emit('submit', { ...values, paymentSystem: this.currentPaymentSystem });
    },
  },
};
</script>
