<template>
  <div id="home">
    <v-container>
      <h1>Home</h1>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'FinancierHome',
};
</script>
