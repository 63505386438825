<template>
  <v-icon
    :class="rootClasses"
    :color="color"
    :size="size"
  >
    {{ mdiIcon }}
  </v-icon>
</template>

<script>
import VueTypes from 'vue-types';
import iconsMap from './icons-map';

export default {
  name: 'app-icon',
  props: {
    icon: VueTypes.oneOf(
      Object.keys(iconsMap),
    ).isRequired,
    color: VueTypes.oneOf([
      'primary',
      'secondary',
      'error',
      'accent',
      'info',
    ]).def('accent'),
    isCursorPointer: VueTypes.bool.def(false),
    size: VueTypes.number.def(17),
  },
  mainClass: 'app-icon',
  computed: {
    rootClasses() {
      return [
        this.$options.mainClass,
        this.isCursorPointer ? `${this.$options.mainClass}--c-pointer` : null,
      ];
    },
    mdiIcon() {
      return iconsMap?.[this.icon];
    },
  },
};
</script>

<style lang="scss">
  @import 'app-icon';
</style>
