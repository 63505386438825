import axios from 'axios';
// TODO: need to refactor code below
/* eslint-disable */
import AccessInteractor from './access_interactor';

class OauthClient {
  // Настроить клиент для работы в приложении
  constructor(opts) {
    this.tokenUrl = opts.tokenUrl;

    this.getAccessToken = opts.getAccessToken;
    this.getRefreshToken = opts.getRefreshToken;
    this.getUserUid = opts.getUserUid;

    // коллбэк если обновить токен не удалось
    //   получает null если getRefreshToken() или getAccessToken() вернул null
    //   получает результат негативного ответа refresh()
    this.onSignInRequired = opts.onSignInRequired;
    // сохранение аутентификационных данных на клиенте
    this.setAuth = opts.setAuth;

    // приготовить интерактор для осуществления доступа к защищенным ресурсам
    this.__accessInteractor = new AccessInteractor({
      getAccessToken: opts.getAccessToken,
      getExpiresAt: opts.getExpiresAt,
      onSignInRequired: opts.onSignInRequired,
      refresh: () => this.refresh(),
    });
  }

  // Получить токены в обмен на Resource Owner Password Credentials (логин, пароль)
  password(credentials) {
    const { username, password, reCaptchaToken } = credentials;
    return this.__tokenRequest(
      'password',
      { username, password },
      reCaptchaToken,
    );
  }

  // Обновить токены
  refresh() {
    return (
      this.__tokenRequest('refresh_token', { refresh_token: this.getRefreshToken() })
        .catch(this.__mapRefreshError)
        .catch(this.__onErrorCallback(this.onSignInRequired))
    );
  }

  // Осуществить запрос к эндпоинту, требующему авторизации
  access(request) {
    return this.__accessInteractor.call(request);
  }

  __tokenRequest(grantType, body, reCaptchaToken = null) {
    const config = {
      method: 'POST',
      url: this.tokenUrl,
      headers: { Authorization: `Bearer ${this.getAccessToken()}` },
      data: { grant_type: grantType, ...body },
    };

    if (reCaptchaToken) {
      config.headers.rc_token = reCaptchaToken;
    }

    return (axios.request(config).then((response) => {
      const body = response.data;
      this.setAuth({
        access_token: body.access_token,
        refresh_token: body.refresh_token,
        expires_in: body.expires_in,
        expires_at: this.__timeNow() + body.expires_in,
      });
      return response;
    })
    );
  }

  // в случае ошибки обновления токена, сделать её доступной в неймспейсе refresh
  __mapRefreshError(httpError) {
    const namespacedHttpError = { refresh: httpError };
    throw namespacedHttpError; // refresh error always can be accessed from other field
  }

  __onSignInRequired(error) {
    this.onSignInRequired();
    throw error;
  }

  __onErrorCallback(callback) {
    return (error) => {
      callback(error);
      throw error;
    };
  }

  __timeNow() { return new Date().getTime() / 1000; }
}

export { OauthClient };
export default OauthClient;
