<template>
  <v-container class="page-create-client-deposit">
    <v-row justify="center">
      <v-col md="10">
        <h1>Create Client Deposit</h1>

        <module-client-deposit-creation />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'page-create-agent',
};
</script>
