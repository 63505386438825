import throttle from 'lodash.throttle';

export default function (errorHandler) {
  const delay = 250;
  const throttledHandleError = throttle(
    errorHandler.handleError,
    delay,
    { 'trailing': false },
  );

  return (action, state, error) => {
    throttledHandleError(error);
  };
}
