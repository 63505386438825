export default class TableFilter {
  label;
  autocompleteItems;
  filterFuncName;

  constructor(label, autocompleteItems, filterFuncName, isDate = false) {
    Object.assign(this, {
      label,
      autocompleteItems,
      filterFuncName,
      isDate,
    });
  }
}
