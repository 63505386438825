<template>
  <v-app class="app">
    <app-toolbar-container />
    <app-view-container />
    <app-footer />
  </v-app>
</template>

<script>
import { AppToolbarContainer } from '@/components/containers/app-toolbar';
import { AppViewContainer } from '@/components/containers/app-view';

export default {
  name: 'App',
  components: {
    AppToolbarContainer,
    AppViewContainer,
    AppFooter: () => import(
      /* webpackChunkName: "app-footer" */
      '@/components/core/app-footer.vue'
    ),
  },
};
</script>

<style lang="scss">
  @import "app";
</style>
