<template>
  <v-flex xs="12" md="10" offset-md="1">
    <h1 class="mb-4">Clients Withdrawals</h1>
    <table-content-provider
      :table-type="$options.constants.tableType"
      v-slot="{ isInitLoadingComplete, pagination, items, handleChange }"
    >
      <table-complex
        v-if="isInitLoadingComplete"
        :filters="tableFilters"
        :filter-names="filterNames"
        :headers="tableHeaders"
        :items="items"
        :min-items-per-page="$options.constants.minItemsPerPage"
        :pagination="pagination"
        @pagination-change="handleChange"
      >
        <template v-slot="{ item }">
          <table-row
            :withdrawal="item"
            :route-name-to-item="$options.constants.clientWithdrawalRoute"
          />
        </template>
      </table-complex>
    </table-content-provider>
  </v-flex>
</template>

<script>
import { TABLE_TYPES, MIN_ITEMS_PER_PAGE } from '@/consts';
import { FINANCIER_ROUTES } from '@/router/routes-const';
import { TableFilter, TableHeader } from '@/models';

import { TableContentProvider } from '@/components/providers/table-content';
import { TableComplex } from '@/components/common/table-complex';
import TableRow from './table-row.vue';

export default {
  name: 'FinancierClientsWithdrawalsIndex',
  components: {
    TableContentProvider,
    TableComplex,
    TableRow,
  },
  constants: {
    tableType: TABLE_TYPES.CLIENT_WITHDRAWALS,
    clientWithdrawalRoute: FINANCIER_ROUTES.CLIENT_WITHDRAWAL.NAME,
    minItemsPerPage: MIN_ITEMS_PER_PAGE,
  },
  computed: {
    filterNames() {
      return [
        'accountDetails',
        'operationUuid',
        'title',
        'clientUuid',
        'uid',
      ];
    },
    tableFilters() {
      return [
        new TableFilter('ID', [], this.filterNames[4]),
        new TableFilter('Account Details', [], this.filterNames[0]),
        new TableFilter('AMarkets Request UID', [], this.filterNames[1]),
        new TableFilter('Agent title', [], this.filterNames[2]),
        new TableFilter('Client UUID', [], this.filterNames[3]),
      ];
    },
    tableHeaders() {
      return [
        new TableHeader('ID', 'uid', false),
        new TableHeader('AMarkets Request UID', 'operationUuid', false),
        new TableHeader('Client UUID', 'clientUuid', false),
        new TableHeader('Client ID', 'clientUid'),
        new TableHeader('Amount', 'amount'),
        new TableHeader('Client account details', 'accountDetails'),
        new TableHeader('Created', 'createdAt'),
        new TableHeader('Agent title', 'title'),
        new TableHeader('', '', false),
      ];
    },
  },
};
</script>
