<template>
  <v-flex xs="12" md="10" offset-md="1">
    <h1 class="mb-4">Clients Withdrawals</h1>
    <table-content-provider
      :table-type="$options.constants.tableType"
      :agent-uid="authData.userUid"
      v-slot="{ isInitLoadingComplete, pagination, items, handleChange }"
    >
      <table-complex
        v-if="isInitLoadingComplete"
        :filters="tableFilters"
        :filter-names="filterNames"
        :headers="tableHeaders"
        :items="items"
        :min-items-per-page="$options.constants.minItemsPerPage"
        :pagination="pagination"
        @pagination-change="handleChange"
      >
        <template v-slot="{ item }">
          <table-row
            :withdrawal="item"
            :route-name-to-item="$options.constants.clientWithdrawalRoute"
          />
        </template>
      </table-complex>
    </table-content-provider>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { TABLE_TYPES, MIN_ITEMS_PER_PAGE } from '@/consts';
import { AGENT_ROUTES } from '@/router/routes-const';
import { TableFilter, TableHeader } from '@/models';

import { TableContentProvider } from '@/components/providers/table-content';
import { TableComplex } from '@/components/common/table-complex';
import TableRow from './table-row.vue';

export default {
  name: 'AgentWithdrawalsIndex',
  components: {
    TableContentProvider,
    TableComplex,
    TableRow,
  },
  constants: {
    tableType: TABLE_TYPES.AGENT_CLIENT_WITHDRAWALS_OF_AGENT,
    clientWithdrawalRoute: AGENT_ROUTES.CLIENT_WITHDRAWAL.NAME,
    minItemsPerPage: MIN_ITEMS_PER_PAGE,
  },
  computed: {
    ...mapGetters('auth', ['authData']),

    filterNames() {
      return ['accountDetails'];
    },
    tableFilters() {
      return [
        new TableFilter('Account Details', [], this.filterNames[0]),
      ];
    },
    tableHeaders() {
      return [
        new TableHeader('Amount', 'amount'),
        new TableHeader('Client account details', 'accountDetails'),
        new TableHeader('Created at', 'createdAt'),
        new TableHeader('', '', false),
      ];
    },
  },
};
</script>
