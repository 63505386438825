<template>
  <div class="prepaid-card">
    <div
      class="prepaid-card__info-wrapper"
      v-for="(val, key) in cardData"
      :key="key"
    >
      <item-info
        v-if="val"
        :title="key"
        :description="val"
      />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import get from 'lodash.get';
import { cardMixin } from '@/mixins';

import { ItemInfo } from '@/components/common/item-info';

export default {
  name: 'agent-prepaid-card',
  components: {
    ItemInfo,
  },
  mixins: [
    cardMixin,
  ],
  props: {
    item: VueTypes.object.def(() => ({})),
  },
  computed: {
    code() {
      return get(this.mix_attributes, 'code', '');
    },
    status() {
      return get(this.mix_attributes, ['state', 'name'], '');
    },
    clientUid() {
      return get(this.mix_attributes, 'client_uid', '');
    },
    operationUid() {
      return get(this.mix_attributes, 'operation_uid', '');
    },
    cardData() {
      return {
        'Amount': this.mix_amount,
        'Code': this.code,
        'Status': this.status,
        'Activated by User': this.clientUid,
        'Office Payment ID': this.operationUid,
        'Status updated at': this.mix_updatedAt,
        'Created at': this.mix_createdAt,
      };
    },
  },
};
</script>
