<template>
  <v-app-bar app flat>
    <v-container mx-auto py-0>
      <v-layout justify-center align-center>
        <toolbar-logo :logo-src="require('@/assets/logo.png')" :redirect-to="logoLink" />
        <router-link
          test-data-nav-link
          :class="['d-sm-none', 'd-md-flex', item.isActive ? 'r' : null]"
          v-for="(item, i) in navLinks"
          :key="i"
          :to="item.route"
          flat
        >
          {{ item.text }}
        </router-link>

        <v-spacer />
        <!-- link to user profile -->
        <toolbar-user-profile-link
          test-data-profile-link
          v-if="userLinkName"
          redirect-to="agent_profile"
        >
          {{ userLinkName }}
        </toolbar-user-profile-link>
        <template v-if="authUserId">
          <toolbar-guideline-btn />
          <toolbar-sign-out-btn
            test-data-sign-out-btn
            @sign-out="handleLogoutBtnClick"
          />
        </template>
      </v-layout>
    </v-container>
  </v-app-bar>
</template>

<script>
import VueTypes from 'vue-types';

import ToolbarLogo from './toolbar-logo.vue';
import ToolbarGuidelineBtn from './toolbar-guideline-btn.vue';

export default {
  name: 'toolbar',
  components: {
    ToolbarLogo,
    ToolbarGuidelineBtn,
    ToolbarSignOutBtn: () => import('./toolbar-sign-out-btn.vue'),
    ToolbarUserProfileLink: () => import('./toolbar-user-profile-link.vue'),
  },
  props: {
    authUserId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    userLinkName: VueTypes.string,
    navLinks: VueTypes.arrayOf(VueTypes.object).isRequired,
    logoLink: VueTypes.string.def(''),
  },
  methods: {
    handleLogoutBtnClick() {
      this.$emit('clicked-sign-out');
    },
  },
};
</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
    margin-right: 2%;
    text-transform: uppercase;
    font-weight: bold;
  }

  .r {
    text-decoration: underline;
  }
</style>
