import { formatDate } from '@/utils';

/**
 * Class representing the client deposits by of an agent
 */
export default class ClientDeposits {
  id;
  agentUid;
  externalOperationUid;
  recepientUserUid;
  amount;
  updatedAt;
  createdAt;
  walletId;
  agentTitle;

  constructor(opts) {
    const { currency, amount, ...rest } = opts;

    Object.assign(this, {
      ...rest,
      amount: `${amount} ${currency}`,
      updatedAt: formatDate(rest.updatedAt),
      createdAt: formatDate(rest.createdAt),
    });
  }
}
