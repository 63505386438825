<template>
  <tr>
    <td>
      <app-clipboard-truncated :value="item.id" />
    </td>
    <td>{{ item.title }}</td>
    <td>{{ item.amount }}</td>
    <td>
      <pre>{{ item.code }}</pre>
    </td>
    <td>
      <v-chip :color="item.statusColor" dark>
        {{ item.statusName }}
      </v-chip>
    </td>
    <td>
      <a v-if="item.isActivated" :href="detailLinks.linkToEditOperation" target="_blank">
        {{ item.operationUid }}
      </a>
    </td>
    <td>
      <a v-if="item.isActivated" :href="detailLinks.linkToUser" target="_blank">
        {{ item.clientUid }}
      </a>
    </td>
    <td>{{ item.statusUpdatedAt }}</td>
    <td>{{ item.createdAt }}</td>
    <td>
      <router-link :to="redirectAttrs">
        Show
      </router-link>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';
import { createDetailLinks } from '@/utils';
import { PrepaidCard } from '@/models';
import { AppClipboardTruncated } from '@/components/common/app-clipboard-truncated';

export default {
  name: 'table-row',
  components: { AppClipboardTruncated },
  props: {
    item: VueTypes.instanceOf(PrepaidCard),
    routeNameToItem: VueTypes.string.isRequired,
  },
  computed: {
    redirectAttrs() {
      return {
        name: this.routeNameToItem,
        params: {
          uid: this.item.id,
          agent_uid: this.item.agentUid,
        },
      };
    },
    detailLinks() {
      const { clientUid: clientId } = this.item;
      const { operationUid: operationId } = this.item;

      return createDetailLinks('prepaidCard', {
        clientId,
        operationId,
      });
    },
  },
};
</script>
