<template>
  <v-container>
    <h3>What's new:</h3>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>2FA login confirmation is available.</v-list-item-title>
            <v-list-item-subtitle>
              To enable 2FA, please turn it on in My Profile settings of your AMarkets account and
              log in to ACard using the new
              <a
                href="https://acard-front.prod.amarkets.dev/"
                target="_blank"
              >link</a>.
            </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="font-italic">
          <v-list-item-title class="font-weight-medium">Please note:</v-list-item-title>
          <v-list-item-subtitle>
            Once you have turned on 2FA, you won't be able to log in to the old version anymore.
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            In case you need to switch back to old ACard, you'll need to go back to your AMarkets and turn 2FA off.
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Creation of a new Prepaid Card is disabled.
          </v-list-item-title>
          <v-list-item-subtitle>
            You will still be able to see all the cards you have already issued,
            but won't be able to issue a new one.
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            In order to make a client deposit, please use the Client Deposit page
            <a
              href="https://acard-front.prod.amarkets.dev/client-deposits"
              target="_blank"
            >
              https://acard-front.prod.amarkets.dev/client-deposits
            </a>.
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <h3>What's old:</h3>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            The functionality remains the same.
          </v-list-item-title>
          <v-list-item-subtitle>
            Minor improvements in the interface will not affect your user experience.
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>
<script>
export default {
  name: 'AgentNewAcardInfo',
};
</script>
<style lang="scss">
.v-list-item__subtitle {
  white-space: inherit;
}
</style>
