<script>
import VueTypes from 'vue-types';
import { mapActions, mapGetters } from 'vuex';
import { ACTION_FETCH_TABLE_CONTENT, ACTION_REMOVE_TABLE_CONTENT } from '@/store/modules/content/action-types';
import { TABLE_TYPES, MIN_ITEMS_PER_PAGE } from '@/consts';

export default {
  name: 'table-content-provider',
  props: {
    tableType: VueTypes.oneOf(Object.values(TABLE_TYPES)).isRequired,
    agentUid: VueTypes.string.def(''),
  },
  async created() {
    await this[ACTION_FETCH_TABLE_CONTENT]({
      tableType: this.tableType,
      agentUid: this.agentUid,
      pageSize: MIN_ITEMS_PER_PAGE,
    });
    this.isInitLoadingComplete = true;
  },
  beforeDestroy() {
    // To not display a table with previously loaded data
    this[ACTION_REMOVE_TABLE_CONTENT]({
      tableType: this.tableType,
    });
  },
  data() {
    return {
      isInitLoadingComplete: false,
    };
  },
  computed: {
    ...mapGetters('content', ['getTableItems', 'getTablePagination']),

    pagination() {
      return this.getTablePagination({
        tableType: this.tableType,
      });
    },
    items() {
      return this.getTableItems({
        tableType: this.tableType,
        pageNumber: this.pagination?.currentPage,
      });
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_TABLE_CONTENT, ACTION_REMOVE_TABLE_CONTENT]),

    async handleChange({ pageNumber, pageSize, filters, sorting }) {
      await this[ACTION_FETCH_TABLE_CONTENT]({
        tableType: this.tableType,
        pageNumber,
        pageSize,
        filters,
        sorting,
        agentUid: this.agentUid,
      });
    },
  },
  render() {
    return this.$scopedSlots.default({
      isInitLoadingComplete: this.isInitLoadingComplete,
      pagination: this.pagination,
      items: this.items,
      handleChange: this.handleChange,
      fetchMoreItems: this.fetchMoreItems,
    });
  },
};
</script>
