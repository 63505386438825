<template>
  <div class="client-deposits-card mt-2 pa-7 rounded-xl">
    <div
      class="client-deposits-card__info-wrapper"
      v-for="(val, key) in cardData"
      :key="key"
    >
      <item-info
        v-if="val"
        :title="key"
        :description="val"
      />
    </div>
  </div>
</template>

<script>
import { ItemInfo } from '@/components/common/item-info';
import { cardMixin } from '@/mixins';
import VueTypes from 'vue-types';
import get from 'lodash.get';

export default {
  name: 'client-deposits-card',
  components: {
    ItemInfo,
  },
  mixins: [
    cardMixin,
  ],
  props: {
    item: VueTypes.object.def(() => ({})),
  },
  computed: {
    getDepositsId() {
      return this.item?.id ?? this.$route.params.uid;
    },
    getOperationUid() {
      return get(this.mix_attributes, 'external_operation_uid', '');
    },
    getUserUid() {
      return get(this.mix_attributes, 'recepient_user_uid', '');
    },
    getWalletId() {
      return get(this.mix_attributes, 'wallet_id', '');
    },
    cardData() {
      return {
        'Id': this.getDepositsId,
        'Wallet Id': this.getWalletId,
        'Amount': this.mix_amount,
        'External Operation UID': this.getOperationUid,
        'Recipient User UID': this.getUserUid,
        'Created at': this.mix_createdAt,
        'Update at': this.mix_updateAt,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'client-deposits-card';
</style>
