<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Deposit
    template(v-if="!isContentLoading")
      v-layout
        agent-operation-card(:item="item")
      v-btn(
        v-if="isDepositRequested"
        class="mt-3"
        width="200"
        color="error"
        @click="cancel"
      ) Cancel
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ACTION_FETCH_CONTENT, ACTION_FETCH_AGENT_DEPOSIT } from '@/store/modules/content/action-types';
import {
  ACTION_CHANGE_ITEM_STATUS,
  ACTION_CANCEL_AGENT_DEPOSIT,
} from '@/store/modules/content-interaction/action-types';
import { withConfirm } from '@/utils';

import { AgentOperationCard } from '@/components/cards/agent-operation-card';

export default {
  name: 'AgentDepositsShow',
  components: {
    AgentOperationCard,
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT_DEPOSIT,
      uid: this.$route.params.uid,
    });
  },
  computed: {
    ...mapGetters('content', ['getStateDataItem', 'isContentLoading']),

    isDepositRequested() {
      const itemState = this?.item?.attributes?.state?.name;
      return itemState === 'requested';
    },
    item() {
      return this.getStateDataItem({
        type: 'agent-deposits',
        id: this.$route.params.uid,
      });
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
    ...mapActions('content-interaction', [ACTION_CHANGE_ITEM_STATUS]),

    async cancel() {
      await withConfirm(this[ACTION_CHANGE_ITEM_STATUS])({
        actionType: ACTION_CANCEL_AGENT_DEPOSIT,
        uid: this.$route.params.uid,
      });
    },
  },
};
</script>
