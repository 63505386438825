export default class ErrorHandlerService {
  static _ERROR_TYPES_STATUSES = {
    VALIDATION: 400,
    BUSINESS_LOGIC: 422,
  };

  _transportErrorsMap;
  _errorStrategies;
  _notifier;

  constructor(transportErrorsMap, errorStrategies, notifier) {
    this._transportErrorsMap = transportErrorsMap;
    this._errorStrategies = errorStrategies;
    this._notifier = notifier;
  }

  handleError = (error) => {
    const errorType = ErrorHandlerService._getErrorType(error);
    const errorStrategy = this._errorStrategies.getStrategy(errorType);
    const errorMessage = errorStrategy(error);

    this._notifier.show({
      type: 'error',
      msg: errorMessage,
    });
  }

  static _isTransportError(error) {
    const statuses = Object.values(ErrorHandlerService._ERROR_TYPES_STATUSES);
    return !error?.response || !statuses.includes(error.response?.status);
  }

  static _isValidationError(error) {
    return ErrorHandlerService._ERROR_TYPES_STATUSES.VALIDATION === error.response.status;
  }

  static _isOauthValidationError(error) {
    return error.response.data.error_description;
  }

  static _isBusinessLogicError(error) {
    return ErrorHandlerService._ERROR_TYPES_STATUSES.BUSINESS_LOGIC === error.response.status;
  }

  static _getErrorType(error) {
    if (ErrorHandlerService._isTransportError(error)) {
      return 'transportError';
    }

    if (ErrorHandlerService._isValidationError(error)) {
      return ErrorHandlerService._isOauthValidationError(error) ? 'oauthValidationError' : 'validationError';
    }

    if (ErrorHandlerService._isBusinessLogicError(error)) {
      return 'businessLogicError';
    }

    return 'transportError';
  }
}
