import { setFormRules, setOnInputTransducers } from '@/services';

export default [
  {
    componentName: 'v-text-field',
    modelName: 'id',
    label: 'User UID',
    isDisabled: false,
    onInput: setOnInputTransducers(['trim']),
    value: '',
    rules: setFormRules([
      'isRequired',
      'isUUID4',
    ]),
  },
  {
    componentName: 'v-text-field',
    modelName: 'name',
    label: 'Name',
    isDisabled: false,
    onInput: setOnInputTransducers(['trim']),
    value: '',
    rules: setFormRules([
      'isRequired',
    ]),
  },
  {
    componentName: 'v-text-field',
    modelName: 'email',
    label: 'Email',
    isDisabled: false,
    onInput: setOnInputTransducers(['trim']),
    value: '',
    rules: setFormRules([
      'isRequired',
      'isEmail',
    ]),
  },
  {
    componentName: 'v-text-field',
    modelName: 'title',
    label: 'Title',
    isDisabled: false,
    onInput: setOnInputTransducers(['trim']),
    value: '',
    rules: setFormRules([
      'isRequired',
      'noWhitespaceCharacters',
    ]),
  },
];
