<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Deposit
    form-new-deposit-container
</template>

<script>
import { FormNewDepositContainer } from '@/components/containers/agent/form-new-deposit';

export default {
  name: 'AgentDepositsNew',
  components: {
    FormNewDepositContainer,
  },
};
</script>
