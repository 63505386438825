import { truncate, formatDate } from '@/utils';

/**
 * Class representing the withdrawal operation performed by a client of an agent
 */
export default class ClientWithdrawal {
  id;
  agentUid;
  clientUid;
  clientUuid;
  operationUuid;
  accountDetails;
  createdAt;
  operationUid;

  constructor(opts) {
    const { currency, amount, ...rest } = opts;

    Object.assign(this, {
      ...rest,
      amount: `${amount} ${currency}`,
      createdAt: formatDate(rest.createdAt),
    });
  }

  get truncatedAccountDetails() {
    const maxLength = 30;
    return truncate(this.accountDetails, maxLength);
  }
}
