<template>
  <plain-form
    :submit-button-text="submitBtnText"
    :server-errors="serverErrors"
    :form="form"
    :disabled="isContentLoading"
    :init-values="initValues"
    @submit="$emit('submit', $event)"
  />
</template>

<script>
import VueTypes from 'vue-types';
import { PlainForm } from '@/components/common/plain-form';
import formData from './form-data';

export default {
  name: 'form-withdrawal',
  components: {
    PlainForm,
  },
  props: {
    serverErrors: VueTypes.arrayOf(VueTypes.object).def(() => [{}]),
    initValues: VueTypes.arrayOf(VueTypes.object).def(() => [{}]),
    submitBtnText: VueTypes.string,
    isContentLoading: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    form() {
      return formData;
    },
  },
};
</script>
