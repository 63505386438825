import { formatDate } from '@/utils';

/**
 * Class representing a prepaid card issued by an agent
 */
export default class PrepaidCard {
  static STATUS_NAMES = {
    NEW: 'new',
    PROCESSING: 'processing',
    ACTIVATED: 'activated',
    LIQUIDATED: 'liquidated',
    CANCELED: 'canceled',
  };

  id;
  agentUid;
  operationUid;
  clientUid;
  title;
  amount;
  code;
  statusName;
  statusUpdatedAt;
  statusColor;
  createdAt;

  constructor(responce, THEME) {
    const { currency, amount, ...opts } = responce;

    Object.assign(this, {
      ...opts,
      amount: `${amount} ${currency}`,
      operationUid: opts.operationUid || '',
      clientUid: opts.clientUid || '',
      statusUpdatedAt: formatDate(opts.statusUpdatedAt),
      statusColor: PrepaidCard._getStatusColor(THEME, opts.statusName),
      createdAt: formatDate(opts.createdAt),
    });
  }

  static _getStatusColor(THEME, statusName) {
    return new Map([
      [PrepaidCard.STATUS_NAMES.NEW, THEME.PRIMARY],
      [PrepaidCard.STATUS_NAMES.PROCESSING, THEME.SECONDARY],
      [PrepaidCard.STATUS_NAMES.ACTIVATED, THEME.ACCENT],
      [PrepaidCard.STATUS_NAMES.LIQUIDATED, THEME.ACCENT],
      [PrepaidCard.STATUS_NAMES.CANCELED, THEME.ACCENT],
    ]).get(statusName) || '#000';
  }

  get isActivated() {
    return this.statusName === PrepaidCard.STATUS_NAMES.ACTIVATED;
  }
}
