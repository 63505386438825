<template>
  <v-flex xs12 md10 offset-md1>
    <h1>Deposit</h1>
    <template v-if="!isContentLoading">
      <agent-operation-card :item="item" />

      <h2 class="mt-5">Payment Agent</h2>
      <v-layout>
        <agent-info-card :item="agent"/>
      </v-layout>

      <btn-commands
        v-if="isCommandsAvailable && isUserHasFinancierRole"
        class="mt-5"
        commands-for="deposit"
        :is-processing="isDepositProcessing"
        :edit-route-name="$options.editDepositRouteName"
        :item-id="$route.params.uid"
      />
    </template>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  ACTION_FETCH_CONTENT,
  ACTION_FETCH_AGENT_DEPOSIT,
  ACTION_FETCH_AGENT,
} from '@/store/modules/content/action-types';
import { FINANCIER_ROUTES } from '@/router/routes-const';

import { AgentInfoCard } from '@/components/cards/agent-info-card';
import { AgentOperationCard } from '@/components/cards/agent-operation-card';
import { BtnCommands } from '@/components/financier/btn-commands';

export default {
  name: 'FinancierAgentsDepositsShow',
  components: {
    AgentInfoCard,
    AgentOperationCard,
    BtnCommands,
  },
  editDepositRouteName: FINANCIER_ROUTES.DEPOSIT_EDIT.NAME,
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT_DEPOSIT,
      uid: this.$route.params.uid,
    });
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT,
      uid: this.$route.params.agent_uid,
    });
  },
  computed: {
    ...mapGetters('content', ['isContentLoading', 'getStateDataItem']),
    ...mapGetters('auth', ['isUserHasFinancierRole']),

    agent() {
      return this.getStateDataItem({ type: 'agents', id: this.$route.params.agent_uid });
    },
    item() {
      return this.getStateDataItem({ type: 'agent-deposits', id: this.$route.params.uid });
    },
    isDepositRequested() {
      return this.item?.attributes?.state?.name === 'requested';
    },
    isDepositProcessing() {
      return this.item?.attributes?.state?.name === 'processing';
    },
    isCommandsAvailable() {
      return this.isDepositRequested || this.isDepositProcessing;
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
  },
};
</script>
