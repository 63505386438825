<template>
  <v-flex xs12, md10, offset-md1>
    <h1>Withdrawal</h1>
    <template v-if="!isContentLoading">
      <agent-operation-card :item="withdrawal" />
      <h2>Payment agent</h2>
      <v-layout>
        <agent-info-card :item="agent" />
      </v-layout>
      <btn-commands
        v-if="isCommandsAvailable && isUserHasFinancierRole"
        commands-for="withdrawal"
        :is-processing="isWithdrawalProcessing"
        :edit-route-name="$options.editFinancierRouteName"
        :item-id="$route.params.uid"
      />
    </template>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  ACTION_FETCH_CONTENT,
  ACTION_FETCH_AGENT_WITHDRAWAL,
  ACTION_FETCH_AGENT,
} from '@/store/modules/content/action-types';
import { FINANCIER_ROUTES } from '@/router/routes-const';

import { AgentInfoCard } from '@/components/cards/agent-info-card';
import { AgentOperationCard } from '@/components/cards/agent-operation-card';
import { BtnCommands } from '@/components/financier/btn-commands';

export default {
  name: 'FinancierAgentsWithdrawalsShow',
  components: {
    AgentInfoCard,
    AgentOperationCard,
    BtnCommands,
  },
  editFinancierRouteName: FINANCIER_ROUTES.WITHDRAWAL_EDIT.NAME,
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT_WITHDRAWAL,
      uid: this.$route.params.uid,
    });
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT,
      uid: this.$route.params.agent_uid,
    });
  },
  computed: {
    ...mapGetters('content', ['isContentLoading', 'getStateDataItem']),
    ...mapGetters('auth', ['isUserHasFinancierRole']),

    agent() {
      return this.getStateDataItem({ type: 'agents', id: this.$route.params.agent_uid });
    },
    withdrawal() {
      return this.getStateDataItem({ type: 'agent-withdrawals', id: this.$route.params.uid });
    },
    isWithdrawalRequested() {
      return this.withdrawal?.attributes?.state?.name === 'requested';
    },
    isWithdrawalProcessing() {
      return this.withdrawal?.attributes?.state?.name === 'processing';
    },
    isCommandsAvailable() {
      return this.isWithdrawalRequested || this.isWithdrawalProcessing;
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
  },
};
</script>
