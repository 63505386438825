<template>
  <tr>
    <td>{{ paymentAgent.title }}</td>
    <td>{{ paymentAgent.email }}</td>
    <td>{{ paymentAgent.name }}</td>
    <td>{{ paymentAgent.balance }}</td>
    <td>{{ paymentAgent.updatedAt }}</td>
    <td>{{ paymentAgent.createdAt }}</td>
    <td>
      <router-link :to="redirectAttrs">
        Show
      </router-link>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';
import { PaymentAgent } from '@/models';

export default {
  name: 'payment-agents-table-row',
  props: {
    paymentAgent: VueTypes.instanceOf(PaymentAgent).isRequired,
    routeNameToItem: VueTypes.string.isRequired,
  },
  computed: {
    redirectAttrs() {
      return {
        name: this.routeNameToItem,
        params: {
          uid: this.paymentAgent.id,
        },
      };
    },
  },
};
</script>
