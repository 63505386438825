<template>
  <form-withdrawal
    submit-btn-text="create"
    :server-errors="formErrors"
    :is-content-loading="isContentLoading"
    @submit="handleSubmit"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTION_CREATE_AGENT_WITHDRAWAL_FOR_AGENT } from '@/store/modules/content-interaction/action-types';
import { AGENT_ROUTES } from '@/router/routes-const';

import { FormWithdrawal } from '@/components/forms/withdrawal';

export default {
  name: 'form-new-withdrawal-container',
  components: {
    FormWithdrawal,
  },
  computed: {
    ...mapGetters('auth', ['authData']),
    ...mapGetters('content-interaction', ['formErrors']),
    ...mapGetters('content', ['isContentLoading']),
  },
  methods: {
    ...mapActions('content-interaction', [ACTION_CREATE_AGENT_WITHDRAWAL_FOR_AGENT]),

    onSuccess(withdrawal) {
      this.$router.push({
        name: AGENT_ROUTES.AGENT_WITHDRAWAL.NAME,
        params: { uid: withdrawal.id },
      });
    },
    async handleSubmit({ amount, currency, accountDetails }) {
      await this[ACTION_CREATE_AGENT_WITHDRAWAL_FOR_AGENT]({
        agentUid: this.authData.userUid,
        onSuccess: this.onSuccess,
        data: {
          type: 'agent-withdrawals',
          attributes: {
            account_details: accountDetails,
            charge: {
              amount,
              currency,
            },
          },
        },
      });
    },
  },
};
</script>
