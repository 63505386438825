<template>
  <div>
    <strong>
      <slot name="title" />
    </strong>
    <p>
      <slot name="description" />
    </p>
  </div>
</template>

<script>
export default {
  name: 'item-info-slot',
};
</script>
