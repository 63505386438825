<template>
  <v-container class="page-create-agent">
    <v-row justify="center">
      <v-col md="10">
        <h1>Agent</h1>
        <agent-titles-provider
          :on-init="false"
          v-slot="{ fetchTitles }"
        >
          <module-agent-creation @agent-created="handleAgentCreation($event, fetchTitles)" />
        </agent-titles-provider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FINANCIER_ROUTES } from '@/router/routes-const';

import { AgentTitlesProvider } from '@/components/providers/agent-titles';

export default {
  name: 'page-create-agent',
  components: {
    AgentTitlesProvider,
  },
  methods: {
    async redirectToAgentPage(agentUid) {
      await this.$router.push({
        name: FINANCIER_ROUTES.AGENT.NAME,
        params: {
          uid: agentUid,
        },
      });
    },
    async handleAgentCreation(agentUid, fetchTitles) {
      await Promise.all([
        fetchTitles(true),
        this.redirectToAgentPage(agentUid),
      ]);
    },
  },
};
</script>
