<template>
  <v-flex xs="12" md="10" offset-md="1">
    <h1 class="mb-4">Client Deposits</h1>
    <client-deposits-card
      :item="clientDeposits"
    />
  </v-flex>
</template>

<script>
import { ClientDepositsCard } from '@/components/cards/client-deposits-card';
import {
  ACTION_FETCH_CLIENT_DEPOSITS,
  ACTION_FETCH_CONTENT,
} from '@/store/modules/content/action-types';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'page-detail-client-deposit',
  components: {
    ClientDepositsCard,
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_CLIENT_DEPOSITS,
      uid: this.$route.params.uid,
    });
  },
  computed: {
    ...mapGetters('content', ['isContentLoading', 'getStateDataItem']),

    clientDeposits() {
      return this.getStateDataItem({ type: 'client-deposits', id: this.$route.params.uid });
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
  },
};
</script>
