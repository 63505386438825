import camelcase from 'lodash.camelcase';
import { getLastPathKey } from '@/utils';

const buildErrorValue = (value) => {
  if (typeof value === 'string') {
    return [value];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return null;
};

const buildErrorObj = (pointer, detail) => ({
  modelName: camelcase(getLastPathKey(pointer, '/')),
  value: buildErrorValue(detail),
});

const hasErrorModelsNullElement = (errors) => errors
  .filter(({ modelName }) => modelName)
  .every((model) => model === null);

export default function (error) {
  const formErrors = error?.response?.data?.errors || null;

  if (formErrors === null) {
    return null;
  }

  const errors = formErrors.reduce((acc, { detail, source: { pointer } }) => {
    acc.push(buildErrorObj(pointer, detail));
    return acc;
  }, []);

  return hasErrorModelsNullElement(errors) ? null : errors;
}
