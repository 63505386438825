<template>
  <form-create-agent
    :server-errors="serverValidationErrors"
    @submit="handleSubmit"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { STORE_MODULE_AGENT_CREATION } from '../../constants';
import { ACTION_CREATE_AGENT, GETTER_SERVER_VALIDATION_ERRORS } from '../../store/types';

import { FormCreateAgent } from '../../components/form-create-agent';

export default {
  name: 'container-form-create-agent',
  components: {
    FormCreateAgent,
  },
  inject: [
    '$agentCreated',
  ],
  computed: {
    ...mapGetters(STORE_MODULE_AGENT_CREATION, {
      serverValidationErrors: GETTER_SERVER_VALIDATION_ERRORS,
    }),
  },
  methods: {
    ...mapActions(STORE_MODULE_AGENT_CREATION, [
      ACTION_CREATE_AGENT,
    ]),

    async handleSubmit(formFields) {
      await this[ACTION_CREATE_AGENT](formFields);

      if (!this.serverValidationErrors?.length) {
        this.$agentCreated(formFields.id);
      }
    },
  },
};
</script>
