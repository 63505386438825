import axios from 'axios';
import { CONFIG } from '@/consts';

const createAxiosInstance = (store) => {
  const instance = axios.create({ baseURL: CONFIG.URL.API });
  instance.defaults.headers.common.Authorization = `Bearer ${store.getters['auth/accessToken']}`;

  instance.interceptors.request.use(
    (request) => request,
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => {
      // TODO: hack because of an old response mapping realization, need to make it right
      const { data } = response;
      return data.pagination ? data : data.data;
    },
    (error) => Promise.reject(error),
  );

  return instance;
};

export default createAxiosInstance;
