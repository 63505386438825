import { ApiService } from '@/lib/api-service';
import compose from 'lodash.flowright';
import adaptQueryParams from './adapt-query-params';

export default class TableDataApiService extends ApiService {
  mapperService;

  constructor(oauth, axios, mapperService) {
    super(oauth, axios);
    this.mapperService = mapperService;
  }

  getAgentWithdrawals(params) {
    return this._get('agent-withdrawals', params, this.mapperService.mapOperationsData);
  }

  getAgentDeposits(params) {
    return this._get('agent-deposits', params, this.mapperService.mapOperationsData);
  }

  getPrepaidCards(params) {
    return this._get('prepaid-cards', params, this.mapperService.mapPrepaidCards);
  }

  getClientWithdrawals(params) {
    return this._get('client-withdrawals', params, this.mapperService.mapClientWithdrawals);
  }

  getPaymentAgents(params) {
    return Promise.all([
      this._get('agents', params),
      this._get('agent-finances'),
    ]).then(([agents, agentsFinances]) => (
      this.mapperService.mapPaymentAgents({
        agents,
        agentsFinances,
      })
    ));
  }

  getAgentsList(params) {
    return this.get('agents', params, this.mapperService.mapAgentsList);
  }

  getAgentWithdrawalsOfAgent({ agentUid, ...restParams }) {
    const path = `agents/${agentUid}/agent-withdrawals`;
    return this._get(path, restParams, this.mapperService.mapOperationsData);
  }

  getAgentDepositsOfAgent({ agentUid, ...restParams }) {
    const path = `agents/${agentUid}/agent-deposits`;
    return this._get(path, restParams, this.mapperService.mapOperationsData);
  }

  getPrepaidCardsOfAgent({ agentUid, ...restParams }) {
    const path = `agents/${agentUid}/prepaid-cards`;
    return this._get(path, restParams, this.mapperService.mapPrepaidCards);
  }

  getClientWithdrawalsOfAgent({ agentUid, ...restParams }) {
    const path = `agents/${agentUid}/client-withdrawals`;
    return this._get(path, restParams, this.mapperService.mapClientWithdrawals);
  }

  getClientDeposits(params) {
    const path = 'client-deposits';
    return this._get(path, params, this.mapperService.mapClientDeposits);
  }

  getClientDepositsOfAgent({ agentUid, ...restParams }) {
    const path = `agents/${agentUid}/client-deposits`;
    return this._get(path, restParams, this.mapperService.mapClientDeposits);
  }

  static _parseRequestParams({ pageNumber = 1, pageSize = 5, filters = {}, sorting = {} } = {}) {
    return { pageNumber, pageSize, sorting, ...filters };
  }

  static _prepareQueryParams(params) {
    return compose(
      adaptQueryParams,
      TableDataApiService._parseRequestParams,
    )(params);
  }

  _get(endpoint, params, mapper) {
    return this.get(
      endpoint,
      TableDataApiService._prepareQueryParams(params),
      mapper,
    );
  }
}
