import get from 'lodash.get';
import { formatDate } from '@/utils';

/**
 * this mixin used only with
 * (agent-operation-card, agent-prepaid-card, client-withdrawal-card) components
 */

export default {
  computed: {
    mix_attributes() {
      return get(this.item, 'attributes', null);
    },
    mix_amount() {
      const amount = get(this.mix_attributes, ['charge', 'amount'], '');
      const currency = get(this.mix_attributes, ['charge', 'currency'], '');

      return amount && currency ? `${amount} ${currency}` : '';
    },
    mix_compensation() {
      const amount = get(this.mix_attributes, ['compensation', 'amount'], '');
      const currency = get(this.mix_attributes, ['compensation', 'currency'], '');

      return amount && currency ? `${amount} ${currency}` : '-';
    },
    mix_updatedAt() {
      return formatDate(
        get(this.mix_attributes, ['state', 'updated_at'], ''),
      );
    },
    mix_createdAt() {
      return formatDate(
        get(this.mix_attributes, 'created_at', ''),
      );
    },
    mix_updateAt() {
      return formatDate(
        get(this.mix_attributes, 'update_at', ''),
      );
    },
  },
};
