import { createModule } from '@/lib/create-module';

const moduleRoot = () => import(
  /* webpackChunkName: "agent-creation-module" */
  './components/module-root/module-root.vue'
);

export default {
  register: createModule(moduleRoot),
};
