import mapPagination from './pagination';

import mapPaymentAgents from './payment-agents';
import mapOperationsData from './operations';
import mapPrepaidCards from './prepaid-cards';
import mapClientWithdrawals from './client-withdrawals';
import mapClientDeposits from './client-deposits';

const withCommonPaginationMapper = (mapper) => (data) => mapper({
  ...data,
  pagination: mapPagination(data.pagination),
});
const withAgentsPaginationMapper = (mapper) => ({ agents: { pagination, ...restAgents }, ...restData }) => mapper({
  ...restData,
  agents: restAgents.data,
  pagination: mapPagination(pagination),
});

export default {
  mapPaymentAgents: withAgentsPaginationMapper(mapPaymentAgents),
  mapOperationsData: withCommonPaginationMapper(mapOperationsData),
  mapPrepaidCards: withCommonPaginationMapper(mapPrepaidCards),
  mapClientWithdrawals: withCommonPaginationMapper(mapClientWithdrawals),
  mapClientDeposits: withCommonPaginationMapper(mapClientDeposits),
};
