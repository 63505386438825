<template>
  <div class="app-clipboard-truncated d-flex align-center">
    <span class="app-clipboard-truncated__value">
      {{ value }}
    </span>
    <app-clipboard
      :value="value"
      :key="value"
      classes="app-clipboard-truncated__clipboard"
    />
  </div>
</template>

<script>
import { AppClipboard } from '@/components/common/app-clipboard';

export default {
  name: 'app-clipboard-truncated',
  components: { AppClipboard },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'app-clipboard-truncated.scss';
</style>
