<template>
  <c-form-create-client-deposit />
</template>

<script>
import { VuexHelper } from '@/lib/vuex-helper';
import { STORE_MODULE_CLIENT_DEPOSITS_CREATION } from '../../const';
import { ClientDepositCreationApiService } from '../../services/client-deposit-creation-api-service';
import { createClientDepositCreationStoreModule } from '../../store';
import { CFormCreateClientDeposit } from '../../containers/form-create-client-deposit';

export default {
  name: 'module-root',
  components: { CFormCreateClientDeposit },
  inject: [
    'oauth',
    'axios',
  ],
  created() {
    VuexHelper.registerStoreModule({
      store: this.$store,
      moduleName: STORE_MODULE_CLIENT_DEPOSITS_CREATION,
      module: createClientDepositCreationStoreModule({
        clientDepositCreationApiService: new ClientDepositCreationApiService(this.oauth, this.axios),
      }),
    });
  },
};
</script>
