<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Prepaid Card
    v-container(v-if="!isContentLoading")
      agent-prepaid-card(:item="prepaidCard")
      h2 Payment Agent
      v-layout
        agent-info-card(:item="agent")
      v-btn(
        v-if="isPrepaidCardNew && isUserHasFinancierRole"
        color="error"
        @click="cancel"
      ) Cancel
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  ACTION_FETCH_CONTENT,
  ACTION_FETCH_PREPAID_CARDS,
  ACTION_FETCH_AGENT,
} from '@/store/modules/content/action-types';
import {
  ACTION_CHANGE_ITEM_STATUS,
  ACTION_CANCEL_PREPAID_CARD,
} from '@/store/modules/content-interaction/action-types';
import { withConfirm } from '@/utils';

import { AgentInfoCard } from '@/components/cards/agent-info-card';
import AgentPrepaidCard from './agent-prepaid-card.vue';

export default {
  name: 'FinancierAgentsWithdrawalsShow',
  components: {
    AgentInfoCard,
    AgentPrepaidCard,
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_PREPAID_CARDS,
      uid: this.$route.params.uid,
    });
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT,
      uid: this.$route.params.agent_uid,
    });
  },
  computed: {
    ...mapGetters('content', ['getStateDataItem', 'isContentLoading']),
    ...mapGetters('auth', ['isUserHasFinancierRole']),

    agent() {
      return this.getStateDataItem({ type: 'agents', id: this.$route.params.agent_uid });
    },
    prepaidCard() {
      return this.getStateDataItem({ type: 'prepaid-cards', id: this.$route.params.uid });
    },
    isPrepaidCardNew() {
      return this.prepaidCard?.attributes?.state?.name === 'new';
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
    ...mapActions('content-interaction', [ACTION_CHANGE_ITEM_STATUS]),

    async cancel() {
      await withConfirm(this[ACTION_CHANGE_ITEM_STATUS])({
        actionType: ACTION_CANCEL_PREPAID_CARD,
        code: this.prepaidCard.attributes.code,
      });
    },
  },
};
</script>
