import Vue from 'vue';
import { VuexHelper } from '@/lib/vuex-helper';
import { mapToken } from '@/store/modules/auth/mappers';
import stateConst from '@/store/modules/auth/const';
import { setAxiosAuthHeader } from '@/utils';
import { ACTION_FETCH_AGENT } from '@/store/modules/content/action-types';
import { MUTATION_SET_AUTH_ERROR_CODE } from './mutation-types';
import api from './api';
import * as actionTypes from './action-types';
import * as mutationTypes from './mutation-types';

async function signIn({ commit, dispatch, getters }, { credentials }) {
  try {
    await api.signIn(credentials);

    const claims = mapToken(getters.accessToken);
    await dispatch(actionTypes.ACTION_SET_AUTH, { userUid: claims.sub, roles: claims.aud || [] });
    await dispatch(actionTypes.ACTION_GET_USER, { allRoles: claims.aud });
  } catch (e) {
    commit(MUTATION_SET_AUTH_ERROR_CODE, { code: e?.response?.status });
    VuexHelper.actionExceptionHandler(e, actionTypes.ACTION_SIGN_IN);
  }
}
async function getUser(context, { allRoles }) {
  try {
    const { ROLES } = stateConst;
    const roles = [];

    allRoles.forEach((role) => {
      if (role in ROLES) {
        roles.push(ROLES[role]);
      }
    });

    await context.dispatch(actionTypes.ACTION_SET_AUTH, { roles });

    // get agent-user data for profile page
    if (roles.includes('agent')) {
      await context.dispatch(
        `content/${ACTION_FETCH_AGENT}`,
        { uid: context.getters.authData.userUid },
        { root: true },
      );
    }
  } catch (e) {
    VuexHelper.actionExceptionHandler(e, actionTypes.ACTION_GET_USER);
  }
}
async function setAuth({ commit }, auth) {
  const storageAuth = localStorage.getItem('auth');
  const oldAuth = storageAuth ? JSON.parse(storageAuth) : {};
  const newAuth = { ...oldAuth, ...auth };

  localStorage.setItem('auth', JSON.stringify(newAuth));
  setAxiosAuthHeader({ instance: Vue.axios, token: newAuth.access_token });
  commit(mutationTypes.MUTATION_SET_AUTH, newAuth);
}
async function signOut({ dispatch, getters }, { onSignOut }) {
  try {
    await api.signOut({ refreshToken: getters.refreshToken });
  } catch (e) {
    VuexHelper.actionExceptionHandler(e, actionTypes.ACTION_SIGN_OUT);
  } finally {
    await dispatch(actionTypes.ACTION_REMOVE_AUTH);
    onSignOut();
  }
}
function removeAuth({ commit }) {
  localStorage.removeItem('auth');
  commit(mutationTypes.MUTATION_REMOVE_AUTH);
}

export default {
  [actionTypes.ACTION_SIGN_IN]: signIn,
  [actionTypes.ACTION_SIGN_OUT]: signOut,
  [actionTypes.ACTION_GET_USER]: getUser,
  [actionTypes.ACTION_SET_AUTH]: setAuth,
  [actionTypes.ACTION_REMOVE_AUTH]: removeAuth,
};
