export default {
  SIGN_IN: {
    NAME: 'sign_in',
    PATH: '/',
  },
  NOT_FOUND: {
    NAME: 'not_found',
    PATH: '/404',
  },
  GUIDELINE: {
    NAME: 'guideline',
    PATH: '/guideline',
  },
};
