<template>
  <v-flex xs="12" md="10" offset-md="1">
    <h1>Client Deposits</h1>
    <table-content-provider
      :agentUid="getUserUid"
      :table-type="$options.constants.tableType"
      v-slot="{ isInitLoadingComplete, pagination, items, handleChange }"
    >
      <div v-if="isInitLoadingComplete">
        <table-complex
          :filters="tableFilters"
          :filter-names="filterNames"
          :headers="tableHeaders"
          :items="items"
          :min-items-per-page="$options.constants.minItemsPerPage"
          :pagination="pagination"
          @pagination-change="handleChange"
        >
          <template v-slot="{ item }">
            <client-deposits-table-row
              :item="item"
              :route-name-to-item="$options.constants.clientDepositRoute"
            />
          </template>
        </table-complex>
        <!-- TODO: https://app.asana.com/0/1199997557733026/1203158481300629-->
        <!-- <app-btn
          color="primary"
          @click="redirectToCreatedClientsDeposit"
        /> -->
      </div>
    </table-content-provider>
  </v-flex>
</template>

<script>
import { FINANCIER_ROUTES } from '@/router/routes-const';
import { mapGetters } from 'vuex';

// import { AppBtn } from '@/components/common/app-btn';
import { MIN_ITEMS_PER_PAGE, TABLE_TYPES } from '@/consts';
import { TableContentProvider } from '@/components/providers/table-content';
import { TableComplex } from '@/components/common/table-complex';
import { TableFilter, TableHeader } from '@/models';
import { ClientDepositsTableRow } from '@/components/financier/client-deposits-table-row';

export default {
  name: 'page-client-deposit',
  components: {
    ClientDepositsTableRow,
    TableContentProvider,
    TableComplex,
    // AppBtn,
  },
  computed: {
    ...mapGetters('auth', ['getUserUid']),

    filterNames() {
      return [
        'agentUid',
        'walletId',
        'externalOperationUid',
        'recepientUserUid',
        'createdAt',
        'amount',
        'agentTitle',
        'uid',
      ];
    },
    tableFilters() {
      return [
        new TableFilter('ID', [], this.filterNames[7]),
        new TableFilter('Agent UID', [], this.filterNames[0]),
        new TableFilter('Wallet Id', [], this.filterNames[1]),
        new TableFilter('External Operation UID', [], this.filterNames[2]),
        new TableFilter('Recipient User UID', [], this.filterNames[3]),
        new TableFilter('Created at', [], this.filterNames[4]),
        new TableFilter('Amount', [], this.filterNames[5]),
        new TableFilter('Agent title', [], this.filterNames[6]),
      ];
    },
    tableHeaders() {
      return [
        new TableHeader('ID', 'uid'),
        new TableHeader('Agent UID', 'agentUid'),
        new TableHeader('Wallet Id', 'walletId'),
        new TableHeader('Amount', 'amount', true),
        new TableHeader('External Operation UID', 'externalOperationUid'),
        new TableHeader('Recipient User UID', 'recepientUserUid'),
        new TableHeader('Created at', 'createdAt'),
        new TableHeader('Agent title', 'agentTitle'),
        new TableHeader('', '', false),
      ];
    },
  },
  methods: {
    redirectToCreatedClientsDeposit() {
      this.$router.push({
        name: FINANCIER_ROUTES.NEW_CLIENT_DEPOSITS.NAME,
        params: { agent_uid: this.getUserUid },
      });
    },
  },
  constants: {
    tableType: TABLE_TYPES.AGENT_CLIENT_DEPOSITS,
    clientDepositRoute: FINANCIER_ROUTES.AGENT_CLIENT_DEPOSITS.NAME,
    minItemsPerPage: MIN_ITEMS_PER_PAGE,
  },
};
</script>
