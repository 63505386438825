<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Prepaid Card
    form-new-prepaid-card-container
</template>

<script>
import { FormNewPrepaidCardContainer } from '@/components/containers/agent/form-new-prepaid-card';

export default {
  name: 'AgentPrepaidCardsNew',
  components: {
    FormNewPrepaidCardContainer,
  },
};
</script>
