<template>
  <tr>
    <td>{{ item.humanId }}</td>
    <td>{{ item.amount }}</td>
    <td v-if="isCompensationVisible">
      {{ item.compensation }}
    </td>
    <td>{{ item.truncatedAccountDetails }}</td>
    <td>
      <v-chip :color="item.statusColor" dark>
        {{ item.statusName }}
      </v-chip>
    </td>
    <td>{{ item.statusUpdatedAt }}</td>
    <td>{{ item.createdAt }}</td>
    <td>
      <router-link :to="redirectAttrs">Show</router-link>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';
import { TABLE_TYPES } from '@/consts';

export default {
  name: 'operation-table-row',
  props: {
    item: VueTypes.object.isRequired,
    routeNameToItem: VueTypes.string.isRequired,
    tableType: {
      type: String,
      default: TABLE_TYPES.AGENT_WITHDRAWALS_OF_AGENT,
    },
  },
  computed: {
    redirectAttrs() {
      return {
        name: this.routeNameToItem,
        params: {
          uid: this.item.id,
          agent_uid: this.item.agentUid,
        },
      };
    },
    isCompensationVisible() {
      return this.tableType === TABLE_TYPES.AGENT_DEPOSITS_OF_AGENT;
    },
  },
};
</script>
