<template>
  <v-footer
    v-if="!isNotFoundPage"
    class="py-4"
    v-once
    height="auto"
    :dark="true"
  >
    <v-container>
      <v-layout :wrap="true">
        <v-spacer />
        <app-btn
          class="mr-0"
          color="primary"
          @click="$vuetify.goTo(0)"
        >
          <app-icon icon="chevron-up" />
        </app-btn>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
import { AppBtn } from '@/components/common/app-btn';
import { AppIcon } from '@/components/common/app-icon';
import { COMMON_ROUTES } from '@/router/routes-const';

export default {
  name: 'app-footer',
  components: {
    AppBtn,
    AppIcon,
  },
  computed: {
    isNotFoundPage() {
      return this.$route.name === COMMON_ROUTES.NOT_FOUND.NAME;
    },
  },
};
</script>
