import compose from 'lodash.flowright';

export default function (dateToCompare, now = new Date()) {
  const msInSec = 1000;
  return compose(
    Math.abs,
    Math.round,
    (diff) => diff / msInSec,
  )(dateToCompare.getTime() - now.getTime());
}
