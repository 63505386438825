<template>
  <div class="item-info">
    <strong>
      {{ title }}
      <small v-if="additional">
        {{ additional }}
      </small>
    </strong>
    <p>{{ description }}</p>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'item-info',
  props: {
    title: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).def(''),
    additional: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).def(''),
    description: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).def(''),
  },
};
</script>

<style lang="scss" scoped>
  @import 'item-info';
</style>
