import compose from 'lodash.flowright';
import humps from 'humps';

export class ApiService {
  _oauth;
  _axios;

  constructor(oauth, axios) {
    this._oauth = oauth;
    this._axios = axios;
  }

  get(endpoint, params, mapper = (resp) => resp) {
    const request = () => this._oauth.access(() => this._axios.get(endpoint, {
      params,
    }));

    return ApiService._makeRequest(request, mapper);
  }

  post(endpoint, data, mapper = (resp) => resp) {
    const request = () => this._oauth.access(() => this._axios.post(endpoint, {
      data,
    }));

    return ApiService._makeRequest(request, mapper);
  }

  static _makeRequest(request, mapper) {
    return request().then(ApiService._map(mapper));
  }

  static _map(mapper) {
    return (response) => compose(
      mapper,
      humps.camelizeKeys,
    )(response);
  }
}
