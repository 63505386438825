<template>
  <tr>
    <td>{{ item.amount }}</td>
    <td>
      <pre>{{ item.code }}</pre>
    </td>
    <td>
      <v-chip :color="item.statusColor" dark>
        {{ item.statusName }}
      </v-chip>
    </td>
    <td>{{ item.statusUpdatedAt }}</td>
    <td>{{ item.createdAt }}</td>
    <td>
      <router-link :to="redirectAttrs">
        Show
      </router-link>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'table-row',
  props: {
    item: VueTypes.object.isRequired,
    routeNameToItem: VueTypes.string.isRequired,
  },
  computed: {
    redirectAttrs() {
      return {
        name: this.routeNameToItem,
        params: {
          uid: this.item.id,
          agent_uid: this.item.agentUid,
        },
      };
    },
  },
};
</script>
