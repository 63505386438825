const handleDependencies = (deps) => deps || {};

export default function ({ moduleName, entities }) {
  const {
    state: [stateFactory, stateDeps],
    actions: [actionsFactory, actionsDeps],
    mutations: [mutationsFactory, mutationsDeps],
    getters: [gettersFactory, gettersDeps],
  } = entities;

  return {
    [moduleName]: {
      namespaced: true,
      state: () => stateFactory(handleDependencies(stateDeps)),
      actions: actionsFactory(handleDependencies(actionsDeps)),
      mutations: mutationsFactory(handleDependencies(mutationsDeps)),
      getters: gettersFactory(handleDependencies(gettersDeps)),
    },
  };
}
