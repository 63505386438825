import * as types from './mutation-types';

export default function () {
  return {
    [types.MUTATION_SET_CONTENT_LOADING_STATUS](state, { isLoading }) {
      state.isContentLoading = isLoading;
    },
    [types.MUTATION_SET_DATA_COLLECTION](state, { items }) {
      const copiedState = { ...state.data };
      const itemType = items[0]?.type;

      if (itemType) {
        const normalizedCollection = items.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});

        state.data = {
          ...copiedState,
          [itemType]: normalizedCollection,
        };
      }
    },
    [types.MUTATION_SET_DATA_ITEM](state, { item }) {
      const data = { ...state.data };

      if (!data[item.type]) {
        data[item.type] = {};
      }

      data[item.type][item.id] = item;
      state.data = data;
    },

    [types.MUTATION_SET_TABLE_ITEMS](state, { items, pagination, tableType }) {
      const previousState = { ...state.data };
      const pageNumber = pagination.currentPage;
      const indexedCollection = items.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      state.data = {
        ...previousState,
        [tableType]: {
          items: {
            [pageNumber]: indexedCollection,
          },
          pagination,
        },
      };
    },
    [types.MUTATION_ADD_TABLE_ITEMS](state, { items, pagination, tableType }) {
      const previousState = { ...state.data };
      const previousTableItems = state.data[tableType].items;
      const pageNumber = pagination.currentPage;
      const newIndexedItemCollection = items.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      state.data = {
        ...previousState,
        [tableType]: {
          items: {
            ...previousTableItems,
            [pageNumber]: newIndexedItemCollection,
          },
          pagination,
        },
      };
    },
    [types.MUTATION_REMOVE_TABLE_ITEMS](state, { tableType }) {
      const previousState = { ...state.data };
      state.data = {
        ...previousState,
        [tableType]: {
          items: {},
          pagination: {},
        },
      };
    },
  };
}
