<template>
  <container-guideline-expansion-panel />
</template>

<script>
import { ContainerGuidelineExpansionPanel } from '../containers/guideline-expansion-panel';

export default {
  name: 'acard-guideline-module',
  components: {
    ContainerGuidelineExpansionPanel,
  },
};
</script>
