<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Withdrawal
    form-edit-withdrawal-container
</template>

<script>
import { mapActions } from 'vuex';
import { ACTION_FETCH_CONTENT, ACTION_FETCH_AGENT_WITHDRAWAL } from '@/store/modules/content/action-types';

import { FormEditWithdrawalContainer } from '@/components/containers/financier/form-edit-withdrawal';

export default {
  name: 'FinancierAgentsWithdrawalsEdit',
  components: {
    FormEditWithdrawalContainer,
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({ actionType: ACTION_FETCH_AGENT_WITHDRAWAL, uid: this.$route.params.uid });
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
  },
};
</script>
