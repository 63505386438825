<template>
  <div class="client-withdrawal-card">
    <div
      class="client-withdrawal-card__info-wrapper"
      v-for="(val, key) in cardData"
      :key="key"
    >
      <item-info
        v-if="val"
        :title="key"
        :description="val"
      />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import get from 'lodash.get';
import { cardMixin } from '@/mixins';

import { ItemInfo } from '@/components/common/item-info';

export default {
  name: 'client-withdrawal-card',
  components: {
    ItemInfo,
  },
  mixins: [
    cardMixin,
  ],
  props: {
    item: VueTypes.object.def(() => ({})),
  },
  computed: {
    clientUid() {
      return get(this.mix_attributes, 'client_uid', '');
    },
    operationUid() {
      return get(this.mix_attributes, 'operation_uid', '');
    },
    accountDetails() {
      return get(this.mix_attributes, 'account_details', '');
    },
    rate() {
      return this.mix_attributes?.rate ?? '-';
    },
    cardData() {
      return {
        'Office User ID': this.clientUid,
        'Office Withdrawal ID': this.operationUid,
        'Amount': this.mix_amount,
        'Account details': this.accountDetails,
        'Created at': this.mix_createdAt,
        'Rate': this.rate,
      };
    },
  },
};
</script>
