export default function ({ data = [], pagination }) {
  return {
    items: data.map((operation) => {
      const { id, attributes: { state, charge, compensation, ...restAttributes } } = operation;

      return {
        id,
        agentUid: restAttributes.agentUid,
        humanId: restAttributes.humanId,
        title: restAttributes?.title || '',
        accountDetails: restAttributes.accountDetails || '-',
        amount: charge.amount,
        paymentSystem: restAttributes.paymentSystem,
        currency: charge.currency,
        compensation: compensation?.amount || '-',
        statusName: state.name,
        statusUpdatedAt: state.updatedAt,
        createdAt: restAttributes.createdAt,
      };
    }),
    pagination,
  };
}
