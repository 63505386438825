/**
 * Class representing pagination of a table content
 */
export default class Pagination {
  currentPage;
  totalPages;
  totalRecords;

  constructor(currentPage, totalPages, totalRecords) {
    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.totalRecords = totalRecords;
  }
}
