import state from './state';
import createActions from './actions';
import mutations from './mutations';
import getters from './getters';

export function createAgentCreationStoreModule({ agentCreationApiService }) {
  return {
    namespaced: true,
    state: () => state,
    actions: createActions({ agentCreationApiService }),
    mutations,
    getters,
  };
}
