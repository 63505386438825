import { setFormRules, setOnInputTransducers } from '@/services';

export default [
  {
    componentName: 'v-text-field',
    modelName: 'wallet_id',
    label: 'Wallet ID',
    isDisabled: false,
    onInput: setOnInputTransducers(['trim']),
    value: '',
    rules: setFormRules([
      'isNotUndefined',
      'isNumber',
    ]),
  },
  {
    componentName: 'v-text-field',
    modelName: 'phone',
    label: 'Phone',
    isDisabled: false,
    onInput: setOnInputTransducers(['trim']),
    value: '',
    rules: setFormRules([
      'isNotUndefined',
      'isNumber',
    ]),
  },
  {
    componentName: 'v-text-field',
    modelName: 'amount',
    label: 'Amount',
    isDisabled: false,
    onInput: setOnInputTransducers([
      'trim',
      'changeFirstDotSymbolToZero',
      'changeSecondCommaSymbolToDot',
    ]),
    value: '',
    rules: setFormRules([
      'isRequired',
      'isNumber',
      'notZero',
    ]),
  },
  {
    componentName: 'v-select',
    modelName: 'currency',
    label: 'Currency',
    isDisabled: true,
    selectItems: ['USD'],
    value: 'USD',
  },
];
