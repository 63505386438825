import { AGENT_ROUTES, FINANCIER_ROUTES, COMMON_ROUTES } from '@/router/routes-const';

export default function ({ userRole }) {
  const homePageNameByRole = {
    agent: AGENT_ROUTES.HOME.NAME,
    financier: FINANCIER_ROUTES.HOME.NAME,
    support: FINANCIER_ROUTES.HOME.NAME,
  };

  return userRole ? homePageNameByRole[userRole] : COMMON_ROUTES.SIGN_IN.NAME;
}
