// actions
export const ACTION_HANDLE_CONTENT_DATA = 'ACTION_HANDLE_CONTENT_DATA';
export const ACTION_FETCH_CONTENT = 'ACTION_FETCH_CONTENT';
export const ACTION_FETCH_AGENT = 'ACTION_FETCH_AGENT';
export const ACTION_CHANGE_LOADING_CONTENT_STATUS = 'ACTION_CHANGE_LOADING_CONTENT_STATUS';
export const ACTION_FETCH_PREPAID_CARDS_OF_AGENTS = 'ACTION_FETCH_PREPAID_CARDS_OF_AGENTS';
export const ACTION_FETCH_CLIENT_WITHDRAWAL = 'ACTION_ACTION_FETCH_CLIENT_WITHDRAWAL';
export const ACTION_FETCH_AGENT_WITHDRAWAL = 'ACTION_FETCH_AGENT_WITHDRAWAL';
export const ACTION_FETCH_CLIENT_DEPOSITS_OF_AGENT = 'ACTION_FETCH_CLIENT_DEPOSITS_OF_AGENT';
export const ACTION_FETCH_CLIENT_DEPOSITS = 'ACTION_FETCH_CLIENT_DEPOSITS';
export const ACTION_FETCH_AGENT_FINANCE = 'ACTION_FETCH_AGENT_FINANCE';
export const ACTION_FETCH_AGENT_DEPOSIT = 'ACTION_FETCH_AGENT_DEPOSIT';
export const ACTION_FETCH_PREPAID_CARDS = 'ACTION_FETCH_PREPAID_CARDS';
export const ACTION_FETCH_TABLE_CONTENT = 'ACTION_FETCH_TABLE_CONTENT';
export const ACTION_REMOVE_TABLE_CONTENT = 'ACTION_REMOVE_TABLE_CONTENT';

// mutations
export const MUTATION_SET_DATA_COLLECTION = 'MUTATION_SET_DATA_COLLECTION';
export const MUTATION_SET_DATA_ITEM = 'MUTATION_SET_DATA_ITEM';
export const MUTATION_SET_CONTENT_LOADING_STATUS = 'MUTATION_SET_CONTENT_LOADING_STATUS';

export const MUTATION_SET_TABLE_ITEMS = 'MUTATION_SET_TABLE_ITEMS';
export const MUTATION_ADD_TABLE_ITEMS = 'MUTATION_ADD_TABLE_ITEMS';
export const MUTATION_REMOVE_TABLE_ITEMS = 'MUTATION_REMOVE_TABLE_ITEMS';

// getters TODO: coming soon!
