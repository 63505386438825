<template>
  <div class="list-with-search table-complex">
    <table-search-filter
      v-if="hasFilters"
      :title="filterTitle"
      :filters="filters"
      @filter-change="filterChangeHandler"
    />
    <v-data-table
      :server-items-length="serverItemsLength"
      :headers="headers"
      :items="items"
      :multi-sort="true"
      :footer-props="footerProps"
      :page="pageNumber"
      @update:options="paginationHandler"
    >
      <template v-slot:item="{ item }">
        <slot :item="item" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { Pagination, TableFilter, TableHeader } from '@/models';

import { TableSearchFilter } from '@/components/filters/table-search-filter';
import createSorting from './utils/create-sorting';

export default {
  name: 'table-complex',
  components: {
    TableSearchFilter,
  },
  props: {
    filters: VueTypes.oneOfType([
      VueTypes.array,
      VueTypes.arrayOf(VueTypes.instanceOf(TableFilter)),
    ]).def([]),
    headers: VueTypes.oneOfType([
      VueTypes.array,
      VueTypes.arrayOf(VueTypes.instanceOf(TableHeader)),
    ]).def([]),
    pagination: VueTypes.oneOfType([
      VueTypes.instanceOf(Pagination),
      VueTypes.object,
    ]).def({}),
    filterTitle: VueTypes.string.def('Search'),
    items: VueTypes.array.def([]),
    filterNames: VueTypes.arrayOf(VueTypes.string).isRequired,
    minItemsPerPage: VueTypes.number.def(5),
  },
  created() {
    this.createFilterModel();
  },
  mounted() {
    this.isInit = true;
  },
  data() {
    return {
      filterValues: null,
      fieldsSorting: [],
      fieldSortingDirections: [],
      itemsPerPage: this.minItemsPerPage,
      pageNumber: 1,
      isInit: false,
    };
  },
  computed: {
    hasFilters() {
      return this.filters.length;
    },
    serverItemsLength() {
      return this.pagination?.totalRecords || 0;
    },
    footerProps() {
      return {
        itemsPerPageOptions: [15, 30, 45],
      };
    },
  },
  methods: {
    createFilterModel() {
      this.filterValues = this.filterNames.reduce((acc, fieldName) => ({ ...acc, [fieldName]: '' }), {});
    },
    getEmitPayload({ pageNumber, pageSize } = {}) {
      return {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || this.itemsPerPage,
        filters: this.filterValues,
        sorting: createSorting(this.fieldsSorting, this.fieldSortingDirections),
      };
    },
    paginationHandler(args = {}, { isFilterChanged = false } = {}) {
      const pageNumber = args?.page;
      const pageSize = args?.itemsPerPage;
      const isPageUpdated = pageNumber !== this.pageNumber;
      const isPageSizeUpdated = pageSize !== this.pageSize;
      const isItCanEmit = isFilterChanged || (isPageUpdated && isPageSizeUpdated) || (args.sortBy || args.sortDesc);

      if (isPageUpdated) {
        this.pageNumber = pageNumber;
      }

      if (isPageSizeUpdated) {
        this.itemsPerPage = pageSize;
      }

      if (args.sortBy) {
        this.fieldsSorting = args.sortBy;
      }

      if (args.sortDesc) {
        this.fieldSortingDirections = args.sortDesc;
      }

      if (this.isInit && isItCanEmit) {
        this.$emit('pagination-change', this.getEmitPayload({ pageNumber, pageSize }));
      }
    },

    filterChangeHandler({ fieldValue, callMethod: filterName }) {
      const value = String(this.filterValues?.[filterName] ?? '');

      if (value || value === '') {
        this.filterValues[filterName] = fieldValue || '';

        if (this.filterValues[filterName] && filterName === 'amount') {
          this.filterValues[filterName] = this.fixAmountValue(this.filterValues[filterName]);
        }
      }

      this.paginationHandler({
        page: 1,
        itemsPerPage: this.itemsPerPage,
      }, { isFilterChanged: true });
    },
    fixAmountValue(amountValue = '') {
      let value = amountValue.trim().replaceAll(',', '.');

      if (value.endsWith('.')) {
        value = value.replaceAll('.', '');
      }

      const asNumber = Number(value);

      if (Number.isNaN(asNumber)) {
        return value;
      }

      if (Number.isInteger(asNumber)) {
        return asNumber * 100;
      }

      if (asNumber < 1) {
        const number = ((asNumber + 0.000_000_000_1) * 100);
        const result = Math.trunc(number);
        return result;
      }

      if (asNumber > 1 && !Number.isInteger(asNumber)) {
        return Number.parseInt(asNumber * 100, 10);
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'table-complex';
</style>
