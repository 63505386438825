<template>
  <div class="agent-operation-card mt-2 pa-7 rounded-xl">
    <div
      class="agent-operation-card__info-wrapper"
      v-for="(val, key) in cardData"
      :key="key"
    >
      <item-info
        v-if="typeof val === 'object'"
        :title="key"
        :additional="val.additional"
        :description="val.description"
      />
      <item-info
        v-else
        :title="key"
        :description="val"
      />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import get from 'lodash.get';
import { cardMixin } from '@/mixins';

import { ItemInfo } from '@/components/common/item-info';

export default {
  name: 'agent-operation-card',
  components: {
    ItemInfo,
  },
  mixins: [
    cardMixin,
  ],
  props: {
    item: VueTypes.object.def(() => ({})),
  },
  computed: {
    uniqueId() {
      return get(this.item, 'id', '');
    },
    humanId() {
      return get(this.mix_attributes, 'human_id', '');
    },
    accountDetails() {
      return get(this.mix_attributes, 'account_details', '-');
    },
    status() {
      return get(this.mix_attributes, ['state', 'name'], '');
    },
    payment_system() {
      return get(this.mix_attributes, 'payment_system', '-');
    },
    cardData() {
      return {
        'UID': { additional: '(unique id)', description: this.uniqueId },
        'HumanID': { additional: '(not unique id)', description: this.humanId },
        'Amount': this.mix_amount,
        'Compensation': this.mix_compensation,
        'Payment system': this.payment_system,
        'Account details': this.accountDetails,
        'Status': this.status,
        'Status updated at': this.mix_updatedAt,
        'Created at': this.mix_createdAt,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'agent-operation-card';
</style>
