<template>
  <v-main id="home">
    <v-progress-linear
      v-if="!isContentLoaded"
      :fixed="true"
      color="primary"
      indeterminate
    />
    <layout-main v-if="hasCommonLayout" />
    <layout-additional v-else />
  </v-main>
</template>

<script>
import VueTypes from 'vue-types';
import { mapGetters } from 'vuex';

import LayoutMain from './layout-main.vue';

export default {
  name: 'app-view',
  components: {
    LayoutMain,
    LayoutAdditional: () => import('./layout-additional.vue'),
  },
  props: {
    isContentLoaded: VueTypes.bool.isRequired,
    hasCommonLayout: VueTypes.bool.isRequired,
  },
  computed: {
    ...mapGetters('auth', ['authData']),
    isUserHasAgentRole() {
      const roles = this.authData?.roles;
      return !!roles?.includes('agent');
    },
  },
};
</script>

<style>
  .v-progress-linear {
    position: absolute;
    margin: 0;
  }
</style>
