<template>
  <form-create-client-deposit
    :server-errors="serverValidationErrors"
    @submit="handleSubmit"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AGENT_ROUTES } from '@/router/routes-const';
import { STORE_MODULE_CLIENT_DEPOSITS_CREATION } from '../../const';
import { ACTION_CREATE_CLIENT_DEPOSITS, GETTER_SERVER_VALIDATION_ERRORS } from '../../store/types';

import { FormCreateClientDeposit } from '../../components/form-create-client-deposit';

export default {
  name: 'c-form-create-client-deposit',
  components: {
    FormCreateClientDeposit,
  },
  inject: [
    'oauth',
  ],
  computed: {
    ...mapGetters(STORE_MODULE_CLIENT_DEPOSITS_CREATION, {
      serverValidationErrors: GETTER_SERVER_VALIDATION_ERRORS,
    }),
  },
  methods: {
    ...mapActions(STORE_MODULE_CLIENT_DEPOSITS_CREATION, [
      ACTION_CREATE_CLIENT_DEPOSITS,
    ]),
    redirectClientDeposits() {
      this.$router.push({ name: AGENT_ROUTES.CLIENT_DEPOSITS.NAME });
    },

    async handleSubmit(formFields) {
      await this[ACTION_CREATE_CLIENT_DEPOSITS]({
        ...formFields,
        agentUid: this.oauth ? this.oauth.getUserUid() : '',
        onSuccess: this.redirectClientDeposits,
      });
    },
  },
};
</script>
