import { MUTATIONS_SET_AGENT_TITLES } from './types';

export default {
  [MUTATIONS_SET_AGENT_TITLES](state, { agentTitles, recordDate = new Date() }) {
    state.agentTitles = {
      titles: agentTitles,
      recordDate,
    };
  },
};
