import Vue from 'vue';
import VueAxios from 'vue-axios';
import vueDebounce from 'vue-debounce';
import Clipboard from 'v-clipboard';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import App from '@/app.vue';
import { CONFIG } from '@/consts';
import { ACTION_SET_AUTH, ACTION_SIGN_OUT } from '@/store/modules/auth/action-types';
import { COMMON_ROUTES } from '@/router/routes-const';
import { createStore } from '@/store';
import { createRouter } from '@/router';
import { OauthClient } from '@/lib/oauth/client';
import { createAxios, setVueFilters } from '@/utils';
import { registerStoreSubscribers } from '@/subscribes/register-store-subscribers';
import { createStoreSharedCacheModule } from '@/store/modules/shared-cache';
import moduleAgentCreation from '@/modules/financier/agent-creation';
import moduleClientDepositCreation from '@/modules/agent/client-deposit-creation';
import moduleAcardGuideLine from '@/modules/common/acard-guideline';
import vueFilters from './vue-filters';
import vuetify from './plugins/vuetify';

const store = createStore();
const router = createRouter({ store });

Vue.config.productionTip = false;
Vue.use(VueAxios, createAxios(store));
Vue.use(vueDebounce, { defaultTime: '500ms' });
Vue.use(Toast);
Vue.use(Clipboard);

setVueFilters(vueFilters, Vue);
Vue.$oauth = new OauthClient({
  tokenUrl: `${CONFIG.URL.AUTH}/token`,
  getAccessToken: () => store.getters['auth/accessToken'],
  getRefreshToken: () => store.getters['auth/refreshToken'],
  getExpiresAt: () => store.getters['auth/expiresAt'],
  getUserUid: () => store.getters['auth/getUserUid'],
  setAuth: async (data) => {
    await store.dispatch(`auth/${ACTION_SET_AUTH}`, data, { root: true });
  },
  onSignInRequired: async () => {
    await store.dispatch(
      `auth/${ACTION_SIGN_OUT}`,
      { onSignOut: () => router.push({ name: COMMON_ROUTES.SIGN_IN.NAME }) },
      { root: true },
    );
  },
});

const app = new Vue({
  vuetify,
  router,
  store,
  data() {
    return {
      info: null,
    };
  },
  render: (h) => h(App),
});

const deps = {
  oauth: Vue.$oauth,
  axios: Vue.axios,
};

// store-modules initializations
store.registerModule('shared-cache', createStoreSharedCacheModule(deps));

// feature-modules initializations
moduleAgentCreation.register('module-agent-creation', Vue, deps);
moduleClientDepositCreation.register('module-client-deposit-creation', Vue, deps);
moduleAcardGuideLine.register('module-acard-guideline', Vue, deps);

// register subscriptions for store mediator
registerStoreSubscribers(app);

app.$mount('#app');
