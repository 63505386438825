const COMMAND_NAMES = {
  START: 'start',
  ACCEPT: 'accept',
  REJECT: 'reject',
  EDIT: 'edit',
  CANCEL: 'cancel',
};

const createButton = (color, commandName, text, outlined = false) => ({
  id: commandName,
  color,
  classes: ['mr-2'],
  commandName,
  text,
  outlined,
});

export default function (isProcessing) {
  const commonButtons = {
    EDIT: createButton('info', COMMAND_NAMES.EDIT, 'edit', true),
  };

  const processingButtons = [
    createButton('primary', COMMAND_NAMES.ACCEPT, 'accept'),
    createButton('error', COMMAND_NAMES.REJECT, 'reject'),
    commonButtons.EDIT,
  ];
  const otherButtons = [
    createButton('primary', COMMAND_NAMES.START, 'start processing'),
    commonButtons.EDIT,
    createButton('error', COMMAND_NAMES.CANCEL, 'cancel'),
  ];

  return new Map([
    [false, otherButtons],
    [true, processingButtons],
  ]).get(isProcessing) || [];
}
