<template>
  <v-btn
    :icon="true"
    @click="$emit('sign-out')"
  >
    <img
      :src="require('@/assets/exit.png')"
      alt="logout button"
      width="30"
      height="30"
    >
  </v-btn>
</template>

<script>
export default {
  name: 'toolbar-sign-out-btn',
};
</script>
