import compose from 'lodash.flowright';

export default class ErrorParser {
  static parseTransportError(error, transportErrorsMap) {
    let msg;

    if (error?.response || error?.refresh) {
      const response = error.response || error.refresh.refresh?.response;
      const { status } = response;
      msg = transportErrorsMap.get(status) || transportErrorsMap.get('default');
    } else {
      msg = transportErrorsMap.get(undefined);
    }

    return msg || 'Something went wrong... Please try again later';
  }

  static parseValidationError(error) {
    const tree = error.response.data.errors_tree.data;
    const buildMessage = (entries) => entries.reduce((errorMsg, [errorFieldName, errors]) => {
      const newLine = `${errorMsg.length ? '\n' : ''}`;
      const separator = errors.length ? ', ' : '';

      return `${errorMsg}${newLine}${errorFieldName}: ${errors.join(separator)}`;
    }, '');

    const createMessage = compose(
      buildMessage,
      Object.entries,
      ErrorParser._flattenValidationErrorTree,
    );
    return createMessage(tree);
  }

  static parseFormValidationError(error) {
    const tree = error?.response.data.errors_tree?.data;

    const createError = (modelName, errors) => ({ modelName, value: errors });
    const buildErrorsCollection = (entries) => (
      entries.reduce((serverErrors, [modelName, errors]) => (
        [...serverErrors, createError(modelName, errors)]
      ), [])
    );
    const createFormErrors = compose(
      buildErrorsCollection,
      Object.entries,
      ErrorParser._flattenValidationErrorTree,
    );

    return createFormErrors(tree);
  }

  static parseOauthValidationError(error) {
    const { data } = error.response;
    const description = typeof data.error_description === 'object'
      ? Object.values(data.error_description).join(' ')
      : data.error_description;

    return `${data.error}${description ? `: ${description}` : ''}`;
  }

  static parseBusinessLogicError(error) {
    return error.response.data.errors_tree || error.response.data.errors;
  }

  /**
   * Transforms validation error tree
   * from
   * { charge: { amount: ['too small', 'another error'] }, count: ['must be greater than 0'] }
   * to ->
   { amount: ['too small', 'another error'], count: ['must be greater than 0'] }
   * then ->
   'amount: to small, another error'
   'count: must be greater than 0'
   */
  static _flattenValidationErrorTree(errorsTree) {
    const result = {};

    const traverse = (t) => {
      const treeKeys = Object.keys(t);
      treeKeys.forEach((treeKey) => {
        const treeElement = t[treeKey];

        if (Array.isArray(treeElement)) {
          result[treeKey] = treeElement;
        } else {
          traverse(treeElement);
        }
      });
    };

    traverse(errorsTree);
    return result;
  }
}
