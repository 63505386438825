<template>
  <v-flex xs="12" md="10" offset-md="1">
    <h1>Client Deposits</h1>
    <table-content-provider
      :agentUid="getUserUid"
      :table-type="$options.constants.tableType"
      v-slot="{ isInitLoadingComplete, pagination, items, handleChange }"
    >
      <div v-if="isInitLoadingComplete">
        <table-complex
          :filters="tableFilters"
          :filter-names="filterNames"
          :headers="tableHeaders"
          :items="items"
          :min-items-per-page="$options.constants.minItemsPerPage"
          :pagination="pagination"
          @pagination-change="handleChange"
        >
          <template v-slot="{ item }">
            <client-deposits-table-row
              :item="item"
              :is-user-financier="isUserHasFinancierRole"
              :route-name-to-item="$options.constants.clientDepositRoute"
            />
          </template>
        </table-complex>
        <app-btn
          color="primary"
          @click="redirectToCreatedClientsDeposit"
        >
          New
        </app-btn>
      </div>
    </table-content-provider>
  </v-flex>
</template>

<script>
import { AGENT_ROUTES } from '@/router/routes-const';
import { mapGetters } from 'vuex';

import { AppBtn } from '@/components/common/app-btn';
import { MIN_ITEMS_PER_PAGE, TABLE_TYPES } from '@/consts';
import { TableContentProvider } from '@/components/providers/table-content';
import { TableComplex } from '@/components/common/table-complex';
import { TableFilter, TableHeader } from '@/models';
import { ClientDepositsTableRow } from '@/components/agent/client-deposits-table-row';

export default {
  name: 'page-client-deposit',
  components: {
    ClientDepositsTableRow,
    TableContentProvider,
    TableComplex,
    AppBtn,
  },
  computed: {
    ...mapGetters('auth', ['getUserUid', 'isUserHasFinancierRole']),

    filterNames() {
      return ['walletId', 'externalOperationUid', 'recepientUserUid', 'createdAt', 'amount'];
    },
    tableFilters() {
      return [
        new TableFilter('Wallet Id', [], this.filterNames[0]),
        new TableFilter('External Operation UID', [], this.filterNames[1]),
        new TableFilter('Recipient User UID', [], this.filterNames[2]),
        new TableFilter('Created at', [], this.filterNames[3]),
        new TableFilter('Amount', [], this.filterNames[4]),
      ];
    },
    tableHeaders() {
      return [
        new TableHeader('Wallet Id', 'walletId'),
        new TableHeader('Amount', 'amount', true),
        new TableHeader('External Operation UID', 'externalOperationUid'),
        new TableHeader('Recipient User UID', 'recepientUserUid'),
        new TableHeader('Created at', 'createdAt'),
        new TableHeader('Update at', 'updatedAt', false),
        new TableHeader('', '', false),
      ];
    },
  },
  methods: {
    redirectToCreatedClientsDeposit() {
      this.$router.push({
        name: AGENT_ROUTES.NEW_CLIENT_DEPOSITS.NAME,
      });
    },
  },
  constants: {
    tableType: TABLE_TYPES.AGENT_CLIENT_DEPOSITS_OF_AGENT,
    clientDepositRoute: AGENT_ROUTES.AGENT_CLIENT_DEPOSITS.NAME,
    minItemsPerPage: MIN_ITEMS_PER_PAGE,
  },
};
</script>
