<template>
  <form-authorization
    :error-code="authErrorCode"
    @submit="handleSubmit"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTION_SIGN_IN } from '@/store/modules/auth/action-types';
import { getHomePageName } from '@/router/helpers';

import { FormAuthorization } from '@/components/forms/auth';

export default {
  name: 'container-form-authorization',
  components: {
    FormAuthorization,
  },
  computed: {
    ...mapGetters('auth', ['authData', 'authErrorCode']),
  },
  methods: {
    ...mapActions('auth', [ACTION_SIGN_IN]),

    redirectToHomePage() {
      this.$router.push({
        name: getHomePageName({
          userRole: this.authData.roles[0],
        }),
      });
    },
    async handleSubmit(formData) {
      await this[ACTION_SIGN_IN](formData);
      await this.redirectToHomePage();
    },
  },
};
</script>
