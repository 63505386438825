<template>
  <tr>
    <td>
      <app-clipboard-truncated :value="item.id" />
    </td>
    <td>
      <app-clipboard
        v-if="item.agentUid"
        :value="item.agentUid"
      >
        <a :href="detailLinks.linkToAgent" target="_blank">{{ item.agentUid }}</a>
      </app-clipboard>
    </td>
    <td>
      <app-clipboard
        v-if="item.walletId"
        :value="item.walletId"
      >
        {{ item.walletId }}
      </app-clipboard>
    </td>
    <td>{{ item.amount }}</td>
    <td>
      <app-clipboard
        v-if="item.externalOperationUid"
        :value="item.externalOperationUid"
      >
        <a :href="detailLinks.linkToEditOperation" target="_blank">{{ item.externalOperationUid }}</a>
      </app-clipboard>
    </td>
    <td>
      <app-clipboard
        v-if="item.recepientUserUid"
        :value="item.recepientUserUid"
      >
        <a :href="detailLinks.linkToUser" target="_blank">{{ item.recepientUserUid }}</a>
      </app-clipboard>
    </td>
    <td>{{ item.createdAt }}</td>
    <td>{{ item.agentTitle }}</td>
    <td>
      <router-link :to="redirectAttrs">Show</router-link>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';
import { AppClipboard } from '@/components/common/app-clipboard';
import { createDetailLinks } from '@/utils';
import { AppClipboardTruncated } from '@/components/common/app-clipboard-truncated';

export default {
  name: 'client-deposits-table-row',
  components: {
    AppClipboard,
    AppClipboardTruncated,
  },
  props: {
    item: VueTypes.object.isRequired,
    routeNameToItem: VueTypes.string.isRequired,
  },
  computed: {
    redirectAttrs() {
      return {
        name: this.routeNameToItem,
        params: {
          uid: this.item.id,
          agent_uid: this.item.agentUid,
        },
      };
    },
    detailLinks() {
      const { agentUid, recepientUserUid: clientId, externalOperationUid: operationId } = this.item;

      return createDetailLinks('clientDeposits', {
        operationId,
        clientId,
        agentUid,
      });
    },
  },
};
</script>
