<template>
  <div :class="getRootClass">
    <slot />
    <app-btn
      class="ml-2"
      :icon="true"
      :small="true"
      @click="clipboardService.handleClick(icon.name)"
    >
      <app-icon
        :icon="icon.name"
        :color="icon.color"
      />
    </app-btn>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { ClipboardService } from '@/components/common/app-clipboard/app-clipboard-service';

import { AppBtn } from '@/components/common/app-btn';
import { AppIcon } from '@/components/common/app-icon';

export default {
  name: 'app-clipboard',
  components: {
    AppBtn,
    AppIcon,
  },
  props: {
    value: VueTypes.oneOfType([
      VueTypes.string,
      VueTypes.number,
    ]).isRequired,
    classes: {
      type: String,
      default: '',
    },
  },
  created() {
    this.clipboardService = new ClipboardService({
      defaultIcon: this.icon,
      valueToCopy: this.value,
      changeIcon: this.changeIcon,
      copyToClipboard: this.$clipboard,
    });
  },
  data() {
    return {
      icon: {
        name: 'copy',
        color: 'accent',
      },
    };
  },
  computed: {
    getRootClass() {
      const rootClass = 'd-flex align-center';

      return this.classes ? [rootClass, this.classes] : rootClass;
    },
  },
  methods: {
    changeIcon(name, color) {
      this.icon = {
        name,
        color: color || 'accent',
      };
    },
  },
};
</script>
