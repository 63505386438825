import { COMMON_ROUTES } from '@/router/routes-const';

import PageNotFound from '@/views/page-not-found.vue';
import PageSignIn from '@/views/page-sign-in.vue';

const PageGuideline = () => import(
  /* webpackChunkName: "page-guideline" */
  '@/views/page-guideline.vue'
);

export default [
  {
    path: COMMON_ROUTES.SIGN_IN.PATH,
    name: COMMON_ROUTES.SIGN_IN.NAME,
    component: PageSignIn,
    meta: {
      notAuth: true,
    },
  },
  {
    path: COMMON_ROUTES.GUIDELINE.PATH,
    name: COMMON_ROUTES.GUIDELINE.NAME,
    component: PageGuideline,
    meta: {
      auth: true,
    },
  },
  {
    path: COMMON_ROUTES.NOT_FOUND.PATH,
    name: COMMON_ROUTES.NOT_FOUND.NAME,
    alias: '*',
    component: PageNotFound,
  },
];
