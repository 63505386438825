import getArrTail from './get-arr-tail';

/**
 * pathToVal example - obj.val.deepVal or path/to/some/nested/val
 */
export default function (pathToVal, separator = '.') {
  return pathToVal.includes(separator)
    ? getArrTail(pathToVal.split(separator))
    : pathToVal;
}
