export default function ({ data = [], pagination }) {
  return {
    items: data.map((clientWithdrawal) => {
      const { id, attributes: { charge, ...restAttributes } } = clientWithdrawal;

      return {
        id,
        agentUid: restAttributes.agentUid,
        clientUuid: restAttributes.clientUuid,
        clientUid: restAttributes.clientUid,
        operationUuid: restAttributes.operationUuid || restAttributes.operationId,
        operationUid: restAttributes.operationUid || restAttributes.operationId,
        amount: charge.amount,
        currency: charge.currency,
        accountDetails: restAttributes.accountDetails || '-',
        createdAt: restAttributes.createdAt,
        title: restAttributes.title || '-',
      };
    }),
    pagination,
  };
}
