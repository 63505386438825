<template>
  <router-link :to="{ name: redirectTo }">
    <img
      class="mr-5"
      alt="app logo"
      height="70"
      width="70"
      :src="logoSrc"
    >
  </router-link>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'toolbar-logo',
  props: {
    logoSrc: VueTypes.string.isRequired,
    redirectTo: VueTypes.string.isRequired,
  },
};
</script>

<style lang="scss" scoped>
  img {
    max-width: 100%;
    height: auto;
  }
</style>
