<template>
  <app-toolbar
    :auth-user-id="userUid"
    :user-link-name="agentEmail"
    :nav-links="availableLinks"
    :logo-link="logoLink"
    @clicked-sign-out="signOutUser"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ACTION_FETCH_AGENT } from '@/store/modules/content/action-types';
import { ACTION_SIGN_OUT } from '@/store/modules/auth/action-types';
import { COMMON_ROUTES } from '@/router/routes-const';
import { getHomePageName } from '@/router/helpers';
import get from 'lodash.get';

import { AppToolbar } from '@/components/core/app-toolbar';

import appToolbarNavLinks from './app-toolbar-nav-links';

export default {
  name: 'app-toolbar-container',
  components: {
    AppToolbar,
  },
  async mounted() {
    if (this.isUserHasAgentRole) {
      await this[ACTION_FETCH_AGENT]({ uid: this.authData.userUid });
    }
  },
  computed: {
    ...mapGetters('content', ['getStateDataItem']),
    ...mapGetters('auth', ['authData']),

    userUid() {
      return this.authData?.userUid || '';
    },
    isUserHasAgentRole() {
      const roles = this.authData?.roles;
      return !!(this.userUid && roles.includes('agent'));
    },
    logoLink() {
      const { roles } = this.authData;
      return getHomePageName({ userRole: roles && roles.length > 0 ? roles[0] : '' });
    },
    agentEmail() {
      const agent = this.userUid && this.getStateDataItem({ type: 'agents', id: this.userUid });
      return this.isUserHasAgentRole && agent ? get(agent, ['attributes', 'email'], '') : '';
    },
    links() {
      return appToolbarNavLinks(this.$route.name);
    },
    availableLinks() {
      return this.links.filter(({ route: { name } }) => {
        const route = this.$router.abilityByName[name];
        return route && route.isPermited();
      });
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_AGENT]),
    ...mapActions('auth', [ACTION_SIGN_OUT]),

    async signOutUser() {
      await this[ACTION_SIGN_OUT]({
        onSignOut: () => this.$router.push({ name: COMMON_ROUTES.SIGN_IN.NAME }),
      });
    },
  },
};
</script>
