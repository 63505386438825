export class Strategy {
  _name;
  _doAction;

  constructor(name, doAction) {
    this._name = name;
    this._doAction = doAction;
  }

  get name() {
    return this._name;
  }

  get action() {
    return this._doAction;
  }
}
