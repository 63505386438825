import compose from 'lodash.flowright';
import maps from './maps';

export default function (params) {
  if (!params) {
    return {};
  }

  // Server understands it like in SQL WHERE foo LIKE "xxx%"
  const LIKE = '%';
  // Server understands it like symbol of desc order
  const DESC = '-';
  // Search at the beginning of a string for fields like: humanId, code
  const createExactMatchSearchVal = (val) => `${val}${LIKE}`;
  // Set partial match search for fields like: email, name, accountDetails
  const createPartialMatchSearchVal = (val) => `${LIKE}${val}${LIKE}`;

  const { sorting: sortingParams, ...filterParams } = params;
  const hasSortingParams = Object.values(sortingParams).length;

  const getValue = (key, val) => (
    (maps.searchExactQueryParamKeys.includes(key) && createExactMatchSearchVal(val))
    || (maps.searchPartialQueryParamKeys.includes(key) && createPartialMatchSearchVal(val))
    || (val.trim?.() ?? val)
  );
  const getAdaptedQueryParams = (filterEntries) => filterEntries.map(([filterParamName, filterValue]) => (
    [maps.filterQueryParams.get(filterParamName), getValue(filterParamName, filterValue)]
  ));
  const filterFalseValues = (filterEntries) => (
    filterEntries.filter(([, filterValue]) => filterValue)
  );
  const adaptFilterQueryParams = compose(
    Object.fromEntries,
    getAdaptedQueryParams,
    filterFalseValues,
    Object.entries,
  );

  const createSortingParam = (sortingEntries) => ({
    sort: sortingEntries.flatMap((sortingEntry) => sortingEntry.reverse().join('')).join(','),
  });
  const getAdaptedSortingParams = (sortingEntries) => sortingEntries.map(([sortingParamName, sortingDirection]) => (
    [maps.sortingQueryParams.get(sortingParamName), sortingDirection === 'desc' ? DESC : '']
  ));
  const adaptSortingQueryParams = compose(
    createSortingParam,
    getAdaptedSortingParams,
    Object.entries,
  );

  return hasSortingParams
    ? { ...adaptFilterQueryParams(filterParams), ...adaptSortingQueryParams(sortingParams) }
    : adaptFilterQueryParams(filterParams);
}
