<template>
  <v-form>
    <v-text-field
      v-model="credentials.username"
      label="Email"
      required
    />
    <v-text-field
      v-model="credentials.password"
      label="Password"
      type="password"
      required
    />
    <vue-recaptcha
      v-if="isCaptchaVisible"
      ref="recaptcha"
      :sitekey="$options.googleReCaptchaKey"
      :loadRecaptchaScript="true"
      @verify="saveCaptchaToken"
      @expired="resetCaptcha"
    />
    <div class="text-xs-center">
      <app-btn
        color="primary"
        @click="handleSubmit"
      >
        Submit
      </app-btn>
    </div>
  </v-form>
</template>

<script>
import VueTypes from 'vue-types';
import { CONFIG } from '@/consts';

import VueRecaptcha from 'vue-recaptcha';
import { AppBtn } from '@/components/common/app-btn';

export default {
  name: 'form-authorization',
  components: {
    VueRecaptcha,
    AppBtn,
  },
  props: {
    errorCode: VueTypes.number.def(0),
  },
  googleReCaptchaKey: CONFIG.TOKEN.G_RECAPTCHA,
  data() {
    return {
      credentials: {
        username: null,
        password: null,
      },
      isCaptchaVisible: false,
      reCaptchaToken: null,
    };
  },
  watch: {
    errorCode(code) {
      if (this.isCaptchaVisible) {
        this.resetCaptcha();
      }

      if (code === 429) {
        this.isCaptchaVisible = true;
      }
    },
  },
  methods: {
    handleSubmit() {
      if (this.credentials.username && this.credentials.password) {
        this.$emit('submit', {
          credentials: {
            ...this.credentials,
            username: this.credentials.username.trim(),
            reCaptchaToken: this.reCaptchaToken,
          },
        });
      }
    },
    saveCaptchaToken(reCaptchaToken) {
      this.reCaptchaToken = reCaptchaToken;
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
      this.reCaptchaToken = '';
    },
  },
};
</script>
