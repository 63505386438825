export default {
  isUserAuth: ({ auth: { userUid } }) => !!userUid,
  authData: ({ auth }) => auth,
  isUserHasAgentRole: (state, { authData: { roles } }) => roles[0] === 'agent',
  isUserHasFinancierRole: (state, { authData: { roles } }) => roles[0] === 'financier',
  accessToken: ({ auth: { access_token: accessToken } }) => accessToken,
  refreshToken: ({ auth: { refresh_token: refreshToken } }) => refreshToken,
  expiresAt: ({ auth: { expires_at: expiresAt } }) => expiresAt,
  authErrorCode: ({ authErrorCode }) => authErrorCode,
  authErrors: ({ authErrors }) => authErrors,
  getUserUid: ({ auth: { userUid } }) => userUid,
};
