<template>
  <v-flex>
    <h1>Payment Agents</h1>
    <table-content-provider
      :table-type="$options.constants.tableType"
      v-slot="{ isInitLoadingComplete, pagination, items, handleChange }"
    >
      <div v-if="isInitLoadingComplete">
        <table-complex
          :filters="tableFilters"
          :filter-names="filterNames"
          :headers="tableHeaders"
          :items="items"
          :min-items-per-page="$options.constants.minItemsPerPage"
          :pagination="pagination"
          @pagination-change="handleChange"
        >
          <template v-slot="{ item }">
            <payment-agents-table-row
              :payment-agent="item"
              :route-name-to-item="$options.constants.agentsRoute"
            />
          </template>
        </table-complex>
        <app-btn
          v-if="isUserHasFinancierRole"
          color="primary"
          @click="$router.push({ name: $options.constants.newAgentRoute })"
        >
          New
        </app-btn>
      </div>
    </table-content-provider>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { TABLE_TYPES, MIN_ITEMS_PER_PAGE } from '@/consts';
import { FINANCIER_ROUTES } from '@/router/routes-const';
import { TableFilter, TableHeader } from '@/models';

import { TableContentProvider } from '@/components/providers/table-content';
import { TableComplex } from '@/components/common/table-complex';
import { PaymentAgentsTableRow } from '@/components/tables/agents';
import { AppBtn } from '@/components/common/app-btn';

export default {
  name: 'FinancierAgentsIndex',
  components: {
    TableContentProvider,
    TableComplex,
    PaymentAgentsTableRow,
    AppBtn,
  },
  constants: {
    tableType: TABLE_TYPES.PAYMENT_AGENTS,
    agentsRoute: FINANCIER_ROUTES.AGENT.NAME,
    newAgentRoute: FINANCIER_ROUTES.NEW_AGENT.NAME,
    minItemsPerPage: MIN_ITEMS_PER_PAGE,
  },
  computed: {
    ...mapGetters('auth', ['isUserHasFinancierRole']),
    filterNames() {
      return ['title', 'email', 'name'];
    },
    tableFilters() {
      return [
        new TableFilter('Title', [], this.filterNames[0]),
        new TableFilter('Email', [], this.filterNames[1]),
        new TableFilter('Name', [], this.filterNames[2]),
      ];
    },
    tableHeaders() {
      return [
        new TableHeader('Title', 'title'),
        new TableHeader('Email', 'email'),
        new TableHeader('Name', 'name'),
        new TableHeader('Balance', 'balance', false),
        new TableHeader('Updated at', 'updatedAt'),
        new TableHeader('Created at', 'createdAt'),
        new TableHeader('', '', false),
      ];
    },
  },
};
</script>
