import { truncate, formatDate } from '@/utils';

/**
 * A class representing one of the money operations performed by an agent: withdrawal, deposit.
 */
export default class Operation {
  static STATUS_NAMES = {
    REQUESTED: 'requested',
    PROCESSING: 'processing',
    CANCELED: 'canceled',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
  };

  id;
  agentUid;
  humanId;
  title;
  accountDetails;
  amount;
  currency;
  paymentSystem;
  compensation;
  statusName;
  statusUpdatedAt;
  statusColor;
  createdAt;

  constructor(responce, THEME) {
    const { currency, amount, ...opts } = responce;

    Object.assign(this, {
      ...opts,
      amount: `${amount} ${currency}`,
      accountDetails: opts.accountDetails,
      statusUpdatedAt: formatDate(opts.statusUpdatedAt),
      statusColor: Operation._getStatusColor(THEME, opts.statusName),
      createdAt: formatDate(opts.createdAt),
    });
  }

  static _getStatusColor(THEME, statusName) {
    return new Map([
      [Operation.STATUS_NAMES.REQUESTED, THEME.INFO],
      [Operation.STATUS_NAMES.PROCESSING, THEME.ACCENT],
      [Operation.STATUS_NAMES.CANCELED, THEME.ACCENT],
      [Operation.STATUS_NAMES.ACCEPTED, THEME.SUCCESS],
      [Operation.STATUS_NAMES.REJECTED, THEME.ERROR],
    ]).get(statusName) || '#000';
  }

  get truncatedAccountDetails() {
    const maxLength = 30;
    return truncate(this.accountDetails, maxLength);
  }
}
