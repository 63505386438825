import { FINANCIER_ROUTES } from '@/router/routes-const';

import FinancierHome from '@/views/financier/FinancierHome.vue';
import FinancierContactMenIndex from '@/views/financier/agents/Index.vue';
import FinancierContactMenShow from '@/views/financier/agents/Show.vue';
import FinancierContactMenDepositsNew from '@/views/financier/agents/deposits/New.vue';
import FinancierContactMenDepositsShow from '@/views/financier/agents/deposits/show.vue';
import FinancierContactMenDepositsEdit from '@/views/financier/agents/deposits/Edit.vue';
import FinancierContactMenDepositsIndex from '@/views/financier/agents/deposits/Index.vue';
import FinancierContactMenWithdrawalsNew from '@/views/financier/agents/withdrawals/New.vue';
import FinancierContactMenWithdrawalsShow from '@/views/financier/agents/withdrawals/show.vue';
import FinancierContactMenWithdrawalsEdit from '@/views/financier/agents/withdrawals/Edit.vue';
import FinancierContactMenWithdrawalsIndex from '@/views/financier/agents/withdrawals/Index.vue';
import FinancierClientsWithdrawalsShow from '@/views/financier/clients_withdrawals/show/show.vue';
import FinancierClientsWithdrawalsIndex from '@/views/financier/clients_withdrawals/index/Index.vue';
import FinancierPrepaidCardsIndex from '@/views/financier/agents/prepaid_cards/index/Index.vue';
import FinancierPrepaidCardsShow from '@/views/financier/agents/prepaid_cards/show/show.vue';
import PageClientDeposit from '@/views/financier/page-client-deposit/page-client-deposit.vue';
import PageDetailClientDeposit
  from '@/views/financier/page-client-deposit/page-detail-client-deposits/page-detail-client-deposit.vue';

const PageCreateAgent = () => import(
  /* webpackChunkName: "page-create-agent" */
  '@/views/financier/page-create-agent.vue'
);

// TODO: https://app.asana.com/0/1199997557733026/1203158481300629
// const PageCreateClientDeposit = () => import(
//   /* webpackChunkName: "page-create-client-deposit" */
//   '@/views/agent/page-client-deposit/page-created-client-deposit/page-created-client-deposit.vue'
// );

export default [
  {
    path: FINANCIER_ROUTES.HOME.PATH,
    name: FINANCIER_ROUTES.HOME.NAME,
    component: FinancierHome,
  },
  {
    path: FINANCIER_ROUTES.AGENTS_DEPOSIT_NEW.PATH,
    name: FINANCIER_ROUTES.AGENTS_DEPOSIT_NEW.NAME,
    component: FinancierContactMenDepositsNew,
  },
  {
    path: FINANCIER_ROUTES.DEPOSIT.PATH,
    name: FINANCIER_ROUTES.DEPOSIT.NAME,
    component: FinancierContactMenDepositsShow,
  },
  {
    path: FINANCIER_ROUTES.DEPOSIT_EDIT.PATH,
    name: FINANCIER_ROUTES.DEPOSIT_EDIT.NAME,
    component: FinancierContactMenDepositsEdit,
  },
  {
    path: FINANCIER_ROUTES.AGENT_DEPOSITS.PATH,
    name: FINANCIER_ROUTES.AGENT_DEPOSITS.NAME,
    component: FinancierContactMenDepositsIndex,
  },
  {
    path: FINANCIER_ROUTES.AGENT_WITHDRAWALS_NEW.PATH,
    name: FINANCIER_ROUTES.AGENT_WITHDRAWALS_NEW.NAME,
    component: FinancierContactMenWithdrawalsNew,
  },
  {
    path: FINANCIER_ROUTES.AGENT_WITHDRAWAL.PATH,
    name: FINANCIER_ROUTES.AGENT_WITHDRAWAL.NAME,
    component: FinancierContactMenWithdrawalsShow,
  },
  {
    path: FINANCIER_ROUTES.WITHDRAWAL_EDIT.PATH,
    name: FINANCIER_ROUTES.WITHDRAWAL_EDIT.NAME,
    component: FinancierContactMenWithdrawalsEdit,
  },
  {
    path: FINANCIER_ROUTES.AGENT_WITHDRAWALS.PATH,
    name: FINANCIER_ROUTES.AGENT_WITHDRAWALS.NAME,
    component: FinancierContactMenWithdrawalsIndex,
  },
  {
    path: FINANCIER_ROUTES.CLIENT_WITHDRAWAL.PATH,
    name: FINANCIER_ROUTES.CLIENT_WITHDRAWAL.NAME,
    component: FinancierClientsWithdrawalsShow,
  },
  {
    path: FINANCIER_ROUTES.CLIENT_WITHDRAWALS.PATH,
    name: FINANCIER_ROUTES.CLIENT_WITHDRAWALS.NAME,
    component: FinancierClientsWithdrawalsIndex,
  },
  {
    path: FINANCIER_ROUTES.PREPAID_CARD.PATH,
    name: FINANCIER_ROUTES.PREPAID_CARD.NAME,
    component: FinancierPrepaidCardsShow,
  },
  {
    path: FINANCIER_ROUTES.PREPAID_CARDS.PATH,
    name: FINANCIER_ROUTES.PREPAID_CARDS.NAME,
    component: FinancierPrepaidCardsIndex,
  },
  {
    path: FINANCIER_ROUTES.AGENT.PATH,
    name: FINANCIER_ROUTES.AGENT.NAME,
    component: FinancierContactMenShow,
  },
  {
    path: FINANCIER_ROUTES.AGENTS.PATH,
    name: FINANCIER_ROUTES.AGENTS.NAME,
    component: FinancierContactMenIndex,
  },
  {
    path: FINANCIER_ROUTES.NEW_AGENT.PATH,
    name: FINANCIER_ROUTES.NEW_AGENT.NAME,
    component: PageCreateAgent,
  },
  {
    path: FINANCIER_ROUTES.CLIENT_DEPOSITS.PATH,
    name: FINANCIER_ROUTES.CLIENT_DEPOSITS.NAME,
    component: PageClientDeposit,
  },
  // TODO: https://app.asana.com/0/1199997557733026/1203158481300629
  // {
  //   path: FINANCIER_ROUTES.NEW_CLIENT_DEPOSITS.PATH,
  //   name: FINANCIER_ROUTES.NEW_CLIENT_DEPOSITS.NAME,
  //   component: PageCreateClientDeposit,
  // },
  {
    path: FINANCIER_ROUTES.AGENT_CLIENT_DEPOSITS.PATH,
    name: FINANCIER_ROUTES.AGENT_CLIENT_DEPOSITS.NAME,
    component: PageDetailClientDeposit,
  },
].map((route) => ({
  ...route,
  meta: {
    roles: ['financier', 'support'],
  },
}));
