import { createModule } from '@/lib/create-module';

const moduleRoot = () => import(
  /* webpackChunkName: "module-acard-guideline" */
  '../acard-guideline/components/root-component.vue'
);

export default {
  register: createModule(moduleRoot, {
    createProvide() {
      return {
        images: [...new Array(14)].reduce((acc, el, idx) => ({
          ...acc,
          // eslint-disable-next-line global-require,import/no-dynamic-require
          [`path${idx}`]: require(`./assets/guideline${idx}.png`),
        }), {}),
      };
    },
  }),
};
