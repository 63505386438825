import { setFormRules, setOnInputTransducers } from '@/services';

export default [
  {
    componentName: 'v-text-field',
    modelName: 'amount',
    label: 'Amount',
    isDisabled: false,
    onInput: setOnInputTransducers([
      'trim',
      'changeFirstDotSymbolToZero',
      'changeSecondCommaSymbolToDot',
    ]),
    value: '',
    maxLength: 7,
    rules: setFormRules([
      'isRequired',
      'isNumber',
      'notZero',
      'notMoreThan||1000000',
    ]),
  },
  {
    componentName: 'v-select',
    modelName: 'currency',
    label: 'Currency',
    isDisabled: true,
    selectItems: ['USD'],
    value: 'USD',
  },
  {
    componentName: 'v-text-field',
    modelName: 'accountDetails',
    label: 'Account details',
    isDisabled: false,
    onInput: setOnInputTransducers(['trim']),
    value: '',
    rules: setFormRules([
      'isRequired',
    ]),
  },
];
