<template>
  <v-container>
    <v-layout>
      <router-view />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'main-layout',
};
</script>
