<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Prepaid Card
    template(v-if="!isContentLoading")
      v-layout
        agent-prepaid-card(:item="prepaidCard")
      v-btn(
        v-if="isPrepaidCardNew"
        color="error"
        @click="cancel"
      ) Cancel
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ACTION_FETCH_CONTENT, ACTION_FETCH_PREPAID_CARDS_OF_AGENTS } from '@/store/modules/content/action-types';
import {
  ACTION_CHANGE_ITEM_STATUS,
  ACTION_CANCEL_PREPAID_CARD,
} from '@/store/modules/content-interaction/action-types';
import { withConfirm } from '@/utils';

import { AgentPrepaidCard } from '@/components/cards/agent-prepaid-card';

export default {
  name: 'AgentDepositsShow',
  components: {
    AgentPrepaidCard,
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_PREPAID_CARDS_OF_AGENTS,
      agentUid: this.authData.userUid,
      uid: this.$route.params.uid,
    });
  },
  computed: {
    ...mapGetters('auth', ['authData']),
    ...mapGetters('content', ['isContentLoading', 'getStateDataItem']),

    prepaidCard() {
      return this.getStateDataItem({ type: 'prepaid-cards', id: this.$route.params.uid });
    },
    isPrepaidCardNew() {
      const itemState = this.prepaidCard?.attributes?.state?.name;
      return itemState === 'new';
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
    ...mapActions('content-interaction', [ACTION_CHANGE_ITEM_STATUS]),

    async cancel() {
      await withConfirm(this[ACTION_CHANGE_ITEM_STATUS])({
        actionType: ACTION_CANCEL_PREPAID_CARD,
        code: this.prepaidCard.attributes.code,
      });
    },
  },
};
</script>
