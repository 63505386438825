<template>
  <app-expansion-panel class="guideline-expansion-panel" :items="items">
    <template v-slot:title="{ title }">
      <div>{{ title }}</div>
    </template>
    <template v-slot:content="{ content }">
      <div v-html="content" />
    </template>
  </app-expansion-panel>
</template>

<script>
import VueTypes from 'vue-types';

import { AppExpansionPanel } from '@/components/common/app-expansion-panel';

export default {
  name: 'guideline-expansion-panel',
  components: {
    AppExpansionPanel,
  },
  props: {
    items: VueTypes.array.isRequired,
  },
};
</script>

<style lang="scss">
@import './guideline-expansion-panel';
</style>
