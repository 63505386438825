import flowRight from 'lodash.flowright';
import transducers from './transducers';

/**
 * This service is a helper func with transducers methods
 * which transduces input value of VUETIFY form fields
 */

export default function (transducerNamesArr = []) {
  const defaultFunc = (val) => val;

  if (Array.isArray(transducerNamesArr) && transducerNamesArr.length) {
    const transducersForCall = transducerNamesArr.reduce((acc, nextTransducerName) => {
      if (transducers[nextTransducerName]) {
        acc.push(transducers[nextTransducerName]());
      }

      return acc;
    }, []);

    return (val) => (transducersForCall.length ? flowRight(...transducersForCall)(val) : defaultFunc(val));
  }

  return (val) => defaultFunc(val);
}
