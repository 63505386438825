<template>
  <guideline-expansion-panel :items="items" />
</template>

<script>
import { MarkdownContentService } from '../../services/markdown-content/markdown-content';
import { markdownCompilerService } from '../../services/markdown-compiler';

import { GuidelineExpansionPanel } from '../../components/guideline-expansion-panel';

export default {
  name: 'container-guideline-expansion-panel',
  components: {
    GuidelineExpansionPanel,
  },
  inject: [
    'images',
  ],
  computed: {
    items() {
      const textContent = new MarkdownContentService(markdownCompilerService, this.images);
      return textContent.generate().items;
    },
  },
};
</script>
