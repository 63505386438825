export default {
  HOME: {
    NAME: 'financier_home',
    PATH: '/financier',
  },
  AGENTS_DEPOSIT_NEW: {
    NAME: 'new_financier_agents_deposit',
    PATH: '/financier/agents/:agent_uid/deposits/new',
  },
  DEPOSIT: {
    NAME: 'financier_deposit',
    PATH: '/financier/agents/:agent_uid/deposits/:uid',
  },
  DEPOSIT_EDIT: {
    NAME: 'edit_financier_deposit',
    PATH: '/financier/agents/:agent_uid/deposits/:uid/edit',
  },
  AGENT_DEPOSITS: {
    NAME: 'financier_agents_deposits',
    PATH: '/financier/agents/deposits',
  },
  AGENT_WITHDRAWALS_NEW: {
    NAME: 'new_financier_agents_withdrawal',
    PATH: '/financier/agents/:agent_uid/withdrawals/new',
  },
  AGENT_WITHDRAWAL: {
    NAME: 'financier_withdrawal',
    PATH: '/financier/agents/:agent_uid/withdrawals/:uid',
  },
  WITHDRAWAL_EDIT: {
    NAME: 'edit_financier_withdrawal',
    PATH: '/financier/agents/:agent_uid/withdrawals/:uid/edit',
  },
  AGENT_WITHDRAWALS: {
    NAME: 'financier_agents_withdrawals',
    PATH: '/financier/agents/withdrawals',
  },
  CLIENT_WITHDRAWAL: {
    NAME: 'financier_clients_withdrawal',
    PATH: '/financier/clients/withdrawals/:uid',
  },
  CLIENT_WITHDRAWALS: {
    NAME: 'financier_clients_withdrawals',
    PATH: '/financier/clients/withdrawals',
  },
  PREPAID_CARD: {
    NAME: 'financier_prepaid_card',
    PATH: '/financier/agents/:agent_uid/prepaid_cards/:uid',
  },
  PREPAID_CARDS: {
    NAME: 'financier_prepaid_cards',
    PATH: '/financier/agents/prepaid_cards',
  },
  AGENT: {
    NAME: 'financier_agent',
    PATH: '/financier/agents/:uid',
  },
  AGENTS: {
    NAME: 'financier_agents',
    PATH: '/financier/agents',
  },
  NEW_AGENT: {
    NAME: 'financier_create_agent',
    PATH: '/financier/create-agent',
  },
  CLIENT_DEPOSITS: {
    NAME: 'financier_client_deposits',
    PATH: '/financier/client-deposits',
  },
  AGENT_CLIENT_DEPOSITS: {
    NAME: 'financier_show_client_deposits',
    PATH: '/financier/client-deposits/:uid',
  },
  NEW_CLIENT_DEPOSITS: {
    NAME: 'financier_create_client_deposits',
    PATH: '/financier/agent/:agent_uid/new',
  },
};
