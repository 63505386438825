<template>
  <form-prepaid-card
    submit-btn-text="create"
    :server-errors="formErrors"
    :is-content-loading="isContentLoading"
    @submit="handleSubmit"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTION_CREATE_PREPAID_CARD_FOR_AGENT } from '@/store/modules/content-interaction/action-types';

import { FormPrepaidCard } from '@/components/forms/prepaid-card';

export default {
  name: 'form-new-prepaid-card-container',
  components: {
    FormPrepaidCard,
  },
  computed: {
    ...mapGetters('content-interaction', ['formErrors']),
    ...mapGetters('auth', ['authData']),
    ...mapGetters('content', ['isContentLoading']),
  },
  methods: {
    ...mapActions('content-interaction', [ACTION_CREATE_PREPAID_CARD_FOR_AGENT]),

    onSuccess() {
      this.$router.push({ name: 'agent_prepaid_cards' });
    },
    async handleSubmit({ count, amount, currency }) {
      await this[ACTION_CREATE_PREPAID_CARD_FOR_AGENT]({
        agentUid: this.authData.userUid,
        data: {
          type: 'prepaid-cards',
          attributes: {
            charge: { amount, currency },
            count,
          },
        },
        onSuccess: this.onSuccess,
      });
    },
  },
};
</script>
