import { AGENT_ROUTES, FINANCIER_ROUTES } from '@/router/routes-const';

const addRouteActivityStatus = (currentRouteName) => (routeObj) => ({
  ...routeObj,
  isActive: routeObj.route.name === currentRouteName,
});

export default function (currentRouteName) {
  return [
    {
      text: 'Deposits',
      route: {
        name: AGENT_ROUTES.DEPOSITS.NAME,
      },
    },
    {
      text: 'Withdrawals',
      route: {
        name: AGENT_ROUTES.WITHDRAWALS.NAME,
      },
    },
    {
      text: 'Prepaid Cards',
      route: {
        name: AGENT_ROUTES.PREPAID_CARDS.NAME,
      },
    },
    {
      text: 'Client Withdrawals',
      route: {
        name: AGENT_ROUTES.CLIENT_WITHDRAWALS.NAME,
      },
    },
    {
      text: 'Client Deposits',
      route: {
        name: AGENT_ROUTES.CLIENT_DEPOSITS.NAME,
      },
    },
    {
      text: 'Deposits',
      route: {
        name: FINANCIER_ROUTES.AGENT_DEPOSITS.NAME,
      },
    },
    {
      text: 'Withdrawals',
      route: {
        name: FINANCIER_ROUTES.AGENT_WITHDRAWALS.NAME,
      },
    },
    {
      text: 'Prepaid Cards',
      route: {
        name: FINANCIER_ROUTES.PREPAID_CARDS.NAME,
      },
    },
    {
      text: 'Client Withdrawals',
      route: {
        name: FINANCIER_ROUTES.CLIENT_WITHDRAWALS.NAME,
      },
    },
    {
      text: 'Client Deposits',
      route: {
        name: FINANCIER_ROUTES.CLIENT_DEPOSITS.NAME,
      },
    },
    {
      text: 'Payment Agents',
      route: {
        name: FINANCIER_ROUTES.AGENTS.NAME,
      },
    },
  ].map(addRouteActivityStatus(currentRouteName));
}
