<template lang="pug">
  v-container(id="home")
    h1(class="mb-4 px-7") Home

    h4(class="px-7")
      router-link(:to="{ name: agentProfileRoute }") Profile
    br

    v-layout(v-if="finances")
      v-container(id="home")
        Finances(:item="finances" pageType="agent")
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ACTION_FETCH_CONTENT, ACTION_FETCH_AGENT_FINANCE } from '@/store/modules/content/action-types';
import { AGENT_ROUTES } from '@/router/routes-const';

export default {
  name: 'AgentHome',
  components: {
    Finances: () => import('@/components/agent/balance/Finances.vue'),
    CommonErrors: () => import('@/components/core/CommonErrors.vue'),
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT_FINANCE,
      uid: this.authData.userUid,
    });
  },
  computed: {
    ...mapGetters('auth', ['authData']),
    ...mapGetters('content', ['getStateDataItem']),

    finances() {
      return this.getStateDataItem({ type: 'agent-finances', id: this.authData.userUid });
    },
    agentProfileRoute() {
      return AGENT_ROUTES.PROFILE.NAME;
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
  },
};
</script>
