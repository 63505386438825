export class ClipboardService {
  _defaultIcon;
  _valueToCopy;
  _changeIcon;
  _copyToClipboard;

  constructor({
    defaultIcon,
    valueToCopy,
    changeIcon,
    copyToClipboard,
  }) {
    this._defaultIcon = defaultIcon;
    this._valueToCopy = valueToCopy;
    this._changeIcon = changeIcon;
    this._copyToClipboard = copyToClipboard;
  }

  handleClick(iconName) {
    const handler = this._getHandlerByIconName(iconName);

    if (handler) {
      handler();
    }
  }

  _backIconToDefault() {
    const { name, color } = this._defaultIcon;
    const timeout = 450;

    setTimeout(() => {
      this._changeIcon(name, color);
    }, timeout);
  }

  _copy = () => {
    this._changeIcon('check', 'primary');
    this._copyToClipboard(this._valueToCopy);
    this._backIconToDefault();
  }

  _getHandlerByIconName(iconName) {
    const handlersByIconName = {
      copy: this._copy,
      check: null,
    };

    return handlersByIconName?.[iconName];
  }
}
