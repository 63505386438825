<template>
  <div class="prepaid-card">
    <div
      class="prepaid-card__info-wrapper"
      v-for="(val, key) in cardData"
      :key="key"
    >
      <item-info-slot>
        <template v-slot:title v-if="val">{{ key }}</template>
        <template v-slot:description>
          <a
            v-if="isLinkable(key)"
            target="_blank"
            :href="getDetailLink(key)"
          >
            {{ val }}
          </a>
          <span v-else>{{ val }}</span>
        </template>
      </item-info-slot>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import get from 'lodash.get';
import { createDetailLinks } from '@/utils';
import { cardMixin } from '@/mixins';

import { ItemInfoSlot } from '@/components/common/item-info-slot';

const LINKABLE_KEYS = {
  ACTIVATED_BY_USER: 'Activated by User',
  OFFICE_PAYMENT_ID: 'Office Payment ID',
};

export default {
  name: 'agent-prepaid-card',
  components: {
    ItemInfoSlot,
  },
  mixins: [
    cardMixin,
  ],
  props: {
    item: VueTypes.object.def(() => ({})),
  },
  computed: {
    code() {
      return get(this.mix_attributes, 'code', '');
    },
    status() {
      return get(this.mix_attributes, ['state', 'name'], '');
    },
    clientUid() {
      return get(this.mix_attributes, 'client_uid', '');
    },
    operationUid() {
      return get(this.mix_attributes, 'operation_uid', '');
    },
    cardData() {
      return {
        'Amount': this.mix_amount,
        'Code': this.code,
        'Status': this.status,
        [LINKABLE_KEYS.ACTIVATED_BY_USER]: this.clientUid,
        [LINKABLE_KEYS.OFFICE_PAYMENT_ID]: this.operationUid,
        'Status updated at': this.mix_updatedAt,
        'Created at': this.mix_createdAt,
      };
    },
    detailLinks() {
      const links = createDetailLinks('prepaidCard', {
        clientId: this.clientUid,
        operationId: this.operationUid,
      });

      return {
        [LINKABLE_KEYS.ACTIVATED_BY_USER]: links.linkToUser,
        [LINKABLE_KEYS.OFFICE_PAYMENT_ID]: links.linkToEditOperation,
      };
    },
  },
  methods: {
    isLinkable(key) {
      return Object.values(LINKABLE_KEYS).includes(key);
    },
    getDetailLink(key) {
      return this.detailLinks[key];
    },
  },
};
</script>
