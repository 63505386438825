import { CONFIG } from '@/consts';

/**
 * Creates links like https://abc.amarkets.org/admin/withdrawals/some_id/edit for financier needs
 */
// TODO: need to replace this logic to getters of ClientWithdrawalModel and PrepaidCardModel
export default function (linksType, { clientId, operationId, agentUid }) {
  const availableTypes = {
    WITHDRAWAL: 'withdrawal',
    PREPAID_CARD: 'prepaidCard',
    CLIENT_DEPOSITS: 'clientDeposits',
  };

  if (!Object.values(availableTypes).includes(linksType)) {
    throw new Error(`${availableTypes.WITHDRAWAL}, ${availableTypes.PREPAID_CARD}: only this types are available`);
  }

  const addEndpointToBaseUrl = (endpoint) => new URL(endpoint, CONFIG.URL['3RD_PARTY_CLIENT_DETAILS']).href;

  const detailLinks = new Map([
    [availableTypes.WITHDRAWAL, {
      linkToUser: addEndpointToBaseUrl(`/manager/users/${clientId}`),
      linkToEditOperation: addEndpointToBaseUrl(`/admin/withdrawals/${operationId}/edit`),
    }],
    [availableTypes.PREPAID_CARD, {
      linkToUser: addEndpointToBaseUrl(`/manager/users/${clientId}`),
      linkToEditOperation: addEndpointToBaseUrl(`/admin/payments/${operationId}/edit`),
    }],
    [availableTypes.CLIENT_DEPOSITS, {
      linkToAgent: `/financier/agents/${agentUid}`,
      linkToUser: addEndpointToBaseUrl(`/manager/users/${clientId}`),
      linkToEditOperation: addEndpointToBaseUrl(`/admin/payments/${operationId}/edit`),
    }],
  ]);

  return detailLinks.get(linksType);
}
