<template>
  <tr>
    <td>
      <app-clipboard-truncated :value="item.id" />
    </td>
    <td>{{ item.title }}</td>
    <td>{{ item.amount }}</td>
    <td>{{ item.paymentSystem }}</td>
    <td v-if="isAgentDepositTable">{{ item.compensation }}</td>
    <td>{{ item.truncatedAccountDetails }}</td>
    <td>
      <v-chip :color="item.statusColor" dark>
        {{ item.statusName }}
      </v-chip>
    </td>
    <td>{{ item.statusUpdatedAt }}</td>
    <td>{{ item.createdAt }}</td>
    <td>
      <router-link :to="redirectAttrs">Show</router-link>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';
import { TABLE_TYPES } from '@/consts';
import { AppClipboardTruncated } from '@/components/common/app-clipboard-truncated';

export default {
  name: 'table-row-s',
  components: { AppClipboardTruncated },
  props: {
    item: VueTypes.object.isRequired,
    routeNameToItem: VueTypes.string.isRequired,
    tableType: {
      type: String,
      default: TABLE_TYPES.AGENT_WITHDRAWALS,
    },
  },
  computed: {
    redirectAttrs() {
      return {
        name: this.routeNameToItem,
        params: {
          uid: this.item.id,
          agent_uid: this.item.agentUid,
        },
      };
    },
    isAgentDepositTable() {
      return this.tableType === TABLE_TYPES.AGENT_DEPOSITS;
    },
  },
};
</script>
