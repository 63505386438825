<script>
import VueTypes from 'vue-types';
import { mapActions } from 'vuex';
import {
  ACTION_ACCEPT_AGENT_WITHDRAWAL,
  ACTION_CANCEL_AGENT_WITHDRAWAL,
  ACTION_REJECT_AGENT_WITHDRAWAL,
  ACTION_START_PROCESSING_AGENT_WITHDRAWAL,
  ACTION_ACCEPT_AGENT_DEPOSIT,
  ACTION_CANCEL_AGENT_DEPOSIT,
  ACTION_REJECT_AGENT_DEPOSIT,
  ACTION_START_PROCESSING_AGENT_DEPOSIT,
  ACTION_CHANGE_ITEM_STATUS,
} from '@/store/modules/content-interaction/action-types';
import { withConfirm } from '@/utils';

export default {
  name: 'financier-commands-provider',
  props: {
    commandsFor: VueTypes.oneOf(['withdrawal', 'deposit']).isRequired,
  },
  computed: {
    isCommandsForWithdrawal() {
      return this.commandsFor === 'withdrawal';
    },
  },
  methods: {
    ...mapActions('content-interaction', [ACTION_CHANGE_ITEM_STATUS]),

    async dispatchAction(actionType, uid) {
      await this[ACTION_CHANGE_ITEM_STATUS]({ actionType, uid });
    },
    selectActionType(withdrawalActionType, depositActionType) {
      return this.isCommandsForWithdrawal ? withdrawalActionType : depositActionType;
    },
    async start(uid) {
      await this.dispatchAction(
        this.selectActionType(
          ACTION_START_PROCESSING_AGENT_WITHDRAWAL,
          ACTION_START_PROCESSING_AGENT_DEPOSIT,
        ), uid,
      );
    },
    async accept(uid) {
      await this.dispatchAction(
        this.selectActionType(
          ACTION_ACCEPT_AGENT_WITHDRAWAL,
          ACTION_ACCEPT_AGENT_DEPOSIT,
        ), uid,
      );
    },
    async reject(uid) {
      const dispatchAction = withConfirm(this.dispatchAction);
      await dispatchAction(
        this.selectActionType(
          ACTION_REJECT_AGENT_WITHDRAWAL,
          ACTION_REJECT_AGENT_DEPOSIT,
        ), uid,
      );
    },
    async cancel(uid) {
      const dispatchAction = withConfirm(this.dispatchAction);
      await dispatchAction(
        this.selectActionType(
          ACTION_CANCEL_AGENT_WITHDRAWAL,
          ACTION_CANCEL_AGENT_DEPOSIT,
        ), uid,
      );
    },
    edit(routeName) {
      this.$router.push({ name: routeName });
    },
  },
  render() {
    return this.$scopedSlots.default({
      start: this.start,
      accept: this.accept,
      reject: this.reject,
      cancel: this.cancel,
      edit: this.edit,
    });
  },
};
</script>
