<template>
  <form method="POST" target="_blank" id="perfect_money_deposit_form" ref="form" :action="formAction">
    <!-- payee - account in amex payment system -->
    <input type="hidden" name="PAYEE_ACCOUNT" :value="paymentAccount" />
    <input type="hidden" name="PAYEE_NAME" :value="paymentName" />
    <!-- address to sending information about success payment -->
    <input type="hidden" name="STATUS_URL" :value="paymentSucceedUrl" />
    <input type="hidden" name="PAYMENT_AMOUNT" :value="itemFields | getObjField('amount', '')" />
    <input type="hidden" name="PAYMENT_UNITS" :value="itemFields | getObjField('currency', '')" />
    <input type="hidden" name="PAYMENT_ID" :value="itemFields | getObjField('id', '')" />
    <!-- address for viewing amex payment -->
    <input type="hidden" name="PAYMENT_URL" :value="paymentUrl" />
    <!--  address for redirection in amex if payment failed -->
    <input type="hidden" name="NOPAYMENT_URL" :value="paymentUrl" />
    <!-- additional fields -->
    <input type="hidden" name="BAGGAGE_FIELDS" value="AGENT_UID PAYMENT_HUMAN_ID" />
    <input type="hidden" name="AGENT_UID" :value="itemFields | getObjField('agent_uid', '')" />
    <input type="hidden" name="PAYMENT_HUMAN_ID" :value="itemFields | getObjField('human_id', '')" />
    <input type="hidden" name="PAYMENT_METHOD" />
  </form>
</template>

<script>
/**
 * This is the hidden part of perfect money form which allows to send payment data.
 * Since perfect money doesn't have an api endpoints we need to use native form to send data to them.
*/

import VueTypes from 'vue-types';
import { CONFIG } from '@/consts';

const isObjectNotEmpty = (obj) => obj && Object.values(obj).length;

export default {
  name: 'form-deposit-hidden',
  props: {
    lastCreatedDeposit: VueTypes.object.def(() => ({})),
    formAction: VueTypes.string.isRequired,
  },
  watch: {
    async lastCreatedDeposit(deposit) {
      await this.$nextTick();

      if (isObjectNotEmpty(deposit)) {
        this.submitForm();
        this.$emit('pm-data-sent');
      }
    },
  },
  computed: {
    itemFields() {
      return isObjectNotEmpty(this.lastCreatedDeposit) ? ({
        id: this.lastCreatedDeposit.id,
        amount: this.lastCreatedDeposit.attributes.charge.amount,
        currency: this.lastCreatedDeposit.attributes.charge.currency,
        agent_uid: this.lastCreatedDeposit.attributes.agent_uid,
        human_id: this.lastCreatedDeposit.attributes.human_id,
      }) : {};
    },
    paymentAccount() {
      return CONFIG.PERFECT_MONEY.ACCOUNT;
    },
    paymentName() {
      return CONFIG.PERFECT_MONEY.NAME;
    },
    paymentUrl() {
      /**
       * Hack:
       *  `__fragment__` on NGINX level will be change on `#`
       *  this is necessary because perfect money remove `#` from transferred URL
       *  if you'll find a less ugly solution it would be great
       */
      return `${window.location.origin}/__fragment__/agent/deposits/${this.itemFields && this.itemFields.id}`;
    },
    paymentSucceedUrl() {
      return `${CONFIG.URL.API}/agent-deposits/accept_by_perfect_money`;
    },
  },
  methods: {
    submitForm() {
      this.$refs.form.submit();
    },
  },
};
</script>
