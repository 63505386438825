<template lang="pug">
  v-layout(column align-start justify-start fill-height class="finances mt-2 pa-7 rounded-xl")
    h3
      strong Balances
    v-flex(v-if="item.attributes.usd")
      | {{item.attributes.usd.total.currency}}: {{item.attributes.usd.total.amount}} $
      v-btn(:href="hrefOfBtn" target="_blank"
        color="info" :outlined="true" type="button" class="ms-7")
        | All Transactions
        app-icon(icon="open-in-new" color="info" class="ms-2")
</template>

<script>
import { AppIcon } from '@/components/common/app-icon';
import { ALL_TRANSACTIONS } from './acard-link';

export default {
  name: 'finances',
  components: {
    AppIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    agentName: {
      type: String,
      default: '',
    },
    pageType: {
      type: String,
      default: 'agent',
    },
  },
  computed: {
    hrefOfBtn() {
      if (this.pageType === 'agent') {
        return `${ALL_TRANSACTIONS}`;
      }

      const role = 'acard_financier';
      const { id } = this.item;
      return `${ALL_TRANSACTIONS}?role=${role}&agentUid=${id}&agentName=${this.agentName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'finances';
</style>
