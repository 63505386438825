import { createModule } from '@/lib/create-module';
import { AGENT_CREATED } from './constants';

const moduleRoot = () => import(
  /* webpackChunkName: "agent-creation-module" */
  '../agent-creation/components/root-component.vue'
);

export default {
  register: createModule(moduleRoot, {
    createProvide(_this) {
      return {
        $agentCreated(agentUid) {
          _this.$emit(AGENT_CREATED, agentUid);
        },
      };
    },
  }),
};
