import { ApiService } from '@/lib/api-service';

export class ClientDepositCreationApiService extends ApiService {
  createClientDeposit({
    agentUid,
    walletId,
    phone,
    amount,
    currency,
  }) {
    return this.post('client-deposits', {
      type: 'client-deposits',
      attributes: {
        agent_uid: agentUid,
        wallet_id: walletId,
        phone_number: phone,
        charge: {
          amount,
          currency,
        },
      },
    });
  }
}
