import compose from 'lodash.flowright';
import createMarkdown from './create-markdown';

export class MarkdownContentService {
  _markdownCompiler;
  _imgLinks
  _contentItems;

  constructor(markdownCompiler, imgLinks) {
    this._markdownCompiler = markdownCompiler;
    this._imgLinks = imgLinks;
  }

  get items() {
    return this._contentItems;
  }

  generate() {
    const md = createMarkdown(this._imgLinks);
    this._contentItems = md.map(({ title, content }) => ({
      title,
      content: compose(
        this._markdownCompiler.compile,
        MarkdownContentService._joinContent,
      )(content),
    }));

    return this;
  }

  static _joinContent(content) {
    return content.map((topic) => `${topic}\n`).join('');
  }
}
