<template>
  <v-flex xs="12" md="10" offset-md="1">
    <h1 class="mb-4">Prepaid Cards</h1>
    <agent-titles-provider v-slot="{ isTitlesLoaded, agentTitles }">
      <table-content-provider
        :table-type="$options.constants.tableType"
        v-slot="{ isInitLoadingComplete, pagination, items, handleChange }"
      >
        <table-complex
          v-if="isInitLoadingComplete && isTitlesLoaded"
          :filters="createTableFilters(agentTitles)"
          :filter-names="filterNames"
          :headers="tableHeaders"
          :items="items"
          :min-items-per-page="$options.constants.minItemsPerPage"
          :pagination="pagination"
          @pagination-change="handleChange"
        >
          <template v-slot="{ item }">
            <table-row
              :item="item"
              :route-name-to-item="$options.constants.prepaidCardRoute"
            />
          </template>
        </table-complex>
      </table-content-provider>
    </agent-titles-provider>
  </v-flex>
</template>

<script>
import { TABLE_TYPES, MIN_ITEMS_PER_PAGE } from '@/consts';
import { FINANCIER_ROUTES } from '@/router/routes-const';
import { PrepaidCard, TableFilter, TableHeader } from '@/models';

import { TableContentProvider } from '@/components/providers/table-content';
import { AgentTitlesProvider } from '@/components/providers/agent-titles';
import { TableComplex } from '@/components/common/table-complex';
import TableRow from './table-row.vue';

export default {
  name: 'FinancierAgentsWithdrawalsIndex',
  components: {
    TableContentProvider,
    AgentTitlesProvider,
    TableComplex,
    TableRow,
  },
  constants: {
    tableType: TABLE_TYPES.PREPAID_CARDS,
    prepaidCardRoute: FINANCIER_ROUTES.PREPAID_CARD.NAME,
    minItemsPerPage: MIN_ITEMS_PER_PAGE,
  },
  computed: {
    filterNames() {
      return [
        'code',
        'statusName',
        'title',
        'createdAtFrom',
        'createdAtTo',
        'ActivatedBy',
        'OperationID',
        'uid',
      ];
    },
    tableFilters() {
      return [
        new TableFilter('ID', [], this.filterNames[7]),
        new TableFilter('Code', [], this.filterNames[0]),
        new TableFilter('Status', Object.values(PrepaidCard.STATUS_NAMES), this.filterNames[1]),
      ];
    },
    tableHeaders() {
      return [
        new TableHeader('ID', 'uid', false),
        new TableHeader('Payment agent', 'title'),
        new TableHeader('Amount', 'amount'),
        new TableHeader('Code', 'code'),
        new TableHeader('Status', 'statusName'),
        new TableHeader('Operation id', 'operationUid'),
        new TableHeader('Activated by', 'agentUid'),
        new TableHeader('Status updated at', 'statusUpdatedAt'),
        new TableHeader('Created at', 'createdAt'),
        new TableHeader('', '', false),
      ];
    },
  },
  methods: {
    createTableFilters(agentTitles) {
      return [
        new TableFilter('ID', [], this.filterNames[7]),
        new TableFilter('Payment Agent', agentTitles, this.filterNames[2]),
        new TableFilter('Status', Object.values(PrepaidCard.STATUS_NAMES), this.filterNames[1]),
        new TableFilter('Code', [], this.filterNames[0]),
        new TableFilter('Activated by', [], this.filterNames[5]),
        new TableFilter('Operation ID', [], this.filterNames[6]),
        new TableFilter('Created at from', [], this.filterNames[3], true),
        new TableFilter('Created at to', [], this.filterNames[4], true),
      ];
    },
  },
};
</script>
