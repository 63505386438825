import { ErrorHandlerService, ErrorParser, NotifierService } from '@/services';
import { TRANSPORT_ERRORS } from '@/consts';
import { StrategyManager } from '@/lib/strategy-manager';
import { Strategy } from '@/lib/strategy';

import actionErrorSubscriber from './action-error-subscriber';

export function registerStoreSubscribers(app) {
  app.$store.subscribeAction({
    error: actionErrorSubscriber(
      new ErrorHandlerService(
        TRANSPORT_ERRORS,
        new StrategyManager([
          new Strategy('transportError', (e) => ErrorParser.parseTransportError(e, TRANSPORT_ERRORS)),
          new Strategy('validationError', ErrorParser.parseValidationError),
          new Strategy('oauthValidationError', ErrorParser.parseOauthValidationError),
          new Strategy('businessLogicError', ErrorParser.parseBusinessLogicError),
        ]),
        new NotifierService(app.$toast),
      ),
    ),
  });
}
