<template>
  <form-withdrawal
    v-if="editableWithdrawal"
    submit-btn-text="save"
    :server-errors="formErrors"
    :init-values="initValues"
    @submit="handleSubmit"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import get from 'lodash.get';
import { ACTION_UPDATE_AGENT_WITHDRAWAL } from '@/store/modules/content-interaction/action-types';
import { FINANCIER_ROUTES } from '@/router/routes-const';

import { FormWithdrawal } from '@/components/forms/withdrawal';

export default {
  name: 'form-edit-withdrawal-container',
  components: {
    FormWithdrawal,
  },
  computed: {
    ...mapGetters('content', ['getStateDataItem']),
    ...mapGetters('content-interaction', ['formErrors']),

    editableWithdrawal() {
      return this.getStateDataItem({ type: 'agent-withdrawals', id: this.$route.params.uid });
    },
    initValues() {
      const attributes = this.editableWithdrawal?.attributes;
      const amount = get(attributes, ['charge', 'amount']);
      const accountDetails = get(attributes, 'account_details');

      return [
        { modelName: 'amount', value: amount },
        { modelName: 'accountDetails', value: accountDetails },
      ];
    },
  },
  methods: {
    ...mapActions('content-interaction', [ACTION_UPDATE_AGENT_WITHDRAWAL]),

    onSuccess(withdrawal) {
      this.$router.push({
        name: FINANCIER_ROUTES.AGENT_WITHDRAWAL.NAME,
        params: {
          agentUid: withdrawal.attributes.agentUid,
          uid: withdrawal.id,
        },
      });
    },
    async handleSubmit({ amount, accountDetails, currency }) {
      const {
        attributes: { account_details: details, charge, ...restAttributes },
        ...restWithdrawal
      } = this.editableWithdrawal;

      await this[ACTION_UPDATE_AGENT_WITHDRAWAL]({
        uid: this.editableWithdrawal.id,
        onSuccess: this.onSuccess,
        data: {
          ...restWithdrawal,
          attributes: {
            ...restAttributes,
            account_details: accountDetails,
            charge: {
              amount,
              currency,
            },
          },
        },
      });
    },
  },
};
</script>
