export function createModule(rootComponent, { props = {}, createProvide = () => {} } = {}) {
  return (name, Vue, deps = {}) => {
    if (!name) {
      throw new Error('module name is required');
    }

    if (!Vue?.component) {
      throw new Error('Vue class must have the component method to module registration');
    }

    if (typeof createProvide !== 'function') {
      throw new Error('createProvide argument must be a function type');
    }

    const config = {
      props,
      provide() {
        return {
          ...createProvide(this),
          ...deps,
        };
      },
      render(h) {
        return h(rootComponent, { on: this.$listeners });
      },
    };

    Vue.component(name, config);
  };
}
