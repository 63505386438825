<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Withdrawals
    form-new-withdrawal-container
</template>

<script>
import { FormNewWithdrawalContainer } from '@/components/containers/agent/form-new-withdrawal';

export default {
  name: 'AgentWithdrawalsNew',
  components: {
    FormNewWithdrawalContainer,
  },
};
</script>
