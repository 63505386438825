import Vue from 'vue';
import { CONFIG } from '@/consts';

export default {
  signIn(credentials) {
    return Vue.$oauth.password(credentials);
  },
  getUser({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`users/${uid}`));
  },
  signOut({ refreshToken }) {
    return Vue.axios.request({
      method: 'POST',
      url: `${CONFIG.URL.AUTH}/sign_out`,
      data: { refresh_token: refreshToken },
    });
  },
};
