import Vue from 'vue';
import humps from 'humps';

export default {
  getPrepaidCards({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.get('prepaid-cards', {
      params: {
        'filter[uid]': uid,
      },
    }));
  },
  getAgent({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`agents/${uid}`));
  },
  getAgentDeposits() {
    return Vue.$oauth.access(() => Vue.axios.get('agent-deposits'));
  },
  getAgentDeposit({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`agent-deposits/${uid}`));
  },
  getAgentFinances({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`agent-finances/${uid}`));
  },
  getAgentDepositsOfAgent({ agentUid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`agents/${agentUid}/agent-deposits`));
  },
  getAgentWithdrawalsOfAgent({ agentUid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`agents/${agentUid}/agent-withdrawals`));
  },
  getAgentWithdrawal({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`agent-withdrawals/${uid}`));
  },
  getAgentWithdrawals({ pageNumber = 1, pageSize = 5 } = {}) {
    return Vue.$oauth.access(() => Vue.axios
      .get('agent-withdrawals', {
        params: {
          'page[number]': pageNumber,
          'page[size]': pageSize,
        },
      })
      .then((response) => (
        humps.camelizeKeys(response)
      )));
  },
  getClientWithdrawal({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`client-withdrawals/${uid}`));
  },
  getClientWithdrawals() {
    return Vue.$oauth.access(() => Vue.axios.get('client-withdrawals'));
  },
  getClientWithdrawalsOfAgent({ agentUid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`agents/${agentUid}/client-withdrawals`));
  },
  getPrepaidCardsOfAgent({ agentUid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`agents/${agentUid}/prepaid-cards`));
  },
  getClientDeposits() {
    return Vue.$oauth.access(() => Vue.axios.get('agents/client-deposits'));
  },
  getClientDepositsOfAgent({ uid }) {
    return Vue.$oauth.access(() => Vue.axios.get(`client-deposits/${uid}`));
  },
};
