// Правила отображения страниц и ссылок на них текущему пользователю (getAuth())
//   по заданным опциям (opts); опции могут быть взяты из meta роутов.
//
//   Так, эта авторизация опирается не на ресурс а на роут,
//     что позволяет легко управлять отображением ссылок,
//     но и создаёт некоторые ограничения если в роуте нехватает данных для предоставления доступа

/* eslint-disable */ // TODO: refactor code below
class Ability {
  constructor(opts, getAuth) {
    this.requireRoles = opts.roles;
    this.requireAuth = !!(opts.auth || opts.roles);
    this.requireNotAuth = !!opts.notAuth;

    this._getAuth = getAuth;
  }

  //
  // Позитивные утверждения
  //

  // Доступ к роуту разрешён
  isPermited() { return !this.authRequired() && !this.logoutRequired() && !this.roleMissmatch(); }

  //
  // Негативные утверждения (для определения поведения в зависимости от типа запрета)
  //

  // Требуется аутентификация но пользователь не аутентифицирован
  authRequired() {
    return this.requireAuth && !this.isAuthed();
  }

  // Требуется отсутствие аутентификации но пользователь аутентифицирован
  logoutRequired() {
    return this.requireNotAuth && this.isAuthed();
  }

  // Требуется отсутствующая у пользователя роль
  roleMissmatch() {
    if (!this.requireRoles) return false;

    const userRoles = this._getAuth().roles;
    const intersectingRoles = userRoles.filter((role) => this.requireRoles.includes(role));
    return !intersectingRoles.length;
  }

  isAuthed() { return this._getAuth().userUid; }
}

export default Ability;
export { Ability };
