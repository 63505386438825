export default function ({ models, CONSTANTS: C }) {
  return {
    getStateDataItem: ({ data }) => ({ type, id }) => data?.[type]?.[id] || null,
    getTableItems: ({ data }) => ({ tableType, pageNumber }) => {
      const tableItems = data?.[tableType]?.items?.[pageNumber];
      const modelByTableType = new Map([
        [C.TABLE_TYPES.AGENT_DEPOSITS, { Model: models.Operation, extraArgs: [C.THEME] }],
        [C.TABLE_TYPES.AGENT_WITHDRAWALS, { Model: models.Operation, extraArgs: [C.THEME] }],
        [C.TABLE_TYPES.PREPAID_CARDS, { Model: models.PrepaidCard, extraArgs: [C.THEME] }],
        [C.TABLE_TYPES.CLIENT_WITHDRAWALS, { Model: models.ClientWithdrawal, extraArgs: [] }],
        [C.TABLE_TYPES.PAYMENT_AGENTS, { Model: models.PaymentAgent, extraArgs: [] }],
        [C.TABLE_TYPES.AGENT_WITHDRAWALS_OF_AGENT, { Model: models.Operation, extraArgs: [C.THEME] }],
        [C.TABLE_TYPES.AGENT_DEPOSITS_OF_AGENT, { Model: models.Operation, extraArgs: [C.THEME] }],
        [C.TABLE_TYPES.AGENT_PREPAID_CARDS_OF_AGENT, { Model: models.PrepaidCard, extraArgs: [C.THEME] }],
        [C.TABLE_TYPES.AGENT_CLIENT_WITHDRAWALS_OF_AGENT, { Model: models.ClientWithdrawal, extraArgs: [] }],
        [C.TABLE_TYPES.AGENT_CLIENT_DEPOSITS, { Model: models.ClientDeposits, extraArgs: [] }],
        [C.TABLE_TYPES.AGENT_CLIENT_DEPOSITS_OF_AGENT, { Model: models.ClientDeposits, extraArgs: [] }],
      ]);

      if (tableItems) {
        const { Model, extraArgs } = modelByTableType.get(tableType);
        return Object.values(tableItems).map((values) => new Model(values, ...extraArgs));
      }

      return [];
    },
    getTablePagination: ({ data }) => ({ tableType }) => {
      const paginationData = data?.[tableType]?.pagination;
      return paginationData
        ? new models.Pagination(
          paginationData.currentPage,
          paginationData.totalPages,
          paginationData.totalRecords,
        )
        : undefined;
    },
    isContentLoading: ({ isContentLoading }) => isContentLoading,
  };
}
