import { TABLE_TYPES } from '@/consts';
import * as types from './types';

export default function ({
  api,
  TableDataApiService,
  Vue,
  mappers,
}) {
  return {
    async [types.ACTION_FETCH_CONTENT]({ dispatch }, { actionType, agentUid, uid } = {}) {
      try {
        const requestByActionType = {
          [types.ACTION_FETCH_PREPAID_CARDS_OF_AGENTS]: () => api.getPrepaidCardsOfAgent({ agentUid }),
          [types.ACTION_FETCH_CLIENT_WITHDRAWAL]: () => api.getClientWithdrawal({ uid }),
          [types.ACTION_FETCH_AGENT_WITHDRAWAL]: () => api.getAgentWithdrawal({ uid }),
          [types.ACTION_FETCH_AGENT_FINANCE]: () => api.getAgentFinances({ uid }),
          [types.ACTION_FETCH_AGENT_DEPOSIT]: () => api.getAgentDeposit({ uid }),
          [types.ACTION_FETCH_PREPAID_CARDS]: () => api.getPrepaidCards({ uid }),
          [types.ACTION_FETCH_AGENT]: () => api.getAgent({ uid }),
          [types.ACTION_FETCH_CLIENT_DEPOSITS]: () => api.getClientDepositsOfAgent({ uid }),
        };
        const request = requestByActionType?.[actionType];

        if (request) {
          await dispatch(types.ACTION_CHANGE_LOADING_CONTENT_STATUS, { isLoading: true });
          const data = await request();
          await dispatch(types.ACTION_HANDLE_CONTENT_DATA, { data: data?.data ? data?.data : data });
        }
      } catch (e) {
        console.error(e, `error while ${types.ACTION_FETCH_CONTENT} - ${actionType}`);
        throw e;
      } finally {
        await dispatch(types.ACTION_CHANGE_LOADING_CONTENT_STATUS, { isLoading: false });
      }
    },
    [types.ACTION_HANDLE_CONTENT_DATA]({ commit }, { data }) {
      if (Array.isArray(data)) {
        commit(types.MUTATION_SET_DATA_COLLECTION, { items: data });
      } else {
        commit(types.MUTATION_SET_DATA_ITEM, { item: data });
      }
    },
    async [types.ACTION_FETCH_AGENT]({ dispatch }, { uid }) {
      try {
        const data = await api.getAgent({ uid });
        await dispatch(types.ACTION_HANDLE_CONTENT_DATA, { data });
      } catch (e) {
        console.error(e, `error while ${types.ACTION_FETCH_AGENT}`);
        throw e;
      }
    },
    [types.ACTION_CHANGE_LOADING_CONTENT_STATUS]({ commit }, { isLoading }) {
      commit(types.MUTATION_SET_CONTENT_LOADING_STATUS, { isLoading });
    },
    async [types.ACTION_FETCH_TABLE_CONTENT]({ commit, dispatch }, {
      tableType,
      pageNumber,
      pageSize,
      filters,
      sorting,
      agentUid,
    }) {
      const tds = new TableDataApiService(Vue.$oauth, Vue.axios, mappers);
      const tableDataRequests = new Map([
        [TABLE_TYPES.AGENT_WITHDRAWALS, (params) => tds.getAgentWithdrawals(params)],
        [TABLE_TYPES.AGENT_DEPOSITS, (params) => tds.getAgentDeposits(params)],
        [TABLE_TYPES.PREPAID_CARDS, (params) => tds.getPrepaidCards(params)],
        [TABLE_TYPES.CLIENT_WITHDRAWALS, (params) => tds.getClientWithdrawals(params)],
        [TABLE_TYPES.PAYMENT_AGENTS, (params) => tds.getPaymentAgents(params)],
        [TABLE_TYPES.AGENT_WITHDRAWALS_OF_AGENT, (params) => tds.getAgentWithdrawalsOfAgent(params)],
        [TABLE_TYPES.AGENT_DEPOSITS_OF_AGENT, (params) => tds.getAgentDepositsOfAgent(params)],
        [TABLE_TYPES.AGENT_PREPAID_CARDS_OF_AGENT, (params) => tds.getPrepaidCardsOfAgent(params)],
        [TABLE_TYPES.AGENT_CLIENT_WITHDRAWALS_OF_AGENT, (params) => tds.getClientWithdrawalsOfAgent(params)],
        [TABLE_TYPES.AGENT_CLIENT_DEPOSITS, (params) => tds.getClientDeposits(params)],
        [TABLE_TYPES.AGENT_CLIENT_DEPOSITS_OF_AGENT, (params) => tds.getClientDepositsOfAgent(params)],
      ]);
      const request = tableDataRequests.get(tableType);
      const baseRequestParams = { pageNumber, pageSize, sorting, filters };
      const requestParams = agentUid ? { agentUid, ...baseRequestParams } : baseRequestParams;

      try {
        await dispatch(types.ACTION_CHANGE_LOADING_CONTENT_STATUS, { isLoading: true });

        // TODO: make init of the api service out of the action scope.
        //  Can't init out now because of the $oauth initialized after the store and consumes it
        const { items, pagination } = await request(requestParams);
        const mutationPayload = { items, pagination, tableType };
        // Add to existing item collection or create a new one
        const mutationType = pageNumber ? types.MUTATION_ADD_TABLE_ITEMS : types.MUTATION_SET_TABLE_ITEMS;

        commit(mutationType, mutationPayload);
      } catch (e) {
        console.error(e, `error while ${types.ACTION_FETCH_TABLE_CONTENT} - ${tableType}`);
        throw e;
      } finally {
        await dispatch(types.ACTION_CHANGE_LOADING_CONTENT_STATUS, { isLoading: false });
      }
    },
    [types.ACTION_REMOVE_TABLE_CONTENT]({ commit }, { tableType }) {
      commit(types.MUTATION_REMOVE_TABLE_ITEMS, { tableType });
    },
  };
}
