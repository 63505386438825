<template>
  <app-view
    :is-content-loaded="!isContentLoading"
    :has-common-layout="isCommonLayout"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { AGENT_ROUTES, COMMON_ROUTES, FINANCIER_ROUTES } from '@/router/routes-const';

import { AppView } from '@/components/core/app-view';

export default {
  name: 'app-view-container',
  components: {
    AppView,
  },
  computed: {
    ...mapGetters('content', ['isContentLoading']),

    isCommonLayout() {
      const { HOME: AGENT_HOME, PROFILE: AGENT_PROFILE } = AGENT_ROUTES;
      const { HOME: FINANCIER_HOME } = FINANCIER_ROUTES;
      const { SIGN_IN, NOT_FOUND } = COMMON_ROUTES;

      return ![
        AGENT_HOME.NAME,
        AGENT_PROFILE.NAME,
        FINANCIER_HOME.NAME,
        SIGN_IN.NAME,
        NOT_FOUND.NAME,
      ].includes(this.$route.name);
    },
  },
};
</script>
