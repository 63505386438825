import { AuthResponse } from './auth_response';
// TODO: need to refactor code below
/* eslint-disable */
// Сценарий доступа к защищенному ресурсу
//   Принимая в call() функцию, возвращающую Axios Promise,
//   при необходимости обновляет access_token, и выполняет или повторяет запрос
class AccessInteractor {
  constructor(attrs) {
    // запрос на обновление request и refresh токенов
    //   должен возвращать Axios Promise
    this.refresh = attrs.refresh;

    // коллбэк если обновить токен не удалось
    //   получает null если getRefreshToken() или getAccessToken() вернул null
    //   получает результат негативного ответа refresh()
    this.onSignInRequired = attrs.onSignInRequired;

    // получение сохранённых на клиенте аутентификационных данных
    this.getAccessToken = attrs.getAccessToken;
    this.getExpiresAt = attrs.getExpiresAt; // должен возвращать integer (Unix Time)
    this.isRefreshing = false;
    this.refreshSubscribers = [];
  }

  // принимает функцию, осуществляющую запрос к защищенному ресурсу, возврающую Axios Promise
  call(request) {
    if (!this.getAccessToken()) {
      return this.onTokenMissed();
    }

    if (this.isExpired()) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.__refresh();
      }

      return this.__retryRequest(request);
    }

    this.refreshSubscribers = [];

    return request();
  }

  subscribeTokenRefresh(cb) {
    this.refreshSubscribers.push(cb);
  }
  
  onRrefreshed() {
    this.refreshSubscribers.map(cb => cb());
  }

  onExpired(onExpired) {
    return (httpError) => {
      const { response } = httpError;
      if (!response) throw httpError;

      const authResponse = new AuthResponse(response.headers['www-authenticate']);
      if (authResponse.error !== 'invalid_token') throw httpError;

      return onExpired();
    };
  }

  __refresh() {
    return (
      this.refresh()
        .catch(this.__mapRefreshError)
        .catch(this.__onErrorCallback(this.onSignInRequired))
        .then(() => {
          this.isRefreshing = false;
          this.onRrefreshed();
        })
    );
  }

  __retryRequest(request) {
    return new Promise((resolve) => {
      this.subscribeTokenRefresh(() => {
        return resolve(this.call(request));
      });
    });
  }


  // в случае ошибки обновления токена, сделать её доступной в неймспейсе refresh
  __mapRefreshError(httpError) {
    const namespacedHttpError = { refresh: httpError };
    throw namespacedHttpError; // refresh error always can be accessed from other field
  }

  // вызвать коллбэк необходимости авторизации, вернуть Promise с фейлом
  //   (поскольку ни один Promise ещё не был создан)
  //   (необходимо для полиморфизма: что бы всегда возвращать Promise)
  onTokenMissed() {
    this.onSignInRequired(null);
    return this.__failedPromise();
  }

  __onErrorCallback(callback) {
    return (error) => {
      callback(error);
      throw error;
    };
  }

  // Promise всегда возвращающий фэйл
  __failedPromise(err) { return new Promise((resolve, reject) => reject(err)); }

  isExpired() { return this.getExpiresAt() < this.__timeNow(); }

  __timeNow() { return new Date().getTime() / 1000; }
}

export { AccessInteractor };
export default AccessInteractor;
