<template>
  <form-deposit
    submit-btn-text="create"
    :server-errors="formErrors"
    :last-created-deposit="lastDepositItem"
    :is-content-loading="isContentLoading"
    @submit="handleSubmit"
    @pm-data-sent="redirectToCreatedDeposit"
  />
</template>

<script>
import humps from 'humps';
import { mapGetters, mapActions } from 'vuex';
import { ACTION_CREATE_AGENT_DEPOSIT_FOR_AGENT } from '@/store/modules/content-interaction/action-types';
import { PAYMENT_SYSTEMS } from '@/consts';
import { AGENT_ROUTES } from '@/router/routes-const';

import { FormDeposit } from '@/components/forms/deposit';

export default {
  name: 'form-new-deposit-container',
  components: {
    FormDeposit,
  },
  computed: {
    ...mapGetters('auth', ['authData']),
    ...mapGetters('content-interaction', ['formErrors', 'lastDepositItem']),
    ...mapGetters('content', ['isContentLoading']),
  },
  methods: {
    ...mapActions('content-interaction', [ACTION_CREATE_AGENT_DEPOSIT_FOR_AGENT]),

    redirectToCreatedDeposit(deposit = null) {
      this.$router.push({
        name: AGENT_ROUTES.AGENT_DEPOSIT.NAME,
        params: { uid: deposit === null ? this.lastDepositItem.id : deposit.id },
      });
    },
    createDeposit(formFields, onSuccess = null) {
      this[ACTION_CREATE_AGENT_DEPOSIT_FOR_AGENT]({
        onSuccess,
        agentUid: this.authData.userUid,
        data: {
          type: 'agent-deposits',
          attributes: {
            account_details: formFields.accountDetails,
            payment_system: humps.decamelize(formFields.paymentSystem.replace(/\s/g, ''), { separator: '_' }),
            charge: {
              amount: formFields.amount,
              currency: formFields.currency,
            },
          },
        },
      });
    },
    handleSubmit(formFields) {
      const cb = this.isNotPerfectMoney(formFields.paymentSystem) ? this.redirectToCreatedDeposit : null;
      this.createDeposit(formFields, cb);
    },
    isNotPerfectMoney(currentPayment) {
      return currentPayment !== PAYMENT_SYSTEMS.PERFECT_MONEY;
    },
  },
};
</script>
