<template>
  <form-deposit
    v-if="editableDeposit"
    submit-btn-text="save"
    :server-errors="formErrors"
    :init-values="initValues"
    :last-created-deposit="lastDepositItem"
    @submit="handleSubmit"
    @pm-data-sent="redirectToUpdatedDeposit"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import get from 'lodash.get';
import { ACTION_UPDATE_AGENT_DEPOSIT } from '@/store/modules/content-interaction/action-types';
import { PAYMENT_SYSTEMS } from '@/consts';
import { FINANCIER_ROUTES } from '@/router/routes-const';

import { FormDeposit } from '@/components/forms/deposit';

const isManualPayment = (currentPayment) => currentPayment === PAYMENT_SYSTEMS.MANUAL;

export default {
  name: 'form-edit-deposit-container',
  components: {
    FormDeposit,
  },
  computed: {
    ...mapGetters('content', ['getStateDataItem']),
    ...mapGetters('content-interaction', ['formErrors', 'lastDepositItem']),

    editableDeposit() {
      return this.getStateDataItem({ type: 'agent-deposits', id: this.$route.params.uid });
    },
    initValues() {
      const attributes = this.editableDeposit?.attributes;
      const amount = get(attributes, ['charge', 'amount']);
      const accountDetails = get(attributes, 'account_details');

      return [
        { modelName: 'amount', value: amount },
        { modelName: 'accountDetails', value: accountDetails },
      ];
    },
  },
  methods: {
    ...mapActions('content-interaction', [ACTION_UPDATE_AGENT_DEPOSIT]),

    redirectToUpdatedDeposit(deposit = null) {
      this.$router.push({
        name: FINANCIER_ROUTES.DEPOSIT.NAME,
        params: {
          agentUid: deposit === null ? this.lastDepositItem.attributes.agentUid : deposit.attributes.agentUid,
          uid: deposit === null ? this.lastDepositItem.id : deposit.id,
        },
      });
    },
    updateDeposit(formFields, onSuccess = null) {
      const {
        attributes: { charge, ...restDepositAttributes },
        ...restDeposit
      } = this.editableDeposit;

      this[ACTION_UPDATE_AGENT_DEPOSIT]({
        onSuccess,
        uid: this.editableDeposit.id,
        data: {
          ...restDeposit,
          attributes: {
            ...restDepositAttributes,
            account_details: formFields.accountDetails,
            charge: {
              amount: formFields.amount,
              currency: formFields.currency,
            },
          },
        },
      });
    },
    handleSubmit(formFields) {
      if (isManualPayment(formFields.paymentSystem)) {
        this.updateDeposit(formFields, this.redirectToUpdatedDeposit, formFields.paymentSystem);
      } else {
        this.updateDeposit(formFields);
      }
    },
  },
};
</script>
