export default function ({ data = [], pagination }) {
  return {
    items: data.map((deposits) => {
      const { id, attributes: { charge, ...restAttributes } } = deposits;
      return {
        id,
        agentUid: restAttributes.agentUid,
        walletId: restAttributes.walletId || '-',
        amount: charge.amount,
        currency: charge.currency,
        externalOperationUid: restAttributes.externalOperationUid,
        recepientUserUid: restAttributes.recepientUserUid,
        createdAt: restAttributes.createdAt,
        updatedAt: restAttributes.updatedAt,
        agentTitle: restAttributes.agentTitle || '-',
      };
    }),
    pagination,
  };
}
