<template>
  <tr>
    <td>
      <app-clipboard-truncated :value="withdrawal.id" />
    </td>
    <td>
      <app-clipboard v-if="withdrawal.operationUuid" :value="withdrawal.operationUuid">
        <a :href="detailLinks.linkToEditOperation" target="_blank">
          {{ withdrawal.operationUuid }}
        </a>
      </app-clipboard>
    </td>
    <td>
      <app-clipboard v-if="withdrawal.clientUuid" :value="withdrawal.clientUuid">
        {{ withdrawal.clientUuid }}
      </app-clipboard>
    </td>
    <td>
      <app-clipboard v-if="withdrawal.clientUid" :value="withdrawal.clientUid">
        <a :href="detailLinks.linkToUser" target="_blank">
          {{ withdrawal.clientUid }}
        </a>
      </app-clipboard>
    </td>
    <td>{{ withdrawal.amount }}</td>
    <td>{{ withdrawal.truncatedAccountDetails }}</td>
    <td>{{ withdrawal.createdAt }}</td>
    <td>
      {{ withdrawal.title }}
    </td>
    <td>
      <router-link :to="redirectAttrs">
        Show
      </router-link>
    </td>
  </tr>
</template>

<script>
import VueTypes from 'vue-types';
import { AppClipboard } from '@/components/common/app-clipboard';
import { createDetailLinks } from '@/utils';
import { ClientWithdrawal } from '@/models';
import { AppClipboardTruncated } from '@/components/common/app-clipboard-truncated';

export default {
  name: 'table-row',
  components: {
    AppClipboard,
    AppClipboardTruncated,
  },
  props: {
    withdrawal: VueTypes.instanceOf(ClientWithdrawal).isRequired,
    routeNameToItem: VueTypes.string.isRequired,
  },
  computed: {
    redirectAttrs() {
      return {
        name: this.routeNameToItem,
        params: {
          uid: this.withdrawal.id,
          agent_uid: this.withdrawal.agentUid,
        },
      };
    },
    detailLinks() {
      const { clientUid: clientId, operationUid: operationId } = this.withdrawal;
      return createDetailLinks('withdrawal', {
        clientId,
        operationId,
      });
    },
  },
};
</script>
