<template>
  <v-card class="table-search-filter">
    <v-card-title primary-title>
      <div>
        <h3>{{ title }}</h3>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                class="table-search-filter__item"
                cols="12"
                md="3"
                v-for="(filter, idx) in filters"
                :key="`${filter.label}${idx}`"
              >
                <v-autocomplete
                  v-if="filter.autocompleteItems.length"
                  :clearable="true"
                  :label="filter.label"
                  :mask="filter.mask"
                  :items="filter.autocompleteItems"
                  @input="sendValToParent($event, filter.filterFuncName)"
                />
                <v-menu
                  v-else-if="filter.isDate === true"
                  :close-on-content-click="false"
                  :offset-y="true"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateValues[idx]"
                      :label="filter.label"
                      :clearable="true"
                      :readonly="true"
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="mdi-calendar"
                      @click:clear="sendValToParent(null, filter.filterFuncName)"
                    />
                  </template>
                  <v-date-picker
                    v-model="dateValues[idx]"
                    @input="sendValToParent($event, filter.filterFuncName)"
                  />
                </v-menu>
                <v-text-field
                  v-else
                  v-debounce="(val) => sendValToParent(val, filter.filterFuncName)"
                  @click:clear="sendValToParent(null, filter.filterFuncName)"
                  :clearable="true"
                  :label="filter.label"
                  :mask="filter.mask"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'table-search-filter',
  props: {
    title: VueTypes.string,
    filters: VueTypes.oneOfType([
      VueTypes.array,
      VueTypes.arrayOf(VueTypes.shape({
        label: VueTypes.string.isRequired,
        autocompleteItems: VueTypes.array.isRequired,
        filterFuncName: VueTypes.string,
        mask: VueTypes.string,
      })),
    ]).isRequired,
  },
  data() {
    return {
      dateValues: [],
    };
  },
  methods: {
    sendValToParent(fieldValue, callMethod) {
      this.$emit('filter-change', { fieldValue, callMethod });
    },
  },
};
</script>

<style lang="scss">
  @import 'table-serach-filter';
</style>
