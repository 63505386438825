<template>
  <v-btn
    v-bind="$props"
    @click="$emit('click')"
  >
    <slot />
  </v-btn>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'app-btn',
  props: {
    color: VueTypes.oneOf([
      'primary',
      'success',
      'error',
      'accent',
      'info',
    ]).def('accent'),
    type: VueTypes.oneOf([
      'submit',
      'button',
    ]).def('button'),
    icon: VueTypes.bool.def(false),
    small: VueTypes.bool.def(false),
    outlined: VueTypes.bool.def(false),
  },
};
</script>
