import {
  MUTATION_SET_SERVER_VALIDATION_ERRORS,
  MUTATION_SET_CONTENT_LOADING_STATUS,
} from './types';

export default {
  [MUTATION_SET_SERVER_VALIDATION_ERRORS](state, { errors }) {
    state.serverValidationErrors = errors;
  },
  [MUTATION_SET_CONTENT_LOADING_STATUS](state, { isLoading }) {
    state.isContentLoading = isLoading;
  },
};
