export default class Notifier {
  static types = ['error', 'success', 'info'];
  _notification;

  constructor(notification) {
    this._notification = notification;
  }

  show({ type = 'info', msg }) {
    Notifier._guardAgainstWrongType(type);
    this._notification[type](msg, {
      timeout: 9000,
      draggable: false,
      closeOnClick: false,
    });
  }

  static _guardAgainstWrongType(type) {
    if (!Notifier.types.includes(type)) {
      const availableTypes = Notifier.types.join(',');
      throw new Error(`${type} is not exist. Only those types are available: ${availableTypes}`);
    }
  }
}
