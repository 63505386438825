<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Withdrawal
    template(v-if="!isContentLoading")
      v-layout
        agent-operation-card(:item="withdrawal")
      v-btn(
        v-if="isWithdrawalRequested"
        color="error"
        @click="cancel"
      ) Cancel
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ACTION_FETCH_CONTENT, ACTION_FETCH_AGENT_WITHDRAWAL } from '@/store/modules/content/action-types';
import {
  ACTION_CHANGE_ITEM_STATUS,
  ACTION_CANCEL_AGENT_WITHDRAWAL,
} from '@/store/modules/content-interaction/action-types';
import { withConfirm } from '@/utils';

import { AgentOperationCard } from '@/components/cards/agent-operation-card';

export default {
  name: 'AgentWithdrawalsShow',
  components: {
    AgentOperationCard,
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT_WITHDRAWAL,
      uid: this.$route.params.uid,
    });
  },
  computed: {
    ...mapGetters('content', ['isContentLoading', 'getStateDataItem']),

    withdrawal() {
      return this.getStateDataItem({ type: 'agent-withdrawals', id: this.$route.params.uid });
    },
    isWithdrawalRequested() {
      return this.withdrawal?.attributes?.state?.name === 'requested';
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
    ...mapActions('content-interaction', [ACTION_CHANGE_ITEM_STATUS]),

    async cancel() {
      await withConfirm(this[ACTION_CHANGE_ITEM_STATUS])({
        actionType: ACTION_CANCEL_AGENT_WITHDRAWAL,
        uid: this.$route.params.uid,
      });
    },
  },
};
</script>
