<template>
  <ul :class="rootClasses">
    <li
      v-for="listItem in listItems"
      :key="listItem.id"
      :class="allItemClasses"
    >
      <slot :item="listItem" />
    </li>
  </ul>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'list',
  props: {
    listItems: VueTypes.arrayOf(VueTypes.object).isRequired,
    itemClasses: VueTypes.arrayOf(VueTypes.string).def(() => []),
    isInline: VueTypes.bool.def(true),
  },
  computed: {
    rootClasses() {
      const baseClass = 'app-list';

      return [
        baseClass,
        this.isInline ? `${baseClass}--inline` : null,
      ];
    },
    allItemClasses() {
      return [...this.itemClasses] || null;
    },
  },
};
</script>

<style lang="scss">
  @import 'app-list';
</style>
