export default class TableHeader {
  text;
  value;
  sortable;

  constructor(text, value, sortable = true) {
    Object.assign(this, {
      text: text.toUpperCase(),
      value,
      sortable,
    });
  }
}
