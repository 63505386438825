<template lang="pug">
  div
    v-alert(
      outlined
      type="error"
      v-for="msg in messages"
      :key="msg"
      :value="true"
    ) {{ msg }}
</template>

<script>
export default {
  props: {
    messages: { type: Array, required: true },
  },
};
</script>
