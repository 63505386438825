<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Payment Agent
    template(v-if="!isContentLoading && agent && finances")
      v-layout
        agent-info-card(:item="agent")

      v-layout
        finances(:item="finances" :agent-name="agentName" class="mt-4 mb-3")

      v-btn(v-if="isUserHasFinancierRole",
        :to="{ name: 'new_financier_agents_deposit', params: { agent_uid: agent.id } }"
        style="text-decoration: none" color="info" class="mr-3") New Deposit
      v-btn(v-if="isUserHasFinancierRole",
        :to="{ name: 'new_financier_agents_withdrawal', params: { agent_uid: agent.id } }"
        style="text-decoration: none" color="primary") New Withdrawal
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  ACTION_FETCH_CONTENT,
  ACTION_FETCH_AGENT,
  ACTION_FETCH_AGENT_FINANCE,
} from '@/store/modules/content/action-types';

import { AgentInfoCard } from '@/components/cards/agent-info-card';
import Finances from '@/components/agent/balance/Finances.vue';

export default {
  name: 'FinancierAgentsShow',
  components: {
    AgentInfoCard,
    Finances,
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT,
      uid: this.$route.params.uid,
    });
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT_FINANCE,
      uid: this.$route.params.uid,
    });
  },
  computed: {
    ...mapGetters('content', ['isContentLoading', 'getStateDataItem']),
    ...mapGetters('auth', ['isUserHasFinancierRole']),

    agent() {
      return this.getStateDataItem({ type: 'agents', id: this.$route.params.uid });
    },
    agentName() {
      return this.agent?.attributes?.title;
    },
    finances() {
      return this.getStateDataItem({ type: 'agent-finances', id: this.$route.params.uid });
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
  },
};
</script>
