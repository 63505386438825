var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"xs":"12","md":"10","offset-md":"1"}},[_c('h1',[_vm._v("Client Deposits")]),_c('table-content-provider',{attrs:{"agentUid":_vm.getUserUid,"table-type":_vm.$options.constants.tableType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isInitLoadingComplete = ref.isInitLoadingComplete;
var pagination = ref.pagination;
var items = ref.items;
var handleChange = ref.handleChange;
return [(isInitLoadingComplete)?_c('div',[_c('table-complex',{attrs:{"filters":_vm.tableFilters,"filter-names":_vm.filterNames,"headers":_vm.tableHeaders,"items":items,"min-items-per-page":_vm.$options.constants.minItemsPerPage,"pagination":pagination},on:{"pagination-change":handleChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('client-deposits-table-row',{attrs:{"item":item,"is-user-financier":_vm.isUserHasFinancierRole,"route-name-to-item":_vm.$options.constants.clientDepositRoute}})]}}],null,true)}),_c('app-btn',{attrs:{"color":"primary"},on:{"click":_vm.redirectToCreatedClientsDeposit}},[_vm._v(" New ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }