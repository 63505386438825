import { ACTION_CHANGE_LOADING_CONTENT_STATUS, ACTION_HANDLE_CONTENT_DATA } from '@/store/modules/content/action-types';
import { mapFormServerErr } from '@/store/mappers-common';
import { ErrorParser } from '@/services';
import api from './api';
import * as actionTypes from './action-types';
import * as mutationTypes from './mutation-types';

async function changeItemStatus({ dispatch }, { actionType, uid, code }) {
  try {
    const requestByActionType = {
      [actionTypes.ACTION_ACCEPT_AGENT_WITHDRAWAL]: () => api.acceptAgentWithdrawal({ uid }),
      [actionTypes.ACTION_CANCEL_AGENT_WITHDRAWAL]: () => api.cancelAgentWithdrawal({ uid }),
      [actionTypes.ACTION_REJECT_AGENT_WITHDRAWAL]: () => api.rejectAgentWithdrawal({ uid }),
      [actionTypes.ACTION_START_PROCESSING_AGENT_WITHDRAWAL]: () => api.startProcessingAgentWithdrawal({ uid }),
      [actionTypes.ACTION_ACCEPT_AGENT_DEPOSIT]: () => api.acceptAgentDeposit({ uid }),
      [actionTypes.ACTION_CANCEL_AGENT_DEPOSIT]: () => api.cancelAgentDeposit({ uid }),
      [actionTypes.ACTION_REJECT_AGENT_DEPOSIT]: () => api.rejectAgentDeposit({ uid }),
      [actionTypes.ACTION_CANCEL_PREPAID_CARD]: () => api.cancelPrepaidCard({ code }),
      [actionTypes.ACTION_START_PROCESSING_AGENT_DEPOSIT]: () => api.startProcessingAgentDeposit({ uid }),
    };
    const request = requestByActionType?.[actionType];

    if (request) {
      await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: true }, { root: true });
      const data = await request();
      await dispatch(`content/${ACTION_HANDLE_CONTENT_DATA}`, { data }, { root: true });
    }
  } catch (e) {
    console.error(e, `error while ${actionTypes.ACTION_CHANGE_ITEM_STATUS} - ${actionType}`);
    throw e;
  } finally {
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: false }, { root: true });
  }
}
async function createAgentDepositForAgent(
  { dispatch, commit },
  { agentUid, data, onSuccess = null, financier = false },
) {
  try {
    commit(mutationTypes.REMOVE_FORM_ERRORS);
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: true }, { root: true });

    const deposit = await api.createAgentDepositForAgent({ agentUid, data, financier });
    commit(mutationTypes.SET_LAST_DEPOSIT_ITEM, { item: deposit });

    if (onSuccess) {
      onSuccess(deposit);
    }
  } catch (e) {
    const errorCallback = (err) => (err?.response?.status === 422
      ? ErrorParser.parseBusinessLogicError(e)
      : ErrorParser.parseFormValidationError(e));

    commit(mutationTypes.SET_FORM_ERRORS, { errors: errorCallback(e) });
    console.error(e, 'error while createAgentDepositForAgent');
    throw e;
  } finally {
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: false }, { root: true });
  }
}
async function updateAgentDeposit({ dispatch, commit }, { data, uid, onSuccess = null }) {
  try {
    commit(mutationTypes.REMOVE_FORM_ERRORS);
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: true }, { root: true });

    const deposit = await api.updateAgentDeposit({ uid, data });
    commit(mutationTypes.SET_LAST_DEPOSIT_ITEM, { item: deposit });

    if (onSuccess) {
      onSuccess(deposit);
    }
  } catch (e) {
    commit(mutationTypes.SET_FORM_ERRORS, { errors: mapFormServerErr(e) });
    console.error(e, 'error while updateAgentDeposit');
    throw e;
  } finally {
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: false }, { root: true });
  }
}
async function createPrepaidCardForAgent({ commit, dispatch }, { agentUid, data, onSuccess = null }) {
  try {
    commit(mutationTypes.REMOVE_FORM_ERRORS);
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: true }, { root: true });

    const prepaidCard = await api.createPrepaidCardForAgent({ agentUid, data });
    await dispatch(`content/${ACTION_HANDLE_CONTENT_DATA}`, { data: prepaidCard }, { root: true });

    if (onSuccess) {
      onSuccess(prepaidCard);
    }
  } catch (e) {
    commit(mutationTypes.SET_FORM_ERRORS, { errors: mapFormServerErr(e) });
    console.error(e, 'error while createPrepaidCardForAgent');
    throw e;
  } finally {
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: false }, { root: true });
  }
}
async function createAgentWithdrawalForAgent({ commit, dispatch }, { agentUid, data, onSuccess = null }) {
  try {
    commit(mutationTypes.REMOVE_FORM_ERRORS);
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: true }, { root: true });

    const withdrawal = await api.createAgentWithdrawalForAgent({ agentUid, data });

    if (onSuccess) {
      onSuccess(withdrawal);
    }
  } catch (e) {
    commit(mutationTypes.SET_FORM_ERRORS, { errors: mapFormServerErr(e) });
    console.error(e, 'error while createAgentWithdrawalForAgent');
    throw e;
  } finally {
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: false }, { root: true });
  }
}
async function updateAgentWithdrawal({ commit, dispatch }, { uid, data, onSuccess = null }) {
  try {
    commit(mutationTypes.REMOVE_FORM_ERRORS);
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: true }, { root: true });

    const withdrawal = await api.updateAgentWithdrawal({ uid, data });

    if (onSuccess) {
      onSuccess(withdrawal);
    }
  } catch (e) {
    commit(mutationTypes.SET_FORM_ERRORS, { errors: mapFormServerErr(e) });
    console.error(e, 'error while updateAgentWithdrawal');
    throw e;
  } finally {
    await dispatch(`content/${ACTION_CHANGE_LOADING_CONTENT_STATUS}`, { isLoading: false }, { root: true });
  }
}
function removeFormErrors({ commit }) {
  commit(mutationTypes.REMOVE_FORM_ERRORS);
}

export default {
  [actionTypes.ACTION_CHANGE_ITEM_STATUS]: changeItemStatus,
  [actionTypes.ACTION_CREATE_AGENT_DEPOSIT_FOR_AGENT]: createAgentDepositForAgent,
  [actionTypes.ACTION_UPDATE_AGENT_DEPOSIT]: updateAgentDeposit,
  [actionTypes.ACTION_CREATE_PREPAID_CARD_FOR_AGENT]: createPrepaidCardForAgent,
  [actionTypes.ACTION_CREATE_AGENT_WITHDRAWAL_FOR_AGENT]: createAgentWithdrawalForAgent,
  [actionTypes.ACTION_UPDATE_AGENT_WITHDRAWAL]: updateAgentWithdrawal,
  [actionTypes.ACTION_REMOVE_FORM_ERRORS]: removeFormErrors,
};
