<template>
  <v-form
    class="plain-form"
    ref="form"
    @submit.prevent="submit"
  >
    <div
      class="plain-form__element"
      v-for="field in formFields"
      :key="field.label"
    >
      <component
        v-model="fieldModels[field.modelName]"
        :is="field.componentName"
        :id="field.label"
        :maxlength="field | getObjField('maxLength', '')"
        :label="field.label"
        :disabled="field.isDisabled"
        :items="field | getObjField('selectItems', [])"
        :error-messages="field | getObjField('serverErrors', [])"
        :rules="field | getObjField('rules', [])"
        @input="onInputHandler($event, field)"
      />
    </div>
    <div class="plain-form__submit-wrapper">
      <v-btn
        :disabled="disabled"
        type="submit"
      >
        {{ submitButtonText }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import VueTypes from 'vue-types';

const normalizePropByModelField = (prop) => (
  prop.reduce((acc, { modelName, value }) => {
    acc[modelName] = value;
    return acc;
  }, {})
);

const mergeFormFieldWithReceivedProp = (formFields, prop, updatedField) => {
  const normalizedProp = normalizePropByModelField(prop);

  return formFields.map((formField) => {
    const value = normalizedProp[formField.modelName];

    return value ? { ...formField, [updatedField]: value } : formField;
  });
};

const isCollectionNotEmpty = (collection) => {
  if (collection.length && Object.values(collection[0]).length) {
    const firstEl = collection[0];
    return firstEl.modelName.length > 0 && String(firstEl.value).length > 0;
  }

  return false;
};

export default {
  name: 'plain-form',
  props: {
    form: VueTypes.arrayOf(VueTypes.shape({
      componentName: VueTypes.oneOf(['v-text-field', 'v-select']).isRequired,
      modelName: VueTypes.string.isRequired,
      label: VueTypes.string.isRequired,
      isDisabled: VueTypes.bool.isRequired,
      value: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).isRequired,
      maxLength: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
      onInput: VueTypes.func,
      selectItems: VueTypes.arrayOf(VueTypes.string),
      rules: VueTypes.arrayOf(VueTypes.func),
    })).isRequired,
    serverErrors: VueTypes.arrayOf(VueTypes.shape({
      modelName: VueTypes.string,
      value: VueTypes.arrayOf(VueTypes.string),
    })).def(() => [{ modelName: '', value: [] }]),
    initValues: VueTypes.arrayOf(VueTypes.shape({
      modelName: VueTypes.string,
      value: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    })).def(() => [{ modelName: '', value: '' }]),
    submitButtonText: VueTypes.string.def('Submit'),
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    this.constructModels();
  },
  data() {
    return {
      fieldModels: {},
      isFormSubmitted: false,
    };
  },
  watch: {
    form() {
      this.constructModels();
      this.resetValidation();
    },
  },
  computed: {
    hasServerErrors() {
      return isCollectionNotEmpty(this.serverErrors) && this.isFormSubmitted;
    },
    hasInitValues() {
      return isCollectionNotEmpty(this.initValues);
    },
    dataForModelsConstruction() {
      return this.hasInitValues
        ? mergeFormFieldWithReceivedProp(this.form, this.initValues, 'value')
        : this.form;
    },
    formFields() {
      return this.hasServerErrors
        ? mergeFormFieldWithReceivedProp(this.form, this.serverErrors, 'serverErrors')
        : this.form;
    },
  },
  methods: {
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    changeSubmitStatus(isSubmitted) {
      this.isFormSubmitted = isSubmitted;
    },
    constructModels() {
      this.fieldModels = this.dataForModelsConstruction.reduce((acc, { value, modelName }) => {
        acc[modelName] = value;
        return acc;
      }, {});
    },
    transformFieldModel(inputVal, field) {
      this.fieldModels = { ...this.fieldModels, [field.modelName]: field.onInput(inputVal) };
    },
    onInputHandler(inputVal, field) {
      if (this.isFormSubmitted) {
        this.changeSubmitStatus(false);
      }

      if (field?.onInput) {
        this.transformFieldModel(inputVal, field);
      }

      this.$emit('model-change', { inputVal, field });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.changeSubmitStatus(true);
        this.$emit('submit', this.fieldModels);
      }
    },
  },
};
</script>

<style lang="scss">
  @import 'plain-form';
</style>
