class Time {
  /* eslint-disable */
  constructor(string) {
    this.string = string;
  }

  format() { return this.formatUtc(); }

  formatUtc() {
    const date = this.date();
    const year = date.getUTCFullYear();
    const month = this._twoDigits(date.getUTCMonth() + 1);
    const day = this._twoDigits(date.getUTCDate());
    const hours = this._twoDigits(date.getUTCHours());
    const minutes = this._twoDigits(date.getUTCMinutes());
    const seconds = this._twoDigits(date.getUTCSeconds());
    const dateStr = `${year}.${month}.${day}`;
    const timeStr = `${hours}:${minutes}:${seconds}`;

    return `${dateStr} - ${timeStr} UTC`;
  }

  formatLocal() {
    const date = this.date();
    return `${date.toLocaleDateString()
      .replace(/\//g, '.')} ${date.toLocaleTimeString()} ${this._utcOffset(date)}`;
  }

  _utcOffset(date) {
    const offset = -date.getTimezoneOffset() / 60;
    if (offset >= 0) return `UTC+${offset}`;
    if (offset < 0) return `UTC-${offset}`;
  }

  date() {
    if (!this.dateObj) this.dateObj = new Date(this.string);
    return this.dateObj;
  }

  _twoDigits = (num) => (num >= 10 ? num : `0${num}`)
}

export default function (timeStr, type = 'formatUtc') {
  const formatTypes = ['formatUtc', 'formatLocal'];

  if (formatTypes.includes(type) && typeof timeStr === 'string' && timeStr.length > 0) {
    const time = new Time(timeStr);
    return time[type]();
  }

  return timeStr;
}
