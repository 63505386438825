class AuthResponse {
  constructor(header) {
    this.header = header || '';
  }

  get schema() {
    const matched = this.header.match(/^(\w+)\s?/);
    return matched && matched[1];
  }

  get error() {
    const matched = this.header.match(/error="(\w*)"/);
    return matched && matched[1];
  }

  get errorDescription() {
    const matched = this.header.match(/error_description="(\w*)"/);
    return matched && matched[1];
  }
}

export { AuthResponse };
export default AuthResponse;
