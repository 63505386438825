import * as types from './mutation-types';
import stateConst from './const';

export default {
  [types.MUTATION_SET_AUTH](state, auth) {
    state.auth = auth;
  },
  [types.MUTATION_SET_AUTH_ERRORS](state, errorsObj) {
    state.authErrors = errorsObj;
  },
  [types.MUTATION_SET_AUTH_ERROR_CODE](state, { code }) {
    state.authErrorCode = code;
  },
  [types.MUTATION_REMOVE_ERRORS](state) {
    state.authErrors = null;
    state.authErrorCode = null;
  },
  [types.MUTATION_REMOVE_AUTH](state) {
    state.auth = stateConst.DEFAULT_AUTH;
    state.data = {};
  },
};
