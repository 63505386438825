export const ACTION_HANDLE_CONTENT_DATA = 'ACTION_HANDLE_CONTENT_DATA';
export const ACTION_FETCH_CONTENT = 'ACTION_FETCH_CONTENT';
export const ACTION_FETCH_AGENT = 'ACTION_FETCH_AGENT';
export const ACTION_CHANGE_LOADING_CONTENT_STATUS = 'ACTION_CHANGE_LOADING_CONTENT_STATUS';

export const ACTION_FETCH_PREPAID_CARDS_OF_AGENTS = 'ACTION_FETCH_PREPAID_CARDS_OF_AGENTS';
export const ACTION_FETCH_CLIENT_WITHDRAWAL = 'ACTION_ACTION_FETCH_CLIENT_WITHDRAWAL';
export const ACTION_FETCH_AGENT_WITHDRAWAL = 'ACTION_FETCH_AGENT_WITHDRAWAL';
export const ACTION_FETCH_AGENT_FINANCE = 'ACTION_FETCH_AGENT_FINANCE';
export const ACTION_FETCH_AGENT_DEPOSIT = 'ACTION_FETCH_AGENT_DEPOSIT';
export const ACTION_FETCH_PREPAID_CARDS = 'ACTION_FETCH_PREPAID_CARDS';
export const ACTION_FETCH_CLIENT_DEPOSITS = 'ACTION_FETCH_CLIENT_DEPOSITS';

export const ACTION_FETCH_TABLE_CONTENT = 'ACTION_FETCH_TABLE_CONTENT';
export const ACTION_REMOVE_TABLE_CONTENT = 'ACTION_REMOVE_TABLE_CONTENT';
