/* eslint-disable max-len */
/**
 * common text - <p>common text</p>
 * **text** - <strong>text</strong>
 * # Title - <h1>Title</h1>
 * 1. list el1 2. list el2 - <ol><li>list el1</li><li>list el2</li></ol>
 * - list el1 - list el2 - <ul><li>list el1</li><li>list el2</li></ul>
 * - list el1 - list el2 - <ul><li>list el1</li><li>list el2</li></ul>
 * ![alt alt-text](${https://www.image.com}) - <img src="https://www.image.com" alt="alt-text" />
 */

export default function (images) {
  return [
    {
      title: 'What is ACARD?',
      content: [
        `You will be given a login and password to ACARD, a new system designed specifically to meet the needs of AMarkets Payment Agents. ACARD homepage looks like this: ![alt text](${images.path0})`,
        'ACARD system uses USD as the accounting currency. If you want to use a different currency, please contact your personal manager.',
      ],
    },
    {
      title: 'How to deposit my account with Perfect Money?',
      content: [
        '# First deposit option – Perfect Money',
        `1. Click **«NEW»** to create a deposit request. ![alt text](${images.path1})`,
        `2. Select the Perfect Money payment system and enter the amount you would like to deposit. Next, click **«CREATE»**. ![alt text](${images.path2})`,
        '3. Follow the instructions.',
      ],
    },
    {
      title: 'How to deposit my account with AMarkets wallet?',
      content: [
        '# Second deposit option – Transfer money from AMarkets to ACARD',
        'Another way to deposit your account is to transfer your money from AMarkets wallet to your ACARD account:',
        '1. Follow <a href="https://my.amarkets.org/trader/deposits/new" target="_blank">this link</a>. Select any payment system.',
        '2. Follow <a href="https://my.amarkets.org/trader/withdrawals/new?_ga=2.145820404.1109525812.1619597398-1992814537.1600822123" target="_blank">this link</a>.',
        `3. Select ACARD as your payment system. Enter the amount you would like to transfer from AMarkets to ACARD account: ![alt text](${images.path3})`,
        `4. Enter your ACARD ID. ![alt text](${images.path4}) ![alt text](${images.path5})`,
        `5. Wait for the finance department to confirm the transfer. After the transfer is completed, you will see this transaction in the **«Client Withdrawals»** section. ![alt text](${images.path6})`,
      ],
    },
    {
      title: 'How to deposit my account manually?',
      content: [
        '# Third deposit option – Manual',
        'This deposit option allows you to top up your account using any payment system (including cash). Please contact your personal manager for more details.',
      ],
    },
    {
      title: 'How to issue a prepaid card for a customer?',
      content: [
        '# Prepaid cards',
        'Now you have the balance to issue PREPAID CARDS for clients. e.g. you received money from a client and you want to give the client a PREPAID CARD for 1000 USD:',
        `1. Go over to the **«PREPAID CARDS»** section and click **«NEW»**. ![alt text](${images.path7})`,
        `2. Enter the desired amount and select how many cards you want to issue with this face value. Then click **«CREATE».** ![alt text](${images.path8})`,
        `3. Now that you have a new card, you need to tell your client the amount and the code. ![alt text](${images.path9})`,
        `4. The balance of your ACARD account will decrease for the stated amount. ![alt text](${images.path10})`,
        `5. When the client uses the prepaid card, the card status will change to **«ACTIVATED»**. If you want to delete the card and get your money back to the account, click **«SHOW»** and then **«CANCEL»**. This action is available only until your client activates the card. ![alt text](${images.path11})`,
        'This is the process of replenishing and issuing prepaid cards.',
      ],
    },
    {
      title: 'How can the client withdraw funds?',
      content: [
        '# Client withdrawals',
        `1. When the client wants to withdraw money using your services, you will receive the information about the client’s bank account and your balance will increase for the withdrawal amount. ![alt text](${images.path12}) ![alt text](${images.path13})`,
        '2. You need to use this info to send money to the client:',
        '- Withdraw the money to your bank account and send it to the client’s bank details.',
        '- The agent can give the client their withdrawal in cash i.e. call, schedule a meeting, and hand out cash. In this case, the withdrawal amount will remain in the agent’s ACARD account.',
      ],
    },
  ];
}
