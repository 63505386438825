import Vue from 'vue';
import Router from 'vue-router';
import { Ability } from '@/auth/Ability';
import { commonRoutes, financierRoutes, agentRoutes } from '@/router/routes';
import { getHomePageName } from './helpers';

Vue.use(Router);

export function createRouter({ store }) {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      ...commonRoutes,
      ...financierRoutes,
      ...agentRoutes,
    ],
  });

  router.abilityByName = {};
  router.options.routes
    .forEach((route) => {
      router.abilityByName[route.name] = new Ability(route.meta || {}, () => store.getters['auth/authData']);
    });

  router.beforeEach((to, _from, next) => {
    const ability = router.abilityByName[to.name];
    // if user role doesn't exists -> home page = sign_in
    const homePage = getHomePageName({ userRole: store.getters['auth/authData']?.roles[0] });

    if (ability.authRequired()) {
      return next({ name: homePage });
    }

    if (ability.logoutRequired()) {
      return next({ name: homePage });
    }

    if (ability.roleMissmatch()) {
      return next({ name: homePage });
    }

    return next();
  });

  return router;
}
