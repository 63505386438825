<template>
  <v-flex xs="12" md="10" offset-md="1">
    <h1 class="mb-4">Agent Withdrawals</h1>
    <agent-titles-provider v-slot="{ isTitlesLoaded, agentTitles }">
      <table-content-provider
        :table-type="$options.constants.tableType"
        v-slot="{ isInitLoadingComplete, pagination, items, handleChange }"
      >
        <table-complex
          v-if="isInitLoadingComplete && isTitlesLoaded"
          :filters="createTableFilters(agentTitles)"
          :filter-names="filterNames"
          :headers="tableHeaders"
          :items="items"
          :min-items-per-page="$options.constants.minItemsPerPage"
          :pagination="pagination"
          @pagination-change="handleChange"
        >
          <template v-slot="{ item }">
            <agent-operations-table-row
              :item="item"
              :route-name-to-item="$options.constants.withdrawalRoute"
            />
          </template>
        </table-complex>
      </table-content-provider>
    </agent-titles-provider>
  </v-flex>
</template>

<script>
import { TABLE_TYPES, MIN_ITEMS_PER_PAGE } from '@/consts';
import { FINANCIER_ROUTES } from '@/router/routes-const';
import { Operation, TableFilter, TableHeader } from '@/models';

import { TableContentProvider } from '@/components/providers/table-content';
import { AgentTitlesProvider } from '@/components/providers/agent-titles';
import { TableComplex } from '@/components/common/table-complex';
import { AgentOperationsTableRow } from '@/components/tables/agent-operations-s';

export default {
  name: 'FinancierAgentsWithdrawalsIndex',
  components: {
    TableContentProvider,
    AgentTitlesProvider,
    TableComplex,
    AgentOperationsTableRow,
  },
  constants: {
    tableType: TABLE_TYPES.AGENT_WITHDRAWALS,
    withdrawalRoute: FINANCIER_ROUTES.AGENT_WITHDRAWAL.NAME,
    minItemsPerPage: MIN_ITEMS_PER_PAGE,
  },
  computed: {
    filterNames() {
      return ['title', 'accountDetails', 'statusName', 'uid'];
    },
    tableHeaders() {
      return [
        new TableHeader('ID', 'uid', false),
        new TableHeader('Payment agent', 'title'),
        new TableHeader('Amount', 'amount'),
        new TableHeader('Account details', 'accountDetails'),
        new TableHeader('Status', 'statusName'),
        new TableHeader('Status updated', 'statusUpdatedAt'),
        new TableHeader('Created', 'createdAt'),
        new TableHeader('', '', false),
      ];
    },
  },
  methods: {
    createTableFilters(agentTitles) {
      return [
        new TableFilter('ID', [], this.filterNames[3]),
        new TableFilter('Payment Agent', agentTitles, this.filterNames[0]),
        new TableFilter('Status', Object.values(Operation.STATUS_NAMES), this.filterNames[2]),
        new TableFilter('Account Details', [], this.filterNames[1]),
      ];
    },
  },
};
</script>
