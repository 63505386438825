<template>
  <v-flex xs="12" md="10" offset-md="1">
    <h1 class="mb-4">Prepaid Cards</h1>
    <table-content-provider
      :agentUid="authData.userUid"
      :table-type="$options.constants.tableType"
      v-slot="{ isInitLoadingComplete, pagination, items, handleChange }"
    >
      <div v-if="isInitLoadingComplete">
        <table-complex
          :filters="tableFilters"
          :filter-names="filterNames"
          :headers="tableHeaders"
          :items="items"
          :min-items-per-page="$options.constants.minItemsPerPage"
          :pagination="pagination"
          @pagination-change="handleChange"
        >
          <template v-slot="{ item }">
            <table-row :item="item" :route-name-to-item="$options.constants.prepaidCardRoute" />
          </template>
        </table-complex>
        <v-btn
          color="primary"
          style="text-decoration: none"
          :to="{ name: $options.constants.newPrepaidCardRoute }"
        >
          New
        </v-btn>
      </div>
    </table-content-provider>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { MIN_ITEMS_PER_PAGE, TABLE_TYPES } from '@/consts';
import { AGENT_ROUTES } from '@/router/routes-const';
import { PrepaidCard, TableFilter, TableHeader } from '@/models';

import { TableContentProvider } from '@/components/providers/table-content';
import { TableComplex } from '@/components/common/table-complex';
import TableRow from './table-row.vue';

export default {
  name: 'AgentPrepaidCardsIndex',
  components: {
    TableContentProvider,
    TableComplex,
    TableRow,
  },
  constants: {
    tableType: TABLE_TYPES.AGENT_PREPAID_CARDS_OF_AGENT,
    prepaidCardRoute: AGENT_ROUTES.CLIENT_PREPAID_CARDS.NAME,
    newPrepaidCardRoute: AGENT_ROUTES.NEW_PREPAID_CARD.NAME,
    minItemsPerPage: MIN_ITEMS_PER_PAGE,
  },
  computed: {
    ...mapGetters('auth', ['authData']),

    filterNames() {
      return ['code', 'statusName'];
    },
    tableFilters() {
      return [
        new TableFilter('Code', [], this.filterNames[0]),
        new TableFilter('Status', Object.values(PrepaidCard.STATUS_NAMES), this.filterNames[1]),
      ];
    },
    tableHeaders() {
      return [
        new TableHeader('Amount', 'amount'),
        new TableHeader('Code', 'code'),
        new TableHeader('Status', 'statusName'),
        new TableHeader('Status updated at', 'statusUpdatedAt'),
        new TableHeader('Created at', 'createdAt'),
        new TableHeader('', '', false),
      ];
    },
  },
};
</script>
