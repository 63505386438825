/* eslint-disable max-len */
/**
 * Common constants which can be used all over the application
 */

// Available payment systems for deposit
export const PAYMENT_SYSTEMS = {
  PERFECT_MONEY: 'Perfect Money',
  MANUAL: 'Manual',
  ADAPTER_B2BINPAY_USDT_TRC_USD: 'adapter_b2binpay_usdt_trc_usd',
  ADAPTER_COINSPAID_USDT_TRC_USD: 'adapter_coinspaid_usdt_trc_usd',
};

// Link to third-party script which handle perfect money form - used in FormDepositHidden component
export const PERFECT_MONEY_FORM_ACTION = 'https://perfectmoney.is/api/step1.asp';

// Default quantity of table items per page
export const MIN_ITEMS_PER_PAGE = 15;

export const THEME = {
  PRIMARY: '#4caf50',
  SECONDARY: '#f79a00',
  ACCENT: '#555',
  ERROR: '#f44336',
  INFO: '#2196f3',
  SUCCESS: '#4caf50',
  WARNING: '#FFC107',
};

// Type of tables
export const TABLE_TYPES = {
  AGENT_WITHDRAWALS: 'agent-withdrawals',
  AGENT_DEPOSITS: 'agent-deposits',
  PREPAID_CARDS: 'prepaid-cards',
  CLIENT_WITHDRAWALS: 'client-withdrawals',
  PAYMENT_AGENTS: 'payment-agents',
  AGENT_WITHDRAWALS_OF_AGENT: 'agent-withdrawals-of-agent',
  AGENT_DEPOSITS_OF_AGENT: 'agent-deposits-of-agent',
  AGENT_PREPAID_CARDS_OF_AGENT: 'agent-prepaid-cards-of-agent',
  AGENT_CLIENT_WITHDRAWALS_OF_AGENT: 'agent-client-withdrawals-of-agent',
  AGENT_CLIENT_DEPOSITS: 'client-deposits',
  AGENT_CLIENT_DEPOSITS_OF_AGENT: 'client-deposits-of-agent',
};

// Error messages by http status code
export const TRANSPORT_ERRORS = new Map([
  [400, 'Bad request.'],
  [401, 'You are not an authorized user. Please go through the authorization process.'],
  [403, 'Permission denied. You are not authorized to view this resource.'],
  [404, 'Resource not found or never existed.'],
  [410, 'The resource no longer exists. Please contact our customer service.'],
  [429, 'Too many wrong login attempts. Please go through the captcha.'],
  [500, 'Something went wrong... We are already know about the problem and trying to solve it. Please, try again later.'],
  [502, 'Bad gateway. We are already know about the problem and trying to solve it. Please, try again later.'],
  [undefined, 'The server is unavailable for some reason. We are already know about the problem and trying to solve it. Please, try again later.'],
  ['default', 'Something went wrong... We are already know about the problem and trying to solve it. Please, try again later.'],
]);

// app configuration
export const CONFIG = {
  'URL': {
    'API': process.env.VUE_APP_API_URL,
    'AUTH': process.env.VUE_APP_AUTH_URL,
    '3RD_PARTY_CLIENT_DETAILS': process.env.VUE_APP_CLIENT_DETAILS_URL,
  },
  'TOKEN': {
    'G_RECAPTCHA': process.env.VUE_APP_GOOGLE_RE_CAPTCHA,
  },
  'PERFECT_MONEY': {
    'ACCOUNT': process.env.VUE_APP_PAYMENT_SYSTEM_PERFECT_MONEY_PAYEE_ACCOUNT,
    'NAME': process.env.VUE_APP_PAYMENT_SYSTEM_PERFECT_MONEY_PAYEE_NAME,
  },
};
