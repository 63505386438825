<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Client Withdrawal
    template(v-if="!isContentLoading")
      client-withdrawal-card(:item="withdrawals")
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ACTION_FETCH_CONTENT, ACTION_FETCH_CLIENT_WITHDRAWAL } from '@/store/modules/content/action-types';

import { ClientWithdrawalCard } from '@/components/cards/client-withdrawal-card';

export default {
  name: 'AgentWithdrawalsShow',
  components: {
    ClientWithdrawalCard,
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_CLIENT_WITHDRAWAL,
      uid: this.$route.params.uid,
    });
  },
  computed: {
    ...mapGetters('content', ['isContentLoading', 'getStateDataItem']),

    withdrawals() {
      return this.getStateDataItem({ type: 'client-withdrawals', id: this.$route.params.uid });
    },
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
  },
};
</script>
