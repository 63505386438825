<template>
  <router-link
    flat
    class="ml-0 hidden-sm-and-down v-btn v-btn--flat v-btn--router theme--light app-toolbar__nav-link"
    :to="{ name: redirectTo }"
  >
    <slot />
  </router-link>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'toolbar-user-profile-link',
  props: {
    redirectTo: VueTypes.string.isRequired,
  },
};
</script>
