<template>
  <div class="client-withdrawal-card mt-2 pa-7 rounded-xl">
    <div
      class="client-withdrawal-card__info-wrapper"
      v-for="(val, key) in cardData"
      :key="key"
    >
      <item-info-slot class="item-info-slot">
        <template v-slot:title>{{ key }}</template>
        <template v-slot:description>
          <a
            v-if="isLinkable(key)"
            target="_blank"
            :href="getDetailLink(key)"
          >
            {{ val }}
          </a>
          <span v-else>{{ val }}</span>
        </template>
      </item-info-slot>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import get from 'lodash.get';
import { createDetailLinks } from '@/utils';
import { cardMixin } from '@/mixins';
import { ItemInfoSlot } from '@/components/common/item-info-slot';

const LINKABLE_KEYS = {
  OFFICE_USER_ID: 'Office User ID',
  OFFICE_WITHDRAWAL_ID: 'Office Withdrawal ID',
};

export default {
  name: 'client-withdrawal-card',
  components: {
    ItemInfoSlot,
  },
  mixins: [
    cardMixin,
  ],
  props: {
    item: VueTypes.object.def(() => ({})),
  },
  computed: {
    clientId() {
      return get(this.mix_attributes, 'client_id', '');
    },
    operationId() {
      return get(this.mix_attributes, 'operation_id', '');
    },
    cardData() {
      return {
        [LINKABLE_KEYS.OFFICE_USER_ID]: this.clientId,
        [LINKABLE_KEYS.OFFICE_WITHDRAWAL_ID]: this.operationId,
        'Amount': this.mix_amount,
        'Account details': get(this.mix_attributes, 'account_details', ''),
        'Created at': this.mix_createdAt,
        'Agent title': get(this.mix_attributes, 'title', '-'),
        'Rate': this.mix_attributes?.rate ?? '-',
      };
    },
    detailLinks() {
      const links = createDetailLinks('withdrawal', {
        clientId: this.clientId,
        operationId: this.operationId,
      });

      return {
        [LINKABLE_KEYS.OFFICE_USER_ID]: links.linkToUser,
        [LINKABLE_KEYS.OFFICE_WITHDRAWAL_ID]: links.linkToEditOperation,
      };
    },
  },
  methods: {
    isLinkable(key) {
      return Object.values(LINKABLE_KEYS).includes(key);
    },
    getDetailLink(key) {
      return this.detailLinks[key];
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'client-withdrawal-card';
</style>
