import * as types from './mutation-types';

export default {
  [types.SET_LAST_DEPOSIT_ITEM](state, { item }) {
    state.lastDepositItem = item;
  },
  [types.SET_FORM_ERRORS](state, { errors }) {
    state.formErrors = errors === null ? [] : errors;
  },
  [types.REMOVE_FORM_ERRORS](state) {
    state.formErrors = [];
  },
};
