import { ErrorParser } from '@/services';
import { VuexHelper } from '@/lib/vuex-helper';
import { ACTION_CREATE_AGENT, MUTATION_SET_SERVER_VALIDATION_ERRORS } from './types';

export default function ({ agentCreationApiService }) {
  return {
    async [ACTION_CREATE_AGENT]({ commit }, { id, name, email, title }) {
      try {
        commit(MUTATION_SET_SERVER_VALIDATION_ERRORS, { errors: [] });
        await agentCreationApiService.createAgent({ id, name, email, title });
      } catch (e) {
        const validationError = 400;
        VuexHelper.actionExceptionHandler(e, ACTION_CREATE_AGENT, [validationError]);
        commit(MUTATION_SET_SERVER_VALIDATION_ERRORS, {
          errors: ErrorParser.parseFormValidationError(e),
        });
      }
    },
  };
}
