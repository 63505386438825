import { ApiService } from '@/lib/api-service';

export class AgentCreationApiService extends ApiService {
  createAgent({ id, name, email, title }) {
    return this.post('agents', {
      id,
      type: 'agents',
      attributes: {
        name,
        email,
        title,
      },
    });
  }
}
