<template lang="pug">
  v-flex(xs12, md10, offset-md1)
    h1 Deposit
    form-edit-deposit-container
</template>

<script>
import { mapActions } from 'vuex';
import { ACTION_FETCH_AGENT_DEPOSIT, ACTION_FETCH_CONTENT } from '@/store/modules/content/action-types';

import { FormEditDepositContainer } from '@/components/containers/financier/form-edit-deposit';

export default {
  name: 'FinancierAgentsDepositsEdit',
  components: {
    FormEditDepositContainer,
  },
  async mounted() {
    await this[ACTION_FETCH_CONTENT]({
      actionType: ACTION_FETCH_AGENT_DEPOSIT,
      uid: this.$route.params.uid,
    });
  },
  methods: {
    ...mapActions('content', [ACTION_FETCH_CONTENT]),
  },
};
</script>
