<template>
  <financier-commands-provider :commands-for="commandsFor" v-slot="commands">
    <app-list
      :item-classes="['mr-0']"
      :list-items="buttons"
      v-slot="{ item: { id, color, classes, commandName, text, outlined } }"
    >
      <app-btn
        v-if="!(commandName === 'edit' && isProcessing)"
        :id="id"
        :class="classes"
        :color="color"
        :outlined="outlined"
        @click="getClickHandler(commandName, commands)"
      >
        <v-icon
          v-if="commandName === 'edit'"
          :left="true"
        >
          mdi-pencil
        </v-icon>

        {{ text }}
      </app-btn>
    </app-list>
  </financier-commands-provider>
</template>

<script>
import VueTypes from 'vue-types';

import { AppList } from '@/components/common/app-list';
import { AppBtn } from '@/components/common/app-btn';
import { FinancierCommandsProvider } from '@/components/providers/financier-commands';
import createButtons from './create-buttons';

export default {
  name: 'btn-commands',
  components: {
    AppList,
    AppBtn,
    FinancierCommandsProvider,
  },
  props: {
    commandsFor: VueTypes.oneOf(['withdrawal', 'deposit']).isRequired,
    isProcessing: VueTypes.bool.isRequired,
    editRouteName: VueTypes.string.isRequired,
    itemId: VueTypes.string.isRequired,
  },
  computed: {
    buttons() {
      return createButtons(this.isProcessing);
    },
  },
  methods: {
    getClickHandler(commandName, commands) {
      const commandArg = commandName === 'edit' ? this.editRouteName : this.itemId;
      const command = commands[commandName];

      return command(commandArg);
    },
  },
};
</script>
