<template>
  <container-form-create-agent />
</template>

<script>
import { VuexHelper } from '@/lib/vuex-helper';
import { STORE_MODULE_AGENT_CREATION } from '../constants';
import { createAgentCreationStoreModule } from '../store';
import { AgentCreationApiService } from '../services/agent-creation-api-service';

import { ContainerFormCreateAgent } from '../containers/form-create-agent';

export default {
  name: 'agent-creation-module',
  components: {
    ContainerFormCreateAgent,
  },
  inject: [
    'oauth',
    'axios',
  ],
  created() {
    VuexHelper.registerStoreModule({
      store: this.$store,
      moduleName: STORE_MODULE_AGENT_CREATION,
      module: createAgentCreationStoreModule({
        agentCreationApiService: new AgentCreationApiService(this.oauth, this.axios),
      }),
    });
  },
};
</script>
