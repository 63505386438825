<template>
  <form-deposit
    submit-btn-text="create"
    :last-created-deposit="lastDepositItem"
    @submit="handleSubmit"
    @pm-data-sent="redirectToCreatedDeposit"
  />
</template>

<script>
import humps from 'humps';
import { mapGetters, mapActions } from 'vuex';
import { ACTION_CREATE_AGENT_DEPOSIT_FOR_AGENT } from '@/store/modules/content-interaction/action-types';
import { PAYMENT_SYSTEMS } from '@/consts';
import { FINANCIER_ROUTES } from '@/router/routes-const';

import { FormDeposit } from '@/components/forms/deposit';

export default {
  name: 'form-new-deposit-container',
  components: {
    FormDeposit,
  },
  computed: {
    ...mapGetters('content-interaction', ['lastDepositItem']),
  },
  methods: {
    ...mapActions('content-interaction', [ACTION_CREATE_AGENT_DEPOSIT_FOR_AGENT]),

    redirectToCreatedDeposit(deposit = null) {
      this.$router.push({
        name: FINANCIER_ROUTES.DEPOSIT.NAME,
        params: {
          agentUid: deposit ? deposit.attributes?.agentUid : this.lastDepositItem.attributes?.agentUid,
          uid: deposit ? deposit.id : this.lastDepositItem.id,
        },
      });
    },
    createDeposit(formFields, onSuccess = null) {
      this[ACTION_CREATE_AGENT_DEPOSIT_FOR_AGENT]({
        onSuccess,
        agentUid: this.$route.params.agent_uid,
        data: {
          type: 'agent-deposits',
          attributes: {
            account_details: formFields.accountDetails,
            payment_system: humps.decamelize(formFields.paymentSystem.replace(/\s/g, ''), { separator: '_' }),
            charge: {
              amount: formFields.amount,
              currency: formFields.currency,
            },
          },
        },
        financier: true,
      });
    },
    handleSubmit(formFields) {
      const cb = this.isNotPerfectMoney(formFields.paymentSystem) ? this.redirectToCreatedDeposit : null;
      this.createDeposit(formFields, cb);
    },
    isNotPerfectMoney(currentPayment) {
      return currentPayment !== PAYMENT_SYSTEMS.PERFECT_MONEY;
    },
  },
};
</script>
