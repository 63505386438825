export default function ({ data = [], pagination }) {
  return {
    items: data.map((prepaidCard) => {
      const { id, attributes: { state, charge, ...restAttributes } } = prepaidCard;

      return {
        id,
        agentUid: restAttributes.agentUid,
        operationUid: restAttributes.operationUid,
        clientUid: restAttributes.clientUid,
        title: restAttributes.title,
        amount: charge.amount,
        currency: charge.currency,
        code: restAttributes.code,
        statusName: state.name,
        statusUpdatedAt: state.updatedAt,
        createdAt: restAttributes.createdAt,
      };
    }),
    pagination,
  };
}
