import Vue from 'vue';
import Vuetify from 'vuetify';
import { THEME } from '@/consts';

const themeKeysToLowerCase = (themeColors = {}) => {
  const lowerCased = Object.entries(themeColors).map(([colorName, colorHash]) => ([
    colorName.toLowerCase(),
    colorHash,
  ]));

  return Object.fromEntries(lowerCased);
};

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    themes: {
      light: {
        ...themeKeysToLowerCase(THEME),
        anchor: 'rgba(0, 0, 0, 0.87)',
      },
    },
    icons: {
      iconfont: 'mdiSvg',
    },
  },
});
